import React from "react";
import PropTypes from "prop-types";
import TurkeyMap from "turkey-map-react";
import { Card, CardHeader, Tooltip, useTheme } from "@mui/material";
import CityList from "../CityList";
import "./style.css";
import { Services } from "api/Services";
import moment from "moment";

const RenderCity = (cityComponent, city, cities) => {
  cityComponent.props.key = city.id;
  cityComponent.props["data-is-election-city"] = [
    ...cities.map((i) => i.id),
  ].includes(city.id);

  let cityName = city.name;

  if ([...cities.map((i) => i.id)].includes(city.id)) {
    const getCity = cities.find((i) => i.id === city.id);
    cityName = `${getCity.id} (${getCity.count} Adet)`;
  }

  return (
    <Tooltip key={city.id} title={cityName}>
      {cityComponent}
    </Tooltip>
  );
};

Map.propTypes = {
  data: PropTypes.array,
};

const server = new Services();

export default function Map({ userFilter }) {
  const { palette } = useTheme();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    userFilter.end_date = moment(userFilter.start_date).format("YYYY-MM-DD");
    server
      .getFilterResult("shipments-by-city", userFilter)
      .then((res) => {
        setData(res.entity.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [userFilter]);

  if (!data) {
    return null;
  }

  const cities = data.map((e) => ({
    id: e.city.toLowerCase(),
    count: e.count,
  }));

  return (
    <Card>
      <CardHeader title="İl Bazlı Gönderiler" sx={{ mb: 3 }} />
      <TurkeyMap
        showTooltip
        cityWrapper={(cityComponent, city) =>
          RenderCity(cityComponent, city, cities)
        }
        customStyle={{
          idleColor: palette.grey[400],
          hoverColor: palette.success.main,
        }}
      />
      <CityList data={data} />
    </Card>
  );
}
