import React from "react";
import { Services } from "api/Services";
import { Alert, AlertTitle, Box, Button, Grid, Stack } from "@mui/material";
import Layout from "components/Layout";
import Filters from "./Filters";
import Statics from "./Statics";
import { FilterList } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import AnalyticsTotalBoxWrapper from "components/AnalyticsTotalBoxWrapper";

const service = new Services();
const Analytics = () => {
  const filter = useSelector((s) => s.analyticsFilterRecuder);
  const [openFilter, setOpenFilter] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [fetchError, setFetchError] = React.useState(false);

  const userFilters = {
    customers: filter.customers.map((i) => i.key),
    branches: filter.branches.map((i) => i.key),
    cities: filter.cities.map((i) => i.key),
    counties: filter.counties.map((i) => i.key),
  };

  const getDashboardCounts = async () => {
    setLoading(true);
    setFetchError(false);
    try {
      const res = await service.getNewDashboardCountsLast(
        userFilters || filter
      );
      setData(res.entity.data[0]);
      setLoading(false);
    } catch (error) {
      setFetchError(true);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getDashboardCounts();
  }, [filter]);

  return (
    <Layout
      heading="Analiz"
      links={[{ name: "Genel", href: "/" }, { name: "Analiz" }]}
      action={
        <Button
          variant="contained"
          color={openFilter ? "primary" : "inherit"}
          startIcon={<FilterList />}
          onClick={() => setOpenFilter(!openFilter)}
        >
          Filtrele
        </Button>
      }
    >
      <Stack spacing={3}>
        {openFilter && (
          <Filters
            filter={filter}
            loading={loading}
            getDashboardCounts={getDashboardCounts}
          />
        )}
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "10vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader type="Oval" color="tomato" height={40} width={40} />
          </Box>
        ) : fetchError ? (
          <Alert severity="error">
            <AlertTitle>Bir sorun oluştu.</AlertTitle>
            Lütfen sunucuyu kontrol — <strong>ediniz!</strong>
          </Alert>
        ) : (
          data && (
            <Stack spacing={4}>
              <Statics
                label="Genel Durum"
                data={{
                  subeDagitimTotal:
                    data.branches_and_distributions_package_total,
                  kabulTotal: data.accepts_package_total,
                  iadeTotal: data.returns_package_total,
                }}
              />
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <AnalyticsTotalBoxWrapper
                          title="Kabul & Transfer Süreci"
                          bgcolor="primary.lighter"
                          data={[
                            {
                              title: "Paket Kabul Bekliyor",
                              packageCount: data.order_received_package_count,
                              orderCount: data.order_received_order_count,
                              icon: "/images/analiz-img/Paket_Kabul_Bekliyor.svg",
                              status: "ORDER_RECEIVED",
                            },
                            {
                              title: "Paket Kabul Yapıldı",
                              packageCount: data.order_accepted_package_count,
                              orderCount: data.order_accepted_order_count,
                              icon: "/images/analiz-img/Paket_Kabul_Yapıldı.svg",
                              status: "ORDER_ACCEPTED",
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <AnalyticsTotalBoxWrapper
                          title="Şube"
                          bgcolor="warning.lighter"
                          data={[
                            {
                              title: "Şube Kabul Yapıldı",
                              packageCount: data.branch_package_count,
                              orderCount: data.branch_order_count,
                              icon: "/images/analiz-img/Şube_Kabul_Yapıldı.svg",
                              status: "AT_BRANCH",
                            },
                            {
                              title: "Şubeden Teslim Edilecek",
                              packageCount: data.back_to_branch_package_count,
                              orderCount: data.back_to_branch_order_count,
                              icon: "/images/analiz-img/Şube_Kabul_Yapıldı.svg",
                              status: "",
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AnalyticsTotalBoxWrapper
                          bgcolor="error.lighter"
                          title="İade Süreci"
                          direction="row"
                          data={[
                            {
                              title: "Şubede",
                              packageCount:
                                data.will_be_return_branch_package_count,
                              orderCount:
                                data.will_be_return_branch_order_count,
                              icon: "/images/analiz-img/Ring.svg",
                              status: "WILL_BE_RETURN_BRANCH",
                            },
                            {
                              title: "Araçta",
                              packageCount:
                                data.will_be_return_on_board_package_count,
                              orderCount:
                                data.will_be_return_on_board_order_count,
                              icon: "/images/analiz-img/Şubede.svg",
                              status: "WILL_BE_RETURN_ON_BOARD",
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <AnalyticsTotalBoxWrapper
                      bgcolor="info.lighter"
                      title="Dağıtım Süreci"
                      data={[
                        {
                          title: "Paket Depoda Bulunamadı",
                          packageCount: data.package_not_found_package_count,
                          orderCount: data.package_not_found_order_count,
                          icon: "/images/analiz-img/Paket_Depoda_Bulunamadı.svg",
                          status: "PACKAGE_NOT_FOUND",
                        },
                        {
                          title: "Teslim Edilemedi",
                          packageCount: data.not_delivered_package_count,
                          orderCount: data.not_delivered_order_count,
                          icon: "/images/analiz-img/Teslim_Edilemedi.svg",
                          status: "NOT_DELIVERED",
                        },
                        {
                          title: "Paket İptal Edildi",
                          packageCount: data.package_cancelled_package_count,
                          orderCount: data.package_cancelled_order_count,
                          icon: "/images/analiz-img/paket_iptal.svg",
                          status: "PACKAGE_CANCELLED",
                        },
                        {
                          title: "Kuryeye Zimmetlendi",
                          packageCount: data.courier_debit_package_count,
                          orderCount: data.courier_debit_order_count,
                          icon: "/images/analiz-img/paket_iptal.svg",
                          status: "COURIER_DEBIT",
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          )
        )}
      </Stack>
    </Layout>
  );
};

export default Analytics;
