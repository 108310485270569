import { getStatus, statusFilter } from "helper";
import { convertTimezone } from "utils/convertTimezone";

const getDrivers = (usernames, driverList) => {
  return driverList.filter((driver) => {
    return usernames && usernames.includes(driver.username);
  });
};

const filterHistoryData = (historyData, driverList) => {
  if (!historyData || !driverList) return historyData;
  const usernames = historyData && historyData.map((data) => data.username);
  const matchedDrivers = getDrivers(usernames, driverList);

  return (
    historyData &&
    historyData.map((data) => {
      const fullName =
        matchedDrivers.find((driver) => driver.username === data.username)
          ?.full_name ?? "";

      console.log("s", data);

      return {
        date: convertTimezone(data.date),
        status: getStatus(data.fail_code ?? data.status),
        username: data.username,
        updatedUsername: data.updated_username,
        fullName: fullName,
        description: data.description,
      };
    })
  );
};

export default filterHistoryData;
