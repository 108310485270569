import React from "react";
import Layout from "components/Layout";
import CustomTable from "components/CustomTable";
import { Services } from "api/Services";
import { applySortFilter } from "components/CustomTable/utils";
import useTable, { getComparator } from "hooks/useTable";
import Item from "./item";
import { statusFilter } from "helper";
import moment from "moment";
import { useHistory } from "react-router";
import qs from "query-string";
import { useSelector } from "react-redux";
import TableSelectedActions from "components/CustomTable/TableSelectedActions";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "components/Iconify";
import { BASE_URL } from "api/Env";
import { useSnackbar } from "notistack";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import Label from "components/Label";
import _ from "lodash";

const server = new Services();

const onUniqBy = (data = []) =>
  _.uniqBy(
    data.map((i) => i.key),
    (i) => i
  );

export default function ShipmentsDetail(props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { hash } = history.location;
  const { data } = useSelector((s) => s.dashboardFilterReducer);
  const analyticsData = useSelector((s) => s.analyticsFilterRecuder);
  const queryParam = qs.parse(history.location.search);

  const [loading, setLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,

    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable({ defaultOrderBy: "created_at", defaultOrder: "desc" });

  // Table Head Data
  const tableHeadData = [
    { key: "kuryeName", title: "Kurye Bilgisi", onSort },
    { key: "projeBilgisi", title: "Proje", onSort },
    { key: "sube", title: "Şube", onSort },
    { key: "siparisKodu", title: "Sipariş Kodu" },
    { key: "alici", title: "Alıcı" },
    { key: "durumx", title: "Sipariş Durumu" },
    { key: "siparisTarih", title: "Sipariş Tarihi" },
    { key: "teslimatZamani", title: "Teslimat Zamanı" },
    { key: "cityCounty", title: "İl İlçe", onSort },
    { key: "address", title: "Adres", onSort },
    { key: "quantity", title: "Paket", onSort },
    { key: "deci", title: "Desi" },
    { key: "description", title: "Açıklama" },
  ];

  const isNotDelivered =
    queryParam.status && queryParam.status === "NOT_DELIVERED";

  if (isNotDelivered) {
    tableHeadData.push({
      key: "aciklama",
      title: "Açıklama",
    });
  }

  const getData = async () => {
    let newFilter = {};
    if (hash === "#fromDashboard") {
      newFilter = {
        route_id: queryParam?.routeId,
        statuses: queryParam?.status ? [queryParam.status] : [],
        // Redux
        end_date: moment(data.start_date).format("YYYY-MM-DD"),
        start_date: data.start_date,
        branches: onUniqBy(data.branches),
        cities: onUniqBy(data.cities),
        counties: onUniqBy(data.counties),
        customers: onUniqBy(data.customers),
        drivers: onUniqBy(data.drivers),
        vehicle_types: onUniqBy(data.vehicle_types),
        vehicles: onUniqBy(data.vehicles),
      };
    } else {
      newFilter = {
        route_id: queryParam?.routeId,
        statuses: queryParam?.status ? [queryParam.status] : [],
        end_date: moment(data.start_date).format("YYYY-MM-DD"),
        start_date: data.start_date,
        branches: onUniqBy(analyticsData.branches),
        cities: onUniqBy(analyticsData.cities),
        counties: onUniqBy(analyticsData.counties),
        customers: onUniqBy(analyticsData.customers),
      };
    }

    if (queryParam?.in_route) {
      newFilter.in_route = true;
    }

    // Analiz ekranı dağıtım süreci alanları hariç tüm alanlar için tarih filtresi kaldırıldı.

    if (
      ![
        undefined,
        "ON_BOARD",
        "DELIVERED",
        "NOT_DELIVERED",
        "PACKAGE_NOT_FOUND",
        "PACKAGE_CANCELLED",
        "COURIER_DEBIT",
      ].includes(queryParam?.status)
    ) {
      delete newFilter.start_date;
      delete newFilter.end_date;
    }

    try {
      const res = await server.getOrderFilter(newFilter);
      setTableData(
        res.entity.data.map((item) => {
          return {
            id: item.id,
            created_at: item.created_at,
            kuryeName: `${item.plan_driver?.first_name || ""} ${
              item.plan_driver?.last_name || ""
            }`,
            kuryeUsername: item.plan_driver?.username || "",
            siparisKodu: item.order_code,
            siparisTarih: moment(item.order_date).format("DD/MM/YYYY"),
            alici: {
              name: `${item.customer?.first_name || ""}${
                item.customer?.last_name || ""
              }`,
              phone: item.customer?.gsm_number || "",
            },
            projeBilgisi: item.warehouse?.firm_name,
            durumx: statusFilter(item),
            teslimatZamani: moment(
              item.delivery_status === "DELIVERED"
                ? item.lastupdated
                : item.delivery_date
            ).format("DD/MM/YYYY HH:mm"),
            code: item.customer.code,
            address: item.address?.address || "",
            cityCounty: `${item.address?.city || ""} ${
              item.address?.county ? ` / ${item.address?.county}` : ""
            }`,
            gsm_number: item.address?.gsm_number || "",
            quantity: item.quantity || 0,
            deci: item.deci,
            delivery_description: item.delivery_description || "",
            sube: item?.assigned_branch?.name || "",
            detailData: item,
            // barcode: `${BASE_URL}qrcode/?bulkId=${item.id}&notPrint=false`,
          };
        })
      );
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: ["siparisKodu"],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  const updateBarcodeKey = (id) => {
    setTableData((t) => {
      // update item
      const newArray = [...t];
      const getIndex = t.findIndex((e) => e.id === id);
      if (getIndex > -1) {
        newArray[getIndex].detailData.barcode_printed = true;
      }
      return newArray;
    });
  };

  const handleBarcodeRows = async () => {
    const ids = tableData
      .filter((row) => selected.includes(row.id))
      .map((i) => i.id);

    //bulk services
    let barcodeID = new Date().getTime();
    server.post_print_barcode_bulk(ids, barcodeID);

    try {
      //await Promise.all(requests);
      // update table
      ids.forEach((id) => {
        updateBarcodeKey(id);
      });
      setTimeout(() => {
        window.open(`${BASE_URL}qrcode/?bulkId=${barcodeID}&notPrint=false`);
      }, 500);
      setSelected([]);
      enqueueSnackbar("Barkodlar başarıyla basıldı.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  const totalPackages = _.sumBy(tableData, "quantity");

  return (
    <Layout
      {...props}
      heading={queryParam.pageTitle || ""}
      links={[
        { name: "Genel", href: "/" },
        { name: queryParam.pageTitle || "" },
      ]}
      action={
        <DownloadExcel
          name={queryParam.pageTitle || ""}
          tableHeadData={tableHeadData}
          tableData={tableData}
        />
      }
    >
      <Label variant="ghost" sx={{ mb: 2 }}>
        Toplam paket: {totalPackages}
      </Label>
      <CustomTable
        {...{
          loading,
          // Filter
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz sipariş kodunu giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <Item
              key={`${item.id}-${index}`}
              item={item}
              selected={selected.includes(item.id)}
              onSelectRow={() => onSelectRow(item.id)}
              pageTitle={queryParam.pageTitle}
              isNotDelivered={isNotDelivered}
            />
          )),
          // Selected
          rowCount: tableData.length,
          numSelected: selected.length,
          onSelectAllRows: (checked) => {
            onSelectAllRows(
              checked,
              tableData.map((row) => row.id)
            );
          },
          containerChildren: selected.length > 0 && (
            <TableSelectedActions
              dense={false}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              actions={
                <Tooltip title="Tümünü Barkodları Bas">
                  <IconButton color="primary" onClick={handleBarcodeRows}>
                    <Iconify icon={"bx:barcode-reader"} />
                  </IconButton>
                </Tooltip>
              }
            />
          ),
        }}
      />
    </Layout>
  );
}
