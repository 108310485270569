import React, { useEffect, useRef, useState } from "react";
import "./table.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";
import {
  getReports,
  getReportsDelivery,
  getReportsProduct,
} from "../../store/actions/reportsAction";
import types from "../../store/types";
import { InputText } from "primereact/inputtext";
// import jsPDF from "jspdf";
import "jspdf-autotable";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import {
  dropdownFilterChange,
  onDateChange,
  reverseStateofOrder,
  getPaymentType,
} from "./filter";
import { Spinner } from "../../screens/Spinner/Spinner";
import { getStatus } from "helper";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import { DropDownFilter } from "components/dropdownFilter";
import moment from "moment";
import { stateofOrder } from "./filter";
import { Box } from "@mui/material";
import Layout from "components/Layout";
import { Services } from "api/Services";

const BringoCustomer = (props) => {
  const [switchbt, setSwitchbt] = useState("genel");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [range, setDateRange] = useState(null);
  const [filterSelected, setFilterSelected] = useState("");
  const [filterDateSelected, setFilterDateSelected] = useState("");
  const reports = useSelector((state) => state.deliveryReducer);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [excelExportData, setExcelExportData] = useState({
    genel: [],
    teslimat: [],
    urun: [],
  });
  const dtGeneral = useRef(null);
  const dtDelivery = useRef(null);
  const dtProduct = useRef(null);
  const { t } = useTranslation();
  const toast = useRef(null);
  const [assignedBranchOptions, setAssignedBranchOptions] = useState([
    { name: "Tümü", value: "" },
  ]);
  const [customers, setCustomers] = React.useState([]);

  // user type -> there are two types of users (ADMIN - BRINGO CUSTOMER)
  const userdata = useSelector((state) => state.userReducer);

  const getMusteriler = async () => {
    try {
      const res = await new Services().getList();
      setCustomers(res.entity.data);
    } catch (error) {}
  };

  // get value of selected customer
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const userType = userdata.user.user_type;

  const options = [
    t("on-board"),
    t("customer-readiness-received"),
    t("partially-delivered"),
    t("in-warehouse"),
    t("on-the-way-customer"),
    t("not-delivered"),
    t("delivery-postponed"),
    t("delivered"),
    t("courier-debit"),
    t("branch-return-accepted"),
    t("branch-accepted"),
    t("order-accepted"),
    t("delivery-planned"),
    t("will-be-return"),
    t("delivery-cancelled"),
  ];

  const paymentTypeOptions = [
    { name: "Tümü", value: "" },
    { name: "Kapıda Kredi Kartı", value: "door_credit" },
    { name: "Kapıda Nakit", value: "door_cash" },
    { name: "Online", value: "online" },
  ];

  const statusFilter = (
    <Dropdown
      value={filterSelected}
      options={options}
      placeholder={"Siparişin Durumu"}
      className="p-column-filter"
      onChange={(e) => {
        dropdownFilterChange(e, t, setFilterSelected);
        setFilterSelected(reverseStateofOrder(e.value, t));
      }}
      showClear
    />
  );
  const dateFilter = (
    <Calendar
      value={filterDateSelected}
      onChange={(e) => onDateChange(e, setFilterDateSelected)}
      dateFormat="dd-mm-yy"
      className="p-column-filter"
      placeholder="Tarih Seçiniz"
    />
  );

  useEffect(() => {
    if (dtDelivery && dtDelivery.current) {
      dtDelivery.current.filter(
        filterSelected,
        "delivery_status",
        filterSelected === "CUSTOMER" ? "contains" : "equals"
      );
    }

    if (dtProduct && dtProduct.current) {
      dtProduct.current.filter(
        filterSelected,
        "delivery_status",
        filterSelected === "CUSTOMER" ? "contains" : "equals"
      );
    }
  }, [filterSelected]);

  useEffect(() => {
    if (dtDelivery && dtDelivery.current) {
      dtDelivery.current.filter(
        filterDateSelected,
        "delivery_date",
        "contains"
      );
    }

    if (dtProduct && dtProduct.current) {
      dtProduct.current.filter(filterDateSelected, "delivery_date", "contains");
    }
  }, [filterDateSelected]);

  const isoDateStartTime = new Date(
    startDate.getTime() - startDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .substring(0, 10);

  const isoDateEndTime = new Date(
    endDate.getTime() - endDate.getTimezoneOffset() * 60000
  )
    .toISOString()
    .substring(0, 10);

  const fetchReports = (startDate, endDate, customerId) => {
    dispatch(getReports(startDate, endDate, customerId));

    dispatch(getReportsProduct(startDate, endDate, customerId));

    dispatch(
      getReportsDelivery(startDate, endDate, customerId, (res) => {
        if (res.success) {
          // activePassive(res.data[0]);
          setIsLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    // Bringo-customer kpi
    if (userType !== "ADMIN") {
      fetchReports(isoDateStartTime, isoDateEndTime, undefined);
    }
  }, []);

  useEffect(() => {
    let todayRange = [new Date(), new Date()];
    setDateRange(todayRange);
  }, []);

  useEffect(() => {
    //
    dispatch({
      type: types.GET_REPORTS,
      payload: [],
    });
    dispatch({
      type: types.GET_REPORTS_DELIVERY,
      payload: [],
    });
    dispatch({
      type: types.GET_PRODUCT_REPORTS,
      payload: [],
    });

    getMusteriler();
  }, []);

  useEffect(() => {
    if (selectedCustomer && userType === "ADMIN") {
      fetchReports(isoDateStartTime, isoDateEndTime, selectedCustomer.id);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    const exportDatas = {};

    ["genel", "teslimat", "urun"].forEach((r) => {
      let data;
      let dup_array;

      switch (r) {
        case "genel":
          data = reports.general?.data;
          break;
        case "teslimat":
          data = reports.delivery?.data;
          break;
        case "urun":
          data = reports.product?.data;
          break;
        default:
          data = null;
          break;
      }

      if (data) {
        dup_array = JSON.parse(JSON.stringify(data));
        dup_array.forEach((cell_value) => {
          cell_value.delivery_status = getStatus(cell_value.delivery_status);
          if (switchbt === "teslimat") {
            cell_value.payment_type = getPaymentType(
              cell_value.payment_type,
              t
            );
          }
        });
      } else {
        dup_array = [];
      }

      exportDatas[r] = dup_array;
    });

    setExcelExportData(exportDatas);
  }, [reports.general?.data, reports.product?.data, reports.delivery?.data]);

  const toastCallback = (callback, message) => {
    if (callback === "success") {
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: message ? message : "Güncellendi",
        life: 3000,
      });
    }
    if (callback === "error") {
      toast.current.show({
        severity: "error",
        summary: t("error"),
        detail: message ? message : "error",
        life: 3000,
      });
    }
    if (callback === "warn") {
      toast.current.show({
        severity: "warn",
        summary: "Uyarı",
        detail: message ? message : "warn",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    if (reports.delivery && reports.delivery?.data) {
      const allBranchNames = [];

      reports.delivery.data.forEach((b) => {
        if (!allBranchNames.includes(b.assigned_branch))
          allBranchNames.push(b.assigned_branch);
      });

      setAssignedBranchOptions(
        [{ name: "Tümü", value: "" }].concat(
          allBranchNames.map((b) => ({ name: b, value: b }))
        )
      );
    }
  }, [reports.delivery]);

  const tableColumns = {
    genel: {
      ref: dtGeneral,
      value: reports.general ? reports.general.data : [],
      columns: [
        {
          field: "warehouse_name",
          header: "Müşteri/Adres Kodu",
        },
        {
          field: "order_count",
          header: "Toplam Sipariş Sayısı",
          filterMatchMode: "contains",
        },
        {
          field: "delivered_order_count",
          header: "Teslim Edilen Sipariş Adedi",
          filterMatchMode: "contains",
        },
        {
          field: "not_delivered_order_count",
          header: "Teslim Edilemeyen Sipariş Adedi",
          filterMatchMode: "contains",
        },
        {
          field: "waiting_to_be_delivered",
          header: "Teslim Edilmeyi Bekleyen (dağıtımda)",
          filterMatchMode: "contains",
          body: (col) => (
            <>
              {col.order_count -
                col.delivered_order_count -
                col.not_delivered_order_count}
            </>
          ),
        },
      ],
    },
    teslimat: {
      ref: dtDelivery,
      value: reports.delivery ? reports.delivery.data : [],
      columns: [
        {
          field: "delivery_date",
          header: "Teslimat Tarihi",
          body: (e) => {
            const dds = new Date(e.delivery_date);
            const fixedMonth = dds.getMonth() + 1;
            return (
              <p>{`${
                dds.getDate() < 10 ? "0" + dds.getDate() : dds.getDate()
              }/${
                fixedMonth < 10 ? "0" + fixedMonth : fixedMonth
              }/${dds.getFullYear()}`}</p>
            );
          },
          filterElement: dateFilter,
          style: { width: "130px" },
        },
        {
          field: "order_code",
          header: "Sipariş Kodu",
          filterMatchMode: "contains",
        },
        {
          field: "first_name",
          header: "Müşteri Adı",
          filterMatchMode: "contains",
        },
        {
          field: "address",
          header: "Adres",
          filterMatchMode: "contains",
          style: { width: "200px" },
        },
        {
          field: "mobile",
          header: "Telefon",
          filterMatchMode: "contains",
          style: { width: "130px" },
        },
        {
          field: "username",
          header: "Kurye Adı",
          filterMatchMode: "contains",
          style: { width: "130px" },
        },
        {
          field: "quantity",
          header: "Siparişteki Paket Adedi",
          filterMatchMode: "contains",
          style: { width: "100px" },
        },
        {
          field: "departure_time",
          header: "Gerçekleşen Teslim Saati",
          filterMatchMode: "contains",
        },
        {
          field: "delivery_status",
          header: "Siparişin Durumu",
          filterMatchMode: "contains",
          filterElement: statusFilter,
          body: (e) => {
            return <p>{`${getStatus(e.delivery_status)}`}</p>;
          },
          style: { width: "170px" },
        },
        {
          field: "delivery_description",
          header: "Teslim Edilememe Nedenini",
          filterMatchMode: "contains",
          adminOnly: true,
        },
        {
          field: "delivery_to",
          header: "Teslim Alan Kişi",
          filterMatchMode: "contains",
        },
        {
          field: "payment_type",
          header: "Ödeme Türü",
          filterMatchMode: "contains",
          filterElement: (
            <DropDownFilter
              options={paymentTypeOptions}
              onChange={(e) =>
                dtDelivery.current.filter(e, "payment_type", "equals")
              }
            />
          ),
          body: (e) => {
            return <p>{getPaymentType(e.payment_type, t)}</p>;
          },
          style: { width: "130px" },
        },
        {
          field: "payment_amount",
          header: "Ödeme Tutarı",
          filterMatchMode: "contains",
        },
        {
          field: "customer_rate",
          header: "Hizmet Puanı",
          filterMatchMode: "contains",
        },
        {
          field: "deci",
          header: "Desi",
          filterMatchMode: "contains",
        },
        {
          field: "assigned_branch",
          header: "Dağıtım Şubesi",
          filterMatchMode: "contains",
          filterElement: (
            <DropDownFilter
              options={assignedBranchOptions}
              onChange={(e) =>
                dtDelivery.current.filter(e, "assigned_branch", "equals")
              }
            />
          ),
          style: { width: "130px" },
        },
        {
          field: "planned_count",
          header: "Dağıtıma Çıkış",
          filterMatchMode: "contains",
        },
        {
          field: "session_name",
          header: "Plan Adı",
          filterMatchMode: "contains",
        },
        {
          field: "customer_feedback",
          header: "Müşteri Geri Bildirimi",
          filterMatchMode: "contains",
          style: { width: "130px" },
        },
        {
          field: "customer_feedback_date",
          header: "Geri Bildirim Tarihi",
          filterMatchMode: "contains",
          body: (e) => {
            if (e.customer_feedback_date == null) return;
            const dds = new Date(e.customer_feedback_date);
            let customer_feedback_date = e.customer_feedback_date.split("T");

            const fixedMonth = dds.getMonth() + 1;
            return (
              <p>{`${
                dds.getDate() < 10 ? "0" + dds.getDate() : dds.getDate()
              }/${fixedMonth < 10 ? "0" + fixedMonth : fixedMonth} / ${
                dds.getFullYear() + "-" + customer_feedback_date[1]
              }`}</p>
            );
          },
          style: { width: "130px" },
        },
      ],
    },
    urun: {
      ref: dtProduct,
      value: reports.product ? reports.product.data : [],
      columns: [
        {
          field: "order_code",
          header: "Sipariş Kodu",
        },
        {
          field: "product_code",
          header: "Ürün Kodu",
        },
        {
          field: "product_name",
          header: "Ürün Adı",
        },
        {
          field: "order_quantity",
          header: "Sipariş Miktarı",
        },
        {
          field: "deci",
          header: "Deci",
        },
        {
          field: "delivery_date",
          header: "Teslim Tarihi",
          body: (e) => {
            return <p>{moment(e.delivery_date).format("DD/MM/YYYY")}</p>;
          },
          filterElement: dateFilter,
        },
        {
          field: "courier_name",
          header: "Kurye Adı",
        },
        {
          field: "delivery_status",
          header: "Durum",
          body: (e) => {
            return <p>{getStatus(e.delivery_status)}</p>;
          },
          filterElement: statusFilter,
        },
        {
          field: "delivery_quantity",
          header: "Teslim Miktarı",
        },
        {
          field: "return_reason",
          header: "İade Nedeni",
        },
        {
          field: "return_quantity",
          header: "İade Miktarı",
        },
      ],
    },
  };

  const renderTable = () => {
    let table = tableColumns[switchbt];

    return (
      <DataTable
        globalFilter={filter}
        paginator
        ref={table.ref}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={20}
        csvSeparator={";"}
        rowsPerPageOptions={[20, 50, 100, 200, 400]}
        // value={reports.delivery.data}
        value={table.value}
      >
        {table.columns.map((column) => {
          console.log(column.field);
          return (
            <Column
              field={column.field}
              header={column.header}
              filter
              filterPlaceholder={column.header}
              filterMatchMode="contains"
              sortable
              body={column.body && column.body}
              filterElement={column.filterElement && column.filterElement}
              style={column.style && column.style}
            ></Column>
          );
        })}
      </DataTable>
    );
  };

  // const exportPdf = () => {
  //   const doc = new jsPDF({
  //     orientation: "landscape",
  //   });
  //   doc.autoTable(
  //     tableColumns[switchbt].columns.map((c) => ({dataKey: c.field, title: c.header.removeTurkishCharacters()})),
  //     tableColumns[switchbt].value
  //   );
  //   doc.save("products.pdf");
  // };

  // const onDowloadChange = (e) => {
  //   if (e.value == "excel") {
  //     if (switchbt === "genel") {
  //       let columns = dtGeneral.current.props.value;
  //       // let children = dtGeneral.current.props.children;

  //       // add extra column: UPGRADE_DATA_EXCEL
  //       columns.forEach((col) => {
  //         col.waiting_to_be_delivered =
  //           col.order_count -
  //           col.delivered_order_count -
  //           col.not_delivered_order_count;
  //       });

  //       dtGeneral.current.exportCSV({ selectionOnly: false });
  //     } else {
  //       let columns = dtDelivery.current.props.value;

  //       columns.forEach((cell_value) => {
  //         cell_value.delivery_status = getStatus(cell_value.delivery_status);
  //         //cell_value.order_code = "\xa0" + cell_value.order_code;
  //         cell_value.order_code = cell_value.order_code;
  //       });
  //       // if(columns) columns.slice().filter(col => col.props.field !== 'delivery_status');
  //       dtDelivery.current.exportCSV({ selectionOnly: false });
  //     }
  //   } else {
  //     exportPdf();
  //   }
  // };

  // const downloadOptions = [
  //   { label: "excel", value: "excel" },
  //   { label: "pdf", value: "pdf" },
  // ];

  return (
    <Layout
      {...props}
      heading="KPI"
      links={[{ name: "Genel", href: "/" }, { name: "KPI" }]}
    >
      <Box>
        {/* <div className={"topMenu"}> */}
        {/* import "./table.css" -> not loading CSS file */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            padding: "30px 60px",
            justifyContent: "space-between",
          }}
        >
          <Toast ref={toast} />

          <div class="large ui buttons">
            {/* If the user type is admin , show customer dropdown*/}
            {userType == "ADMIN" && (
              <Dropdown
                style={{
                  height: "47px",
                  lineHeight: "30px",
                  marginRight: "10px",
                  width: "180px",
                }}
                value={selectedCustomer}
                options={customers}
                //optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  setSelectedCustomer(e.value);
                }}
                placeholder="Müşteri"
              />
            )}
            <button
              onClick={() => setSwitchbt("genel")}
              class={`${switchbt === "genel" ? "active" : ""} ui button`}
            >
              Genel
            </button>
            <button
              onClick={() => setSwitchbt("teslimat")}
              class={`${switchbt === "teslimat" ? "active" : ""} ui button`}
            >
              Teslimat
            </button>
            <button
              onClick={() => setSwitchbt("urun")}
              class={`${switchbt === "urun" ? "active" : ""} ui button`}
            >
              Ürün
            </button>
          </div>
          <div className={"dateFilterContainer"}>
            <Calendar
              id="range"
              style={{
                height: "36px",
                marginRight: "10px",
                width: "180px",
              }}
              value={range}
              onChange={(e) => setDateRange(e.value)}
              // onChange={e => setStartDate(e.value)}
              readOnlyInput
              selectionMode="range"
              dateFormat="dd/mm/yy"
            />
            {/* <Calendar
            id="range"
            style={{ height: "36px", marginRight: "10px" }}
            value={endDate}
            onChange={(e) => setEndDate(e.value)}
            readOnlyInput
            dateFormat="dd/mm/yy"
          /> */}
            <button
              onClick={() => {
                let startDate = range[0];
                let endDate = range[1];

                if (!endDate) {
                  toastCallback("error", "Tarih seçiniz");
                  return;
                }
                const isoDateStartTime = new Date(
                  startDate.getTime() - startDate.getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substring(0, 10);
                // .split(".")[0]
                // .split(':')[0];
                const isoDateEndTime = new Date(
                  endDate.getTime() - endDate.getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substring(0, 10);
                // .split(".")[0]
                // .split(':')[0];

                // filtering requests
                let customerId = undefined;

                if (userType === "ADMIN") {
                  if (selectedCustomer == null) {
                    toastCallback("warn", "Müşteri Seçmelisiniz");
                    return;
                  }

                  customerId = selectedCustomer.id;
                }

                fetchReports(isoDateStartTime, isoDateEndTime, customerId);
              }}
              class="ui button"
            >
              Filtrele
            </button>
          </div>
        </div>
        <div className={"tableTop"}>
          <div className="table-header">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Arama..."
              />
            </span>
          </div>
          {/* <Dropdown
          style={{
            height: "33px",
            lineHeight: "14px",
            marginRight: "10px",
            width: "80px",
            marginLeft: "20px",
          }}
          options={downloadOptions}
          onChange={onDowloadChange}
          placeholder="İndir"
        /> */}
          <DownloadExcel
            name={"Kpi"}
            tableHeadData={tableColumns[switchbt].columns.map((c) => ({
              key: c.field,
              title: c.header,
            }))}
            tableData={excelExportData[switchbt]}
          />
        </div>
        {/* <div>{reports.general ? renderTable() : null}</div> */}
        <Box
          className="yasir"
          sx={{
            overflow: "auto",
            '& > .p-datatable.p-component > .p-datatable-wrapper > table[role="grid"]':
              {
                tableLayout: "auto",
                "& > thead th": {
                  whiteSpace: "nowrap",
                },
              },
          }}
        >
          {
            <>
              {isLoading === true && <Spinner showText={false} />}
              {renderTable()}
            </>
          }
        </Box>
      </Box>
    </Layout>
  );
};

export default BringoCustomer;
