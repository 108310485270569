import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import Item from "./item";
import Iconify from "components/Iconify";
import IstanbulMap from "react-istanbul-map";
import TableNoData from "components/CustomTable/TableNoData";
import { Services } from "api/Services";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";

CountyList.propTypes = {
  data: PropTypes.array,
};
const server = new Services();

export default function CountyList({ userFilter }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    userFilter.end_date = moment(userFilter.start_date).format("YYYY-MM-DD");
    server
      .getFilterResult("shipments-by-county", userFilter)
      .then((res) => {
        setData(res.entity.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [userFilter]);
  const tableHeadData = [
    { key: "county", title: "İlçe Semt" },
    { key: "count", title: "Sipariş Sayısı" },
    { key: "density", title: "Yoğunluk" },
  ];
  const { palette } = useTheme();

  const [showAll, setShowAll] = useState(false);
  const [sortType, setSortType] = useState("desc");

  if (!data) {
    return null;
  }

  const sortedData = (key) => {
    const sortedData = [...data];
    if (sortType === "desc") {
      sortedData.sort((a, b) => {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
      setSortType("asc");
    } else {
      sortedData.sort((a, b) => {
        if (a[key] > b[key]) {
          return -1;
        }

        if (a[key] < b[key]) {
          return 1;
        }
        return 0;
      });
      setSortType("desc");
    }
    setData(sortedData);
  };

  const dataToShow = showAll ? data : data.slice(0, 5);

  return (
    <Card>
      <CardHeader title="İlçe & Semt Dağılımı" sx={{ mb: 3 }} />
      <IstanbulMap
        mapWidth={"100%"}
        mapHeight={"100%"}
        viewBox={"70 30 80 45"}
        mapColor={palette.grey[400]}
        hoverColor={palette.success.main}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadData.map((t) => (
                <TableCell
                  sx={{ cursor: "pointer" }}
                  key={t.key}
                  onClick={() => sortedData(t.key)}
                >
                  {t.title} <Iconify icon="bx:bx-sort" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.length > 0 ? (
              dataToShow.map((row, index) => <Item key={index} item={row} />)
            ) : (
              <TableNoData isNotFound colSpan={3} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {data.length > 5 && (
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="inherit"
            endIcon={
              showAll ? (
                <Iconify icon="bx:bx-chevron-down" />
              ) : (
                <Iconify icon="bx:bx-chevron-right" />
              )
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Daha az göster" : "Tümünü göster"}
          </Button>
        </Box>
      )}
    </Card>
  );
}
