import React from "react";
import {
  Box,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Search } from "@mui/icons-material";
import { Services } from "api/Services";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import TableNoData from "components/CustomTable/TableNoData";
import CustomTableHead from "components/CustomTable/TableHead";
import { fDate } from "utils/formatTime";
import Label from "components/CustomTable/TableLabel";
import { statusFilter } from "helper";
import Lottie from "react-lottie";
import { useHistory } from "react-router";
import qs from "query-string";
import { useDispatch } from "react-redux";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: require("./searching-animation.json"),
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const services = new Services();

const style = {
  position: "absolute",
  top: "100px",
  left: "50%",
  width: "100%",
  maxWidth: {
    xs: "96%",
    md: "767px",
  },
  transform: "translate(-50%, -50%)",
  backgroundColor: "#ffffff",
};

const Listbox = styled("div")(({ theme }) => ({
  zIndex: 1,
  position: "absolute",
  width: "100%",
  maxHeight: "50vh",
  overflow: "auto",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "0 0 8px 8px",
}));

const SearchBox = ({ open, onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (search.length == 0) {
        setLoading(false);
        return setList([]);
      }
      setLoading(true);
      try {
        const res = await services.getOrderSearch(search);
        setList(res.entity.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [search]);

  const goDetail = (siparisKodu) => {
    history.push({
      pathname: `/distribution/detail/sub-detail`,
      search: qs.stringify({ siparisKodu }),
    });
    onClose();
  };

  const isList = list.length > 0;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          ...style,
          borderRadius: search ? "8px 8px 0 0" : "8px",
        }}
      >
        <OutlinedInput
          autoFocus
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{
            borderRadius: 1,
            height: 72,
            "& > *": {
              border: "0!important",
            },
          }}
          placeholder="Hızlı sipariş sorgula"
          startAdornment={
            <InputAdornment position="start">
              <Search fontSize="large" />
            </InputAdornment>
          }
        />
        <Listbox sx={{ padding: isList ? "16px 0" : 0 }}>
          {loading ? (
            <Stack sx={{ px: 2 }}>
              <Lottie
                height="40vh"
                options={defaultOptions}
                isClickToPauseDisabled
              />
            </Stack>
          ) : (
            <TableContainer>
              <Table>
                {isList && (
                  <CustomTableHead
                    data={[
                      { title: "Ad Soyad" },
                      { title: "Firma Adı" },
                      { title: "Sipariş Kodu" },
                      { title: "Teslim Durumu" },
                      { title: "Sipariş Tarihi" },
                    ]}
                  />
                )}
                <TableBody>
                  {isList ? (
                    list.map((item, index) => {
                      return (
                        <TableRow
                          key={index}
                          component="tr"
                          sx={{
                            cursor: "pointer",
                            transition: ".4s ease",
                            "&:hover": {
                              transform: "scale(0.99)",
                            },
                          }}
                          onClick={() => goDetail(item.order_code)}
                        >
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar
                              alt={item.address.first_name}
                              color={
                                createAvatar(item.address.first_name).color
                              }
                              sx={{ mr: 2 }}
                            >
                              {createAvatar(item.address.first_name).name}
                            </Avatar>
                            <Stack>
                              <Typography variant="body2" noWrap>
                                {item.address.first_name}{" "}
                                {item.address.last_name}
                              </Typography>
                              <Typography
                                noWrap
                                variant="body2"
                                sx={{ color: "text.disabled" }}
                              >
                                {item.address.gsm_number}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{item.warehouse?.firm_name}</TableCell>
                          <TableCell>{item.order_code}</TableCell>
                          <TableCell>
                            <Label color="default">{statusFilter(item)}</Label>
                          </TableCell>
                          <TableCell>{fDate(item.order_date)}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : search ? (
                    <TableNoData isNotFound colSpan={4} />
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Listbox>
      </Box>
    </Modal>
  );
};

export default SearchBox;
