import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "components/CustomTable/TableLabel";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import { useHistory } from "react-router";
import qs from "query-string";
import { TextFirstLetter } from "utils";

Item.propTypes = {
  item: PropTypes.object,
  pageTitle: PropTypes.string,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
};

export default function Item({ item, pageTitle, selected, onSelectRow }) {
  const history = useHistory();

  const goDetail = () => {
    history.push({
      pathname: `/distribution/detail/sub-detail`,
      search: qs.stringify({
        siparisKodu: item.detailData.order_code,
        pageTitle: pageTitle,
      }),
    });
  };

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>
      <TableCell
        sx={{ cursor: "pointer" }}
        onClick={() => goDetail(item.siparisKodu)}
      >
        <Stack direction="row" spacing={1}>
          <Avatar
            alt={item.kuryeName}
            color={createAvatar(item.kuryeName).color}
          >
            {createAvatar(item.kuryeName).name}
          </Avatar>
          <Stack>
            <Typography variant="body2" noWrap>
              {TextFirstLetter(item.kuryeName)}
            </Typography>
            <Typography noWrap variant="body2" sx={{ color: "text.disabled" }}>
              {TextFirstLetter(item.kuryeUsername)}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.projeBilgisi)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.sube)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.siparisKodu}</Typography>
      </TableCell>
      <TableCell>
        <Stack>
          <Typography variant="caption" noWrap>
            {TextFirstLetter(item.alici.name)}
          </Typography>
          <Typography noWrap variant="caption" sx={{ color: "text.disabled" }}>
            {item.alici.phone}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Label color={item.durumx === "DELIVERED" ? "success" : "warning"}>
          {item.durumx}
        </Label>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.siparisTarih}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.teslimatZamani}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.cityCounty)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.address)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
          {item.quantity} adet
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{item.deci}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {item.delivery_description || ""}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
