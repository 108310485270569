import _ from "lodash";

export const getLengthByKey = (data, key) => {
  if (key == "all") {
    return data.length;
  }
  const totalLength = data.reduce((total, item) => {
    return item[key] > 0 ? total + 1 : total;
  }, 0);

  return totalLength;
};

export function applySortFilter({
  //
  tableData,
  // Tabs
  tabsActive,
  // Filter
  comparator,
  filterOption,
  filterSearch: searchTerm,
  filterSearchKeys,
  //
  uniqBy = null,
}) {
  // Tabs
  if (tabsActive && tabsActive !== "all") {
    tableData = tableData.filter((item) => item[tabsActive]);
  }

  // Order By
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  // Filter
  if (filterOption !== "Tümü") {
    tableData = tableData.filter((item) => item.durum === filterOption);
  }

  // Filter Search

  if (searchTerm === "") {
    return tableData;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const normalizedSearchTerm = lowerCaseSearchTerm
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const results = tableData.filter((object) => {
    for (const property of filterSearchKeys) {
      if (typeof object[property] === "string") {
        const lowerCaseString = object[property].toLowerCase();
        const normalizedString = lowerCaseString
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (normalizedString.includes(normalizedSearchTerm)) {
          return true;
        }
      }
    }
    return false;
  });

  return uniqBy ? _.uniqBy(results, uniqBy) : results;
}
