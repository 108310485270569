import React from "react";
import moment from "moment";
import useTable, { getComparator } from "hooks/useTable";
import Layout from "components/Layout";
import { Services } from "api/Services";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import {
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import qs from "query-string";
import { getStatus, statusFilter } from "helper";
import Label from "components/CustomTable/TableLabel";
import Avatar from "components/CustomTable/Avatar";
import createAvatar from "utils/createAvatar";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import Iconify from "components/Iconify";
import TableSelectedActions from "components/CustomTable/TableSelectedActions";
import { BASE_URL } from "api/Env";
import { useSnackbar } from "notistack";

const server = new Services();
// Created Dates
const prevMonthDate = moment().subtract(1, "months");
const currentDate = moment();
// Tabs
const tabs = [];
// Filter Options Data
const filterOptions = [
  "Tümü",
  "Teslim edildi",
  "Dağıtımda",
  "Depoda",
  "Kısmen teslim edildi",
  "Teslim edilmedi",
  "Teslim ertelendi",
  "İade edilecek",
  "Şubeye İade Kabul Yapıldı",
  "Sipariş müşteriden alınamadı",
  "Paket Kabul Yapıldı",
  "Şube Kabul Yapıldı",
  "Paket Kabul Bekliyor",
];

const DistributionDetail = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryParam = qs.parse(props.location.search);
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [tabsActive, tabsOnChange] = React.useState("all");
  const [filterOption, filterOptionsOnChange] = React.useState(
    filterOptions[0]
  );
  const [filterDate, filterDateOnChange] = React.useState([
    prevMonthDate,
    currentDate,
  ]);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  const getData = async (params) => {
    setLoading(true);
    try {
      const res = await server.getDistributionDetailList(params);
      const newData = res.entity.data.map((item) => {
        const customer = item.customer;

        return {
          id: item.id,
          detailData: item,
          kuryeBilgisi: {
            name: `${item.plan_driver?.first_name || ""} ${
              item.plan_driver?.last_name || ""
            }`,
            username: `${item.plan_driver?.username || ""} ● ${
              item.plan_vehicle?.name || ""
            }`,
          },
          siparisKodu: item.order_code,
          tarih: moment(item.order_date).format("DD/MM/YYYY"),
          siparisVeren: {
            name: `${customer.first_name}${
              customer.last_name ? ` ${customer.last_name}` : ""
            }`,
            phone: customer.gsm_number,
          },
          firmaBilgisi: item.warehouse?.name,
          durum: statusFilter(item),
          teslimatZamani: moment(item.delivery_date).format("DD/MM/YYYY"),
          teslimAlan: item.delivery_to,
          geriBildirimTarihi: item.debit_date
            ? moment(item.debit_date).format("DD/MM/YYYY")
            : null,
          teslimEdilmemeSebebi: item.delivery_description,
          sube: item?.assigned_branch?.name || "",
        };
      });
      setTableData(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // console.log("DATA", data);
  // console.log("HERE", tableData);

  // Get pagination props
  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    order,
    orderBy,
    onSort,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable();

  // Table Head Data
  const tableHeadData = [
    { key: "kuryeBilgisi", title: "Kurye Bilgisi" },
    { key: "siparisKodu", title: "Sipariş Kodu" },
    { key: "sube", title: "Şube", onSort },
    { key: "tarih", title: "Tarihi", onSort },
    { key: "siparisVeren", title: "Sipariş Veren" },
    { key: "firmaBilgisi", title: "Depo", onSort },
    { key: "durum", title: "Durumu" },
    { key: "teslimatZamani", title: "Teslimat Zamanı" },
    { key: "teslimAlan", title: "Teslim Alan" },
    { key: "geriBildirimTarihi", title: "Geri Bildirim Tarihi", onSort },
    { key: "teslimEdilmemeSebebi", title: "Tes.Edil. Sebebi" },
    { key: "", title: "Barkod" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Tabs
    tabsActive,
    // Filter
    filterOption,
    filterSearch,
    filterSearchKeys: ["siparisKodu"],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  React.useEffect(() => queryParam && getData(queryParam), []);

  const goDetail = (siparisKodu) => {
    props.history.push({
      pathname: `/distribution/detail/sub-detail`,
      search: qs.stringify({ siparisKodu }),
    });
  };

  const updateBarcodeKey = (id) => {
    setTableData((t) => {
      // update item
      const newArray = [...t];
      const getIndex = t.findIndex((e) => e.id === id);
      if (getIndex > -1) {
        newArray[getIndex].detailData.barcode_printed = true;
      }
      return newArray;
    });
  };

  const heading = queryParam.routeKey
    ? getStatus(queryParam.routeKey)
    : queryParam.routeID;

  const handleBarcodeRows = async () => {
    const ids = tableData
      .filter((row) => selected.includes(row.id))
      .map((i) => i.id);

    //bulk services
    const barcodeID = new Date().getTime();
    server.post_print_barcode_bulk(ids, barcodeID);

    try {
      //await Promise.all(requests);
      // update table
      ids.forEach((id) => {
        updateBarcodeKey(id);
      });
      setTimeout(() => {
        window.open(`${BASE_URL}qrcode/?bulkId=${barcodeID}&notPrint=false`);
      }, 500);
      setSelected([]);
      enqueueSnackbar("Barkodlar başarıyla basıldı.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  const handlePrint = async (id) => {
    const barcodeID = new Date().getTime();

    try {
      await server.post_print_barcode_bulk([id], barcodeID);
      updateBarcodeKey(id);
      setTimeout(() => {
        window.open(`${BASE_URL}qrcode/?bulkId=${barcodeID}&notPrint=false`);
      }, 500);
      enqueueSnackbar("Barkod başarıyla basıldı.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  return (
    <Layout
      {...props}
      heading="Kurye Zimmet"
      links={[
        { name: "Anasayfa", href: "/" },
        { name: "Kurye Zimmet", href: "/distribution" },
        { name: heading },
      ]}
      action={
        <DownloadExcel
          name={heading}
          tableHeadData={tableHeadData}
          tableData={tableData}
        />
      }
    >
      <CustomTable
        {...{
          loading,
          // Tabs
          tabs,
          tabsActive,
          tabsOnChange,
          // Filter
          filterOption,
          filterOptions,
          filterOptionsOnChange,
          filterOptionsPlaceholder: "Teslim Durumu",
          filterDate,
          filterDateDisable: true,
          filterDateOnChange,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder:
            "Lütfen aramak istediğiniz sipariş numarasını giriniz",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => {
            const isPrint = item.detailData.barcode_printed;

            return (
              <TableRow key={index} hover sx={{ "& td": { border: 0 } }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.includes(item.id)}
                    onClick={() => onSelectRow(item.id)}
                  />
                </TableCell>
                <TableCell
                  sx={{ cursor: "pointer" }}
                  onClick={() => goDetail(item.siparisKodu)}
                >
                  <Stack direction="row">
                    <Avatar
                      alt={item.kuryeBilgisi.name}
                      color={createAvatar(item.kuryeBilgisi.name).color}
                      sx={{ mr: 2 }}
                    >
                      {createAvatar(item.kuryeBilgisi.name).name}
                    </Avatar>
                    <Stack>
                      <Typography variant="body2" noWrap>
                        {item.kuryeBilgisi.name}
                      </Typography>
                      <Typography
                        noWrap
                        variant="body2"
                        sx={{ color: "text.disabled" }}
                      >
                        {item.kuryeBilgisi.username}
                      </Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{item.siparisKodu}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{item.sube}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{item.tarih}</Typography>
                </TableCell>
                <TableCell>
                  <Stack>
                    <Typography variant="body2" noWrap>
                      {item.siparisVeren.name}
                    </Typography>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{ color: "text.disabled" }}
                    >
                      {item.siparisVeren.phone}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{item.firmaBilgisi}</Typography>
                </TableCell>
                <TableCell>
                  <Label
                    color={item.durum === "DELIVERED" ? "success" : "warning"}
                  >
                    {item.durum}
                  </Label>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {item.teslimatZamani}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">{item.teslimAlan}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {item.geriBildirimTarihi || "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {item.teslimEdilmemeSebebi}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    color={isPrint ? "primary" : "error"}
                    onClick={() => handlePrint(item.id)}
                  >
                    <Iconify
                      icon={isPrint ? "mdi:barcode-scan" : "mdi:barcode-off"}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          }),
          // Selected
          rowCount: tableData.length,
          numSelected: selected.length,
          onSelectAllRows: (checked) => {
            onSelectAllRows(
              checked,
              tableData.map((row) => row.id)
            );
          },
          containerChildren: selected.length > 0 && (
            <TableSelectedActions
              dense
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              actions={
                <Tooltip title="Tümünü Barkodları Bas">
                  <IconButton color="primary" onClick={handleBarcodeRows}>
                    <Iconify icon={"bx:barcode-reader"} />
                  </IconButton>
                </Tooltip>
              }
            />
          ),
        }}
      />
    </Layout>
  );
};

export default DistributionDetail;
