import React from "react";
import qs from "query-string";
import { Services } from "api/Services";
import Layout from "components/Layout";
import {
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Alert,
  Checkbox,
  Box,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// LightGallery
import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
// LightGallery
import {
  DatePicker,
  DateTimePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import InputMask from "react-input-mask";
import { GONDERIM_TURLERI } from "helper";
import { CalendarMonth, Person, Restore } from "@mui/icons-material";
import { OpenInNewSharp } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AnalyticsOrderTimeline from "components/AnalyticsOrderTimeline";
import CustomMap from "components/CustomMap";
import Iconify from "components/Iconify";
import moment from "moment";
import { useSelector } from "react-redux";
import filterHistoryData from "screens/Tabs/filterHistoryData";
import { convertTimezone } from "utils/convertTimezone";
import { useDispatch } from "react-redux";
import { driverList } from "store/actions/DriverManagementAction";
import Label from "components/Label";
import FilterData from "store/reducers/distributionDetailReducer/filterData";
import PlanBox from "./PlanBox";
import { TextFirstLetter } from "utils";
import { BASE_URL } from "api/Env";

const server = new Services();

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const DetailSub = (props) => {
  const location = props.location;
  const queryParam = qs.parse(location.search);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [firmalar, setFirmalar] = React.useState([]);
  const [dagitimSubeleri, setDagitimSubeleri] = React.useState([]);
  const [siparisDurumu, setSiparisDurumu] = React.useState(null);
  const [failedReasons, setFailedReasons] = React.useState([]);
  const getDriver = useSelector((state) => state.driverManagementReducer);
  const [data, setData] = React.useState(null);
  const [sendLoading, setSendLoading] = React.useState(false);
  const { user } = useSelector((s) => s.userReducer);
  const [filteredSiparisDurumu, setFilteredSiparisDurumu] =
    React.useState(null);
  const [manualRequest, setManualRequest] = React.useState(true); // Controls the assigned branch manual declaration.
  const defaultDeliverySituation = {
    deliveryStatus: "",
    deliveryText: "",
    postponeDate: "",
    smsChecked: "",
    postponeChecked: false,
  };
  const [deliverySituation, setDeliverySituation] = React.useState(
    defaultDeliverySituation
  );

  const [orderImages, setOrderImages] = React.useState([]);
  const dispatch = useDispatch();
  const history = props.history;

  const layoutProps = {
    ...props,
    heading: "Sipariş Kartı",
    links:
      user.user_type == "ADMIN"
        ? [
            { name: "Anasayfa", href: "/" },
            {
              name: queryParam.pageTitle ?? "Kurye Zimmet",
              href: "/distribution",
              goBack: queryParam.pageTitle,
            },
            { name: queryParam.siparisKodu },
          ]
        : [{ name: "Anasayfa", href: "/" }, { name: queryParam.siparisKodu }],
    action: (
      <LoadingButton
        type="submit"
        color="primary"
        variant="contained"
        startIcon={<Iconify icon={"eva:save-fill"} />}
        sx={{ alignSelf: "flex-end" }}
        loading={sendLoading}
      >
        Değişiklikleri kaydet
      </LoadingButton>
    ),
  };

  React.useEffect(() => {
    if (queryParam.siparisKodu) {
      getData();
    }
  }, [props.location.search]); // props.location.search, getData

  const getData = async () => {
    setLoading(true);
    dispatch(driverList());
    try {
      try {
        const res = await server.getOrder({
          orderCode: queryParam.siparisKodu,
        });
        const resFirmalar = await server.getList();
        const resDagitimSubeleri = await server.getBranchList();
        const resSiparisDurumu = await server.getDeliveryHistory(res[0].id);
        const resFailedReasons = await server.getFailedReasons();

        const newData = {
          ...FilterData(res[0]),
          updatedUser: resSiparisDurumu.entity.data[0].updated_user || "",
          updatedUsername: resSiparisDurumu.entity.data[0].updated_username,
          lastUpdated: resSiparisDurumu.entity.data[0].lastupdated,
          deliveryData: resSiparisDurumu.entity.data[0],
        };

        if (res[0].plan) {
          const plan = await server.getPlan({ orderID: res[0].id });
          newData.planBox = plan[0];
        }
        const resOrderImages = await server.getOrderImages({
          orderID: res[0].id,
        });
        setOrderImages(resOrderImages);

        // resSiparisDurumu.entity.data[0].order_date = convertTimezone(siparisDurumu.order_date);
        setFirmalar(resFirmalar.entity.data);
        setDagitimSubeleri(resDagitimSubeleri.entity.data);
        setSiparisDurumu(resSiparisDurumu.entity.data[0]);
        setFailedReasons(
          resFailedReasons.filter((s) => s.type === "VISIT_FAIL_REASON")
        );

        setData(newData);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } catch (error) {}
  };

  const getUpdateSiparis = async () => {
    try {
      const resSiparisDurumu = await server.getDeliveryHistory(data.id);
      setSiparisDurumu(resSiparisDurumu.entity.data[0]);
      setData((s) => ({
        ...s,
        updatedUser: resSiparisDurumu.entity.data[0].updated_user || "",
        updatedUsername: resSiparisDurumu.entity.data[0].updated_username,
        lastUpdated: resSiparisDurumu.entity.data[0].lastupdated,
        deliveryData: resSiparisDurumu.entity.data[0],
      }));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (siparisDurumu && getDriver) {
      setFilteredSiparisDurumu({
        ...siparisDurumu,
        delivery_date: convertTimezone(siparisDurumu.delivery_date),
        order_date: convertTimezone(siparisDurumu.order_date),
        lastupdated: convertTimezone(siparisDurumu.lastupdated),
        created_at: convertTimezone(siparisDurumu.created_at),
        history: filterHistoryData(siparisDurumu.history, getDriver.data),
        status_date:
          siparisDurumu.status_date &&
          convertTimezone(siparisDurumu.status_date),
      });
    }
  }, [siparisDurumu, getDriver]);

  const changeDeliveryStatus = async () => {
    if (deliverySituation.deliveryStatus !== "return") {
      if (!deliverySituation.deliveryText) {
        throw "Teslim durumu değiştirmek için gerekli alanlar dolu değil";
      }

      let obj = {
        orderId: data.id,
        routeId: data.deliveryData.route_id,
        notificationEnabled: deliverySituation.smsChecked,
      };

      if (deliverySituation.deliveryStatus === "delivered") {
        obj = {
          ...obj,
          recipientName: deliverySituation.deliveryText,
          deliveryCode: data.orderCode,
        };

        await server
          .changeOrderDeliveryStatusFailedSuccess(obj)
          .catch((err) => {
            console.log(err);
            throw "Bilinmeyen bir hata oluştu";
          });
        return;
      }

      obj = {
        ...obj,
        failDescription: deliverySituation.deliveryText,
        failReasonId: deliverySituation.failReasonId,
      };

      if (deliverySituation.postponeChecked) {
        obj = {
          ...obj,
          orderNewDate: moment(deliverySituation.postponeDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
        };
      }

      await server.changeOrderDeliveryStatusFailed(obj).catch((err) => {
        console.log(err);
        throw "Bilinmeyen bir hata oluştu";
      });
      return;
    }

    await server.willBeReturn([data.id]).catch((err) => {
      console.log(err);
      throw "Bilinmeyen bir hata oluştu";
    });
    return;
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setSendLoading(true);

    const sendData = {
      name: data.addressFirstName,
      lastName: data.addressLastName,
      email: data.addressEmail,
      //phoneNumber: data.addressGsmNumber,
      phoneNumber: data.addressGsmNumber
        .replace(/\((.*)\)/, "0")
        .replaceAll(" ", ""),
      address: data.address,
      addressCode: data.addressCode,
      updateManualRequest: manualRequest,
      //city: data.addressDistrict,
      city: data.addressDistrict,
      county: data.addressCounty,
      latitude: Number(data.addressLat),
      longitude: Number(data.addressLng),
      orderDate: moment(data.orderDate).format("YYYY-MM-DDTHH:mm:ss"),
      branchOid: data.assignedBranchID,
      warehouseOid: data.warehouseOid,
      barcode: data.barcode,
      deci: data.deci,
      deliveryDate: data.deliveryDate,
      orderCode: data.orderCode,
      outletCode: data.customerCode,
      quantity: data.quantity,
    };

    //phone length value check -> 14
    /*let phoneValueLength = data.addressGsmNumber.replace(/\((.*)\)/, "0").trim().length;
    if(phoneValueLength < 14){
      enqueueSnackbar("telefon formatı yanlış", { variant: "warning" });
      setSendLoading(false);
      return
    }
    */

    try {
      // Check if user changed delivery informations
      if (deliverySituation.deliveryStatus) {
        try {
          await changeDeliveryStatus();
        } catch (err) {
          enqueueSnackbar(err, { variant: "warning" });
          setSendLoading(false);

          return;
        }
      }

      await server.addOrderManuel(sendData);
      enqueueSnackbar("Başarıyla güncellendi", { variant: "success" });

      setDeliverySituation(defaultDeliverySituation);
      setManualRequest(true);

      await getUpdateSiparis();

      setSendLoading(false);

      if (location.state) {
        history.push(location.state.url, location.state);
        return;
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setSendLoading(false);
      console.log("ERROR =>", error);
    }
  };

  if (loading) {
    return (
      <Layout {...layoutProps}>
        <Stack alignItems="center" sx={{ my: 10 }}>
          <CircularProgress />
        </Stack>
      </Layout>
    );
  }

  if (!data) {
    return (
      <Layout {...layoutProps}>
        <Alert severity="error">Veri bulunamadı.</Alert>
      </Layout>
    );
  }

  const dDLiveTrackingItems = [
    { label: "", value: "" },
    { label: "Teslim Edildi", value: "delivered" },
    { label: "Teslim Edilemedi", value: "notDelivered" },
    { label: "İade Et", value: "return" },
  ];

  return (
    <form onSubmit={onSubmit}>
      <Layout {...layoutProps}>
        <Label
          variant="ghost"
          sx={{ px: 1, py: 2, mb: 2, height: "auto", display: "block" }}
        >
          <Stack direction="row" flexWrap="wrap" alignItems="flext-start">
            <Stack direction="row" alignItems="center">
              <Restore fontSize="small" color="text.secondary" />
              <Typography variant="subtitle2">
                &nbsp;Son güncelleme:&nbsp;
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Person fontSize="small" />
              <Typography variant="body2">
                &nbsp;{TextFirstLetter(data.updatedUser || "")}&nbsp;
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
              <CalendarMonth fontSize="small" />
              <Typography variant="body2">
                &nbsp;
                {moment(data.lastUpdated)
                  .add(3, "hours")
                  .format("DD.MM.YYYY HH:mm")}
                &nbsp;
              </Typography>
            </Stack>
          </Stack>
        </Label>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={9}>
            <Stack rowGap={4}>
              <Card sx={{ height: "300px" }}>
                <CustomMap
                  coords={[data.addressLat, data.addressLng]}
                  onChange={(e) => {
                    // Assigned branch will not be assigned manually.
                    setManualRequest(false);
                    setData((d) => ({
                      ...d,
                      address: e.name,
                      addressCounty: "",
                      addressDistrict: "",
                      addressLat: e.center.lat,
                      addressLng: e.center.lng,
                    }));
                  }}
                />
              </Card>
              {user.user_type === "ADMIN" && (
                <PlanBox
                  data={data.planBox}
                  rut={data.mockData.plan_day_sequence}
                />
              )}
              <Grid container spacing={3}>
                {filteredSiparisDurumu && (
                  <Grid item xs={12} md={12} lg={4}>
                    <Stack direction="column" spacing={3}>
                      {/* GÖNDERİ HAREKETLERİ */}
                      <AnalyticsOrderTimeline
                        data={filteredSiparisDurumu}
                        label={<LabelStyle>Sipariş Hareketleri</LabelStyle>}
                      />
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={siparisDurumu ? 4 : 6}>
                  {/* ALICI BİLGİLERİ */}
                  <Card sx={{ p: 3 }}>
                    <LabelStyle pb={2}>Alıcı Bilgileri</LabelStyle>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Adı"
                        value={data.addressFirstName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressFirstName: value }));
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Soyadı"
                        value={data.addressLastName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressLastName: value }));
                        }}
                      />
                      <TextField
                        fullWidth
                        type="email"
                        label="E-posta"
                        value={data.addressEmail}
                        onChange={(e) => {
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressEmail: value }));
                        }}
                      />
                      {/* ======= Phone Number -S-  */}
                      {/* <TextField
                        fullWidth
                        type="tel"
                        label="Telefon"
                        value={data.addressGsmNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressGsmNumber: value }));
                        }}
                      /> */}
                      <InputMask
                        fullWidth
                        type="tel"
                        mask="(0)999 999 99 99"
                        value={data.addressGsmNumber}
                        disabled={false}
                        maskChar=" "
                        onChange={(e) => {
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressGsmNumber: value }));
                        }}
                      >
                        {() => <TextField label="Telefon" />}
                      </InputMask>
                      {/* ======= Phone Number -E-  */}
                      <Stack direction="row" spacing={1}>
                        <TextField
                          fullWidth
                          label="İl"
                          multiline
                          value={data.addressDistrict}
                          onChange={(e) => {
                            const value = e.target.value;
                            setData((d) => ({ ...d, addressDistrict: value }));
                          }}
                        />
                        <TextField
                          fullWidth
                          label="İlçe"
                          multiline
                          value={data.addressCounty}
                          onChange={(e) => {
                            const value = e.target.value;
                            setData((d) => ({ ...d, addressCounty: value }));
                          }}
                        />
                      </Stack>
                      <TextField
                        fullWidth
                        label="Adres Lat"
                        multiline
                        value={data.addressLat}
                        onChange={(e) => {
                          // setManualRequest(false);
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressLat: value }));
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Adres Lng"
                        multiline
                        value={data.addressLng}
                        onChange={(e) => {
                          // setManualRequest(false);
                          const value = e.target.value;
                          setData((d) => ({ ...d, addressLng: value }));
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Adres"
                        multiline
                        rows={4}
                        value={data.address}
                        onChange={(e) => {
                          // Address is not effective on detecting assigned branch.
                          // Only latitude and longitudes are effective on deciding.
                          // Lang and long are updated when custom map cursor's position changed.
                          // setManualRequest(false);
                          const value = e.target.value;
                          setData((d) => ({ ...d, address: value }));
                        }}
                      />
                      <TextField
                        fullWidth
                        disabled
                        label="Teslim Alan"
                        value={data.deliveryTo}
                        onChange={(e) => {
                          const value = e.target.value;
                          setData((d) => ({ ...d, deliveryTo: value }));
                        }}
                      />
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={siparisDurumu ? 4 : 6}>
                  {/* GÖNDERİCİ BİLGİLERİ */}
                  <Card sx={{ p: 3 }}>
                    <LabelStyle pb={2}>Gönderici Bilgileri</LabelStyle>
                    <Stack spacing={3}>
                      <TextField
                        fullWidth
                        label="Adı"
                        value={data.deliveryData.sender}
                        disabled
                      />
                      <TextField
                        fullWidth
                        label="İl"
                        value={data.deliveryData.sender_city}
                        disabled
                      />
                      <TextField
                        fullWidth
                        label="İlçe"
                        value={data.deliveryData.sender_county}
                        disabled
                      />
                      <TextField
                        fullWidth
                        type="tel"
                        label="Telefon"
                        value={data.deliveryData.sender_phone}
                        disabled
                      />
                      <TextField
                        fullWidth
                        label="Adres"
                        multiline
                        rows={4}
                        value={data.deliveryData.sender_address}
                        disabled
                      />
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          {/* GÖNDERİ BİLGİLERİ */}
          <Grid item xs={12} md={4} lg={3}>
            <Stack spacing={4}>
              {/* TESLİMAT DURUMUNU DEĞİŞTİRME */}
              {user.user_type === "ADMIN" && (
                <Card sx={{ p: 2 }}>
                  <LabelStyle pb={2}>Teslimat Durumunu Değiştir</LabelStyle>
                  <div style={{ padding: "2px" }}>
                    <Stack
                      direction="column"
                      spacing={2}
                      alignItems="start"
                      justifyContent="center"
                    >
                      <TextField
                        label="Teslimat Durumu"
                        value={deliverySituation.deliveryStatus}
                        onChange={(e) =>
                          setDeliverySituation((prev) => ({
                            ...prev,
                            deliveryStatus: e.target.value,
                          }))
                        }
                        fullWidth
                        select
                      >
                        {dDLiveTrackingItems.map((item, i) => {
                          return (
                            <MenuItem
                              key={`dd-${i}`}
                              sx={{ height: "48px" }}
                              value={item.value}
                              divider
                            >
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      {deliverySituation.deliveryStatus && (
                        <>
                          {deliverySituation.deliveryStatus !== "return" ? (
                            <>
                              <TextField
                                fullWidth
                                label={
                                  deliverySituation.deliveryStatus ===
                                  "delivered"
                                    ? "Teslim Alan Kişi"
                                    : "Ek Açıklama"
                                }
                                value={deliverySituation.deliveryText}
                                onInput={(e) =>
                                  setDeliverySituation((prev) => ({
                                    ...prev,
                                    deliveryText: e.target.value,
                                  }))
                                }
                              />

                              {deliverySituation.deliveryStatus ===
                                "notDelivered" && (
                                <Box width={1}>
                                  {/* <Stack direction="row" spacing={2} alignItems="center">
                                                    <Checkbox 
                                                      value={deliverySituation.postponeChecked} 
                                                      onChange={(e)=> setDeliverySituation(prev => ({...prev, postponeChecked: e.target.checked}))}
                                                    />
                                                    <Typography>Ziyareti Ertelemek istiyor musunuz?</Typography>
                                                  </Stack> */}
                                  <TextField
                                    label="Teslim Edilmeme Nedeni"
                                    select
                                    sx={{ width: "100%" }}
                                    value={deliverySituation.failReasonId || ""}
                                    onChange={(e) => {
                                      setDeliverySituation((prev) => ({
                                        ...prev,
                                        failReasonId: e.target.value,
                                      }));
                                    }}
                                  >
                                    {failedReasons.map((item) => {
                                      return (
                                        <MenuItem
                                          key={item.id}
                                          sx={{ height: "48px" }}
                                          value={item.id}
                                          divider
                                        >
                                          {item.text}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>

                                  {deliverySituation.postponeChecked && (
                                    <div style={{ paddingTop: "10px" }}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DatePicker
                                          inputFormat="dd/MM/yyyy"
                                          value={deliverySituation.postponeDate}
                                          onChange={(date) =>
                                            setDeliverySituation((prev) => ({
                                              ...prev,
                                              postponeDate: date,
                                            }))
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} fullWidth />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    </div>
                                  )}
                                </Box>
                              )}
                            </>
                          ) : (
                            <Typography
                              style={{
                                color: "red",
                                fontWeight: "400",
                              }}
                            >
                              Siparişin durumu iade statüsüne dönüştürülecek!
                            </Typography>
                          )}
                        </>
                      )}

                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        alignSelf="end"
                      >
                        <Typography>Sms Gönder</Typography>
                        <Checkbox
                          value={deliverySituation.smsChecked}
                          onChange={(e) =>
                            setDeliverySituation((prev) => ({
                              ...prev,
                              smsChecked: e.target.checked,
                            }))
                          }
                        />
                      </Stack>
                    </Stack>
                  </div>
                </Card>
              )}
              {/* İŞLEM BİLGİLERİ */}
              <Card sx={{ p: 3 }}>
                <LabelStyle pb={2}>Gönderi Bilgileri</LabelStyle>
                <Stack spacing={3}>
                  <FormControl fullWidth>
                    <InputLabel>Müşteri Projesi</InputLabel>
                    <Select
                      disabled
                      value={data.warehouseFirmaID}
                      onChange={(e) => {
                        const value = e.target.value;
                        setData((d) => ({
                          ...d,
                          warehouseFirmaID: value,
                        }));
                      }}
                      input={<OutlinedInput label="Müşteri Projesi" />}
                    >
                      {firmalar.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>Gönderim Türü</InputLabel>
                    <Select
                      disabled
                      value={data.deliveryType}
                      onChange={(e) => {
                        const value = e.target.value;
                        setData((d) => ({ ...d, deliveryType: value }));
                      }}
                      input={<OutlinedInput label="Gönderim Türü" />}
                    >
                      {GONDERIM_TURLERI.map((item) => {
                        return (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>Dağıtım Şubesi</InputLabel>
                    <Select
                      value={data.assignedBranchID}
                      onChange={(e) => {
                        const value = e.target.value;
                        setData((d) => ({
                          ...d,
                          assignedBranchID: value,
                        }));
                      }}
                      input={<OutlinedInput label="Dağıtım Şubesi" />}
                    >
                      {dagitimSubeleri.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel>Kargo Takip Adresi</InputLabel>
                    <OutlinedInput
                      disabled
                      label="Kargo Takip Adresi"
                      value={data.deliveryData.url}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Kargo Takip Adresi"
                            edge="end"
                            onClick={() => {
                              const url = data.deliveryData.url;
                              window.navigator.clipboard.writeText(url);
                              enqueueSnackbar("Yeni sekmede açılıyor...", {
                                variant: "info",
                                autoHideDuration: 1000,
                              });
                              setTimeout(() => {
                                window.open(url, "_blank");
                              }, 1200);
                            }}
                          >
                            <OpenInNewSharp />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel>Sipariş Barkodu</InputLabel>
                    <OutlinedInput
                      disabled
                      label="Sipariş Barkodu"
                      value={data.barcodeURL}
                      endAdornment={
                        <InputAdornment position="end">
                          {/* <IconButton
                            aria-label="Gönderi Barkodu"
                            edge="end"
                            onClick={() => {
                              const url = data.barcodeURL;
                              window.navigator.clipboard.writeText(url);
                              enqueueSnackbar("Gönderi Barkodu Kopyalandı", {
                                variant: "info",
                              });
                            }}
                          >
                            <CopyAll />
                          </IconButton> */}
                          <IconButton
                            aria-label="Kargo Takip Adresi"
                            edge="end"
                            onClick={() => {
                              const url = data.barcodeURL;
                              window.navigator.clipboard.writeText(url);
                              enqueueSnackbar("Yeni sekmede açılıyor...", {
                                variant: "info",
                                autoHideDuration: 1000,
                              });
                              setTimeout(() => {
                                window.open(url, "_blank");
                              }, 1200);
                            }}
                          >
                            <OpenInNewSharp />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Oluşturma Tarihi"
                      inputFormat="dd/MM/yyyy HH:mm"
                      value={convertTimezone(data.createdAt)}
                      onChange={(newValue) => {
                        setData((d) => ({ ...d, createdAt: newValue }));
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                      disabled
                    />
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      label="Sipariş Tarihi"
                      value={convertTimezone(data.orderDate)}
                      onChange={(newValue) => {
                        console.log({ newValue });
                        setData((d) => ({ ...d, orderDate: newValue }));
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    fullWidth
                    label="Sipariş kodu"
                    value={data.orderCode}
                    disabled
                  />
                  <TextField
                    fullWidth
                    //disabled
                    type="number"
                    label="Paket adet"
                    value={data.quantity}
                    onChange={(e) => {
                      const value = e.target.value;
                      setData((d) => ({ ...d, quantity: value }));
                    }}
                  />
                  <TextField
                    fullWidth
                    //disabled
                    type="number"
                    label="Desi"
                    value={data.deci}
                    onChange={(e) => {
                      const value = e.target.value;
                      setData((d) => ({ ...d, deci: value }));
                    }}
                  />
                </Stack>
              </Card>
            </Stack>
          </Grid>
        </Grid>

        {orderImages.length > 0 && (
          <Card>
            <Stack spacing={2} p={2}>
              <Typography variant="h6">Gönderi Fotoğrafları</Typography>
              <ImageList variant="masonry" cols={6} gap={8}>
                {orderImages.map((item) => (
                  <ImageListItem key={item.img} sx={{ cursor: "pointer" }}>
                    <LightGallery onInit={() => {}} speed={500}>
                      <img
                        src={`${BASE_URL}/api/public/document/${item.id}`}
                        srcSet={`${BASE_URL}/api/public/document/${item.id}`}
                        alt={item.title}
                        loading="lazy"
                      />
                    </LightGallery>
                  </ImageListItem>
                ))}
              </ImageList>
            </Stack>
          </Card>
        )}
      </Layout>
    </form>
  );
};

export default DetailSub;
