import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { statusFilter } from "helper";
import tableTextLabels from "utils/tableTextLabels";
import { LocalShipping, Visibility } from "@mui/icons-material";

const DataGrid = styled(MUIDataTable)(({ theme }) => ({
  "& thead th": { backgroundColor: "#F4F6F8!important", whiteSpace: "nowrap" },
  "& tbody tr": { cursor: "pointer" },
}));

const columnsInitial = [
  {
    name: "Plan Sırası",
    options: { filterType: "textField", display: "true" },
  },
  {
    name: "Sipariş kodu",
    options: { filterType: "textField", display: "true" },
  },
  { name: "Sipariş tarihi", options: { filter: false, display: "true" } },
  { name: "İlçe", options: { display: "true" } },
  { name: "Desi", options: { display: "true" } },
  { name: "Teslim tarihi", options: { filter: false, display: "true" } },
  { name: "Teslim durumu", options: { display: "true" } },
  { name: "", options: { filter: false, display: false, viewColumns: false } },
];

Orders.propTypes = {
  orders: PropTypes.array,
  onRowClick: PropTypes.func,
};

export default function Orders(props) {
  const { orders, onRowClick } = props;
  const [columns, setColumns] = React.useState(columnsInitial);

  const newOrders = orders.map((order) => {
    return [
      order.plan_day_sequence,
      order.order_code,
      moment(order.order_date).format("DD.MM.YYYY HH:mm"),
      order.county,
      order.deci,
      moment(order.delivery_date).format("DD.MM.YYYY HH:mm"),
      statusFilter(order),
      order,
    ];
  });

  const isVisibleRow = (rowIndex) => {
    return columns[rowIndex].options.display === "true";
  };

  return (
    <DataGrid
      title={`Tüm Siparişler (${newOrders.length})`}
      data={newOrders}
      columns={columns}
      options={{
        setTableProps: () => ({ size: "small" }),
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filterType: "dropdown",
        tableBodyHeight: "calc(55vh - 166px)",
        responsive: "vertical",
        pagination: false,
        selectableRowsHeader: false,
        selectableRows: "none",
        textLabels: tableTextLabels,
        onViewColumnsChange: (changedColumn, action) => {
          var newCols = columns.map((col) => {
            let newCol = Object.assign({}, col);
            if (changedColumn === newCol.name) {
              if (action === "add") {
                newCol.options.display = "true";
              } else {
                newCol.options.display = "false";
              }
            }
            return newCol;
          });
          setColumns(newCols);
        },
        customRowRender: (data, dataIndex, rowIndex) => {
          const item = data[7];

          return (
            <TableRow
              key={rowIndex}
              hover
              onClick={() => {
                onRowClick(
                  newOrders[dataIndex][newOrders[dataIndex].length - 1]
                );
              }}
            >
              {isVisibleRow(0) && (
                <TableCell>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <LocalShipping
                      fontSize="small"
                      sx={{ color: item.routeColor }}
                    />
                    <Typography variant="caption">{data[0]}</Typography>
                  </Stack>
                </TableCell>
              )}

              {isVisibleRow(1) && (
                <TableCell>
                  <Link
                    href={`/distribution/detail/sub-detail?siparisKodu=${data[1]}`}
                    target="_blank"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "secondary.main",
                    }}
                  >
                    <Visibility fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="subtitle2">{data[1]}</Typography>
                  </Link>
                </TableCell>
              )}
              {isVisibleRow(2) && (
                <TableCell>
                  <Typography variant="caption">{data[2]}</Typography>
                </TableCell>
              )}
              {isVisibleRow(3) && (
                <TableCell>
                  <Typography variant="caption">{data[3]}</Typography>
                </TableCell>
              )}
              {isVisibleRow(4) && (
                <TableCell>
                  <Typography variant="caption">{data[4]}</Typography>
                </TableCell>
              )}

              {isVisibleRow(5) && (
                <TableCell>
                  <Typography variant="caption">{data[5]}</Typography>
                </TableCell>
              )}
              {isVisibleRow(6) && (
                <TableCell>
                  <Typography variant="caption">{data[6]}</Typography>
                </TableCell>
              )}
            </TableRow>
          );
        },
      }}
    />
  );
}
