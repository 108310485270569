function generateUniqueColors(num) {
  const colors = [];

  for (let i = 0; i < num; i++) {
    // Rastgele bir renk kodu oluşturun
    let color = "#";
    const letters = "0123456789ABCDEF";
    for (let j = 0; j < 6; j++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    // Eğer oluşturduğumuz renk daha önce oluşturulmuşsa,
    // yeniden bir renk kodu oluşturun
    while (colors.includes(color) || isTooCloseToWhite(color)) {
      color = "#";
      for (let j = 0; j < 6; j++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    }

    // Renk kodunu diziye ekleyin
    colors.push(color);
  }

  return colors;
}

function isTooCloseToWhite(color) {
  // Renk kodunu RGB değerlerine dönüştürün
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  // RGB değerlerinin ortalamasını alın
  const avg = (r + g + b) / 3;

  // Ortalama değer 255'ten (tamamen beyaz) çok uzak değilse,
  // renk beyaza çok yakın olduğu için false döndürün
  return avg > 250;
}

export default (length) => generateUniqueColors(length);
//   console.log(uniqueColors); // ["#E91E63", "#9C27B0", "#3F51B5", "#2196F3", "#00BCD4", "#009688", "#4CAF50", "#8BC34A", "#CDDC39", "#FFEB3B"]
