import React from "react";
import Item from "./item";
import { Card, CardHeader, Stack, Typography } from "@mui/material";

import useTable, { getComparator } from "hooks/useTable";
import { applySortFilter } from "components/CustomTable/utils";
import CustomTable from "components/CustomTable";
import { Services } from "api/Services";
import moment from "moment";
import Label from "components/Label";
import {
  AccessTimeOutlined,
  CheckCircleOutline,
  LocalShipping,
  Schedule,
  SnoozeOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";

const server = new Services();
const List = ({ userFilter }) => {
  const [tableData, setTableData] = React.useState([]);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  React.useEffect(() => {
    userFilter.end_date = moment(userFilter.start_date).format("YYYY-MM-DD");
    server
      .getFilterResult("shipments", { ...userFilter, max_count: null })
      .then((res) => {
        console.log("res.entity.data =>", res.entity.data);
        setTableData(res.entity.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [userFilter]);

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    dense,
    onChangeDense,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: "route_start_time",
    defaultOrder: "desc",
    defaultRowsPerPage: 5,
  });
  // Table Head Data
  const tableHeadData = [
    { key: "driver_name", title: "Kurye", onSort },
    { key: "route_date", title: "Plan Tarihi", onSort },
    { key: "durum", title: "Durum", onSort },
    { key: "route_start_time", title: "Rota Baş. Saati", onSort },
    { key: "branch_name", title: "Şube", onSort },
    { key: "vehicle_name", title: "Plaka", onSort },
    { key: "order_count", title: "Sipariş", onSort },
    { key: "package_count", title: "Paket", onSort },
    { key: "delivered_count", title: "Teslim", onSort },
    { key: "not_delivered_count", title: "Teslim Edilmeyen", onSort },
    { key: "outlet_count", title: "Nokta", onSort },
    { key: "deci", title: "Desi", onSort },
    { key: "actions", title: "İşlemler", align: "right" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: ["driver_name", "branch_name", "vehicle_name"],
    comparator: getComparator(order, orderBy),
    uniqBy: "route_id",
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  return (
    <Card>
      <CardHeader title="Rotalar" />
      <CustomTable
        {...{
          dense,
          loading: false,
          // Filter
          onChangeDense,
          filterHidden: true,
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <Item key={`${item.id}-${index}`} item={item} />
          )),
        }}
      />
    </Card>
  );
};

export default List;
