/* eslint-disable */

import { Icon, Label, Menu, Table } from "semantic-ui-react";

import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//import ProductService from './services/ProductService';
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
//  import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import "./DataTableStyle.css";
import { BASE_URL } from "../../api/Env";
import DialogModal from "./dialogs";
import TableBody from "./tbody";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import GeocodeDialog from "../GeocodeDialog/GeocodeDialog";
import GeocodeEditDialog from "../GeocodeDialog/GeocodeEditDialog";
import AddPopup from "../WarehouseDialog/AddPopup";
import { VehicleManagement } from "../../screens/DialogPopup/VehicleManagement";
// const VehicleManagement =  lazy(() => import("../../screens/DialogPopup/VehicleManagement").then(module=>({default:module.VehicleManagement})));
import { excelDeliveryStatus } from "./excelFunc";

import { useSelector, useDispatch } from "react-redux";
import {
  customerAdd,
  customerUpdate,
  customerDelete,
} from "../../store/actions/customerAction";
import {
  projectAdd,
  projectUpdate,
  publishUpdate,
} from "../../store/actions/projectAction";
import {
  getProjectApplicationReview,
  applicationEvaluationUpdate,
} from "../../store/actions/projectApplicationDetailAction";
import {
  getDeliveryReportFilter,
  liveFilter,
  willBeReturn,
  manuallyUpdateOrder,
} from "../../store/actions/liveTrackingAction";
import {
  getCustomerWarehouse,
  deleteCustomerWarehouse,
} from "../../store/actions/customerWarehouseAction";
import {
  deleteBranch,
  branchAssignmnetList,
  branchAssignmnetAppointed,
  warehouseListToBeAssigned,
  branchList,
} from "../../store/actions/branchAction";

import {
  manuelOrderAdd,
  updateOrder,
  deleteOrderUnplanned,
  getUnplannedByDate,
  getUnplannedByDateGeocode,
  geocodeCoordinateSave,
  sendToPlanAction,
  submittedPlansAction,
  revertToPlanAction,
  orderMultipleDelete,
  print_barcode,
  getAdminOrderDate,
  print_barcode_bulk,
} from "../../store/actions/orderAction";
import {
  getUnplannedOrds,
  getUnplannedOrdsUngeocoded,
  getUnplannedSendToPlan,
} from "../../store/actions/unplannedOrders";

import { uploadExcelAction } from "../../store/actions/uploadExcelAction";

import { Services } from "../../api/Services";

import TYPES from "../../store/types/index";
import { setRef } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "../../common/removeTurkishCharacters.js";
// import * as FileSaver from 'file-saver';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { unplannedCheckReducer } from "../../store/reducers/unplannedCheckReducer";
import { uploadFileReducer } from "../../store/reducers/uploadFilesReducer";

//import { vehicleDriversList, vehicleAdd, vehicleUpdate, vehicleDelete } from "../../store/actions/vehicleManagementAction";
import {
  driversAdd,
  driversVehicleList,
  deleteDriverAction,
  driverUpdate,
  driverList,
} from "../../store/actions/DriverManagementAction";
import {
  vehicleDriversList,
  vehicleAdd,
  vehicleUpdate,
  deleteVehicleAction,
} from "../../store/actions/vehicleManagementAction";

import {
  getCustomerCountyUser,
  postCustomerCountyUser,
} from "../../store/actions/debitAction";

import DialogActive from "../../screens/DialogPopup/DialogActive";
import DriverManagement from "../../screens/DialogPopup/DriverManagement";
import CustomerUserAdd from "../../screens/DialogPopup/CustomerUserAdd";
import PostponeVisit from "../../screens/DialogPopup/PostponeVisit";
import DebitMultipleSelection from "../../screens/DialogPopup/DebitMultipleSelection";
import CustomerWarehouse from "../../screens/DialogPopup/CustomerWarehouse";
import BranchAssignment from "../../screens/DialogPopup/BranchAssignment";
import BranchDrawing from "../../screens/DialogPopup/BranchDrawing";
import Debit from "../../screens/DialogPopup/Debit";
import { JobUpdate } from "../../store/actions/jobsAction";
import { Row } from "jspdf-autotable";
import { Button as ButtonMui } from "@mui/material";
import { Redirect, useHistory, useLocation } from "react-router";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import { tableHead } from "./excel/tableHead";
import {
  tableManipulation,
  tableManipulationAdminOrderManagement,
} from "./excel/tableManipulation";

const index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const server = new Services();
  const { t } = useTranslation();

  //const list = useSelector(state => state.customerReducer)
  const {
    dates2,
    setDates2,
    boderline,
    setBoderline,
    type,
    deleteCustomerCallback,
    differentPage,
  } = props;

  //console.log("differentPage :", differentPage);

  // let emptyProduct = {
  //   id: null,
  //   name: '',
  //   image: null,
  //   description: '',
  //   category: null,
  //   price: 0,
  //   quantity: 0,
  //   rating: 0,
  //   inventoryStatus: 'INSTOCK'
  // };

  let emptyProduct = {};

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [liveCustomFilter, setLiveCustomFilter] = useState(null);
  const [modalType, setModalType] = useState();
  const [pageName, setPageName] = useState();
  const [deleted, setDeleted] = useState(false);
  const [projectDrop, setProjectDrop] = useState();
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [adminOrderFilterDate, setAdminOrderFilterDate] = useState(null);
  const [deliveryStatusDropdowm, setDeliveryStatusDropdowm] = useState(null);
  const [fileEvent, setFileEvent] = useState();
  const toast = useRef(null);
  const dt = useRef(null);
  const [dialogVs, setdialogVs] = useState(null);
  const [dialogEdit, setDialogEdit] = useState(null);
  const [dialogAddPopup, setDialogAddPopup] = useState(null);
  const [dialogVehicleManagement, setDialogVehicleManagement] = useState(null);
  const [dialogDriversManagement, setDialogDriversManagement] = useState(null);
  const [dialogCustomerUserAdd, setDialogCustomerUserAdd] = useState(null);
  const [dialogPostponeVisit, setDialogPostponeVisit] = useState(null);
  const [dialogDebitMultipleSelection, setDialogDebitMultipleSelection] =
    useState(null);
  const [dialogCustomerWarehouse, setDialogCustomerWarehouse] = useState(null);
  const [dialogBranchAssignment, setDialogBranchAssignment] = useState(null);
  const [dialogBranchDrawing, setDialogBranchDrawing] = useState(null);
  const [dialogDebit, setDialogDebit] = useState(null);
  const [selectedDialogEdit, setSelectedDialogEdit] = useState(null);
  const [selectedDialogEditTarget, setSelectedDialogEditTarget] =
    useState(null);
  const [newOrders, setNewOrders] = useState(false);
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [aa, setAA] = useState(null);
  const [deleteSingleRecord, setDeleteSingleRecord] = useState(false);
  const [vehicleChecked, setVehicleChecked] = useState(false);
  const [vehicleDocName, setVehicleDocName] = useState(null);
  const [dialogActiveStatus, setDialogActiveStatus] = useState(null);
  const [dialogActivePassiveID, setDialogActivePassiveID] = useState(null);
  const [deletedVehicle, setDeletedVehicle] = useState(false);
  //const [customerWarehouseFirmId, setCustomerWarehouseFirmId] = useState("x");
  const [addPopupWhichComponent, setAddPopupWhichComponent] = useState(null);
  const [orderManagementAddressUpdate, setOrderManagementAddressUpdate] =
    useState(null);

  const customer = useSelector((updated) => updated.customerReducer);

  const unplannedCheck = useSelector(
    (updated) => updated.unplannedCheckReducer
  );

  const ungeocoded = useSelector((state) => state.ungeocodedReducer);
  const new_orders = useSelector((excel_file) => excel_file.uploadExcel);
  // const updated_order = useSelector(order => order.orderReducer);
  const getOrderList = useSelector((state) => state.orderReducer);
  const customDialogData = useSelector((state) => state.customDialogReducer);
  const vehiclesFiles = useSelector((state) => state.uploadFileReducer);
  const vehicleStatus = useSelector((state) => state.vehicleStatusReducer);
  const vehicleManagement = useSelector(
    (state) => state.vehicleManagementReducer
  );
  // console.log(ungeocoded, getOrderList);
  // pull zones and projects for excel output
  const vehicleProjectData = useSelector(
    (state) => state.vehicleProjectReducer
  );
  const vehicleZoneData = useSelector((state) => state.vehicleZoneReducer);
  //upload new order popup
  const allWarehouseList = useSelector((state) => state.warehouseAllReducer);
  const activeWarehouse = useSelector((state) => state.activeWarehouseReducer);
  const debitList = useSelector((state) => state.debitReducer);
  const driversList = useSelector((state) => state.driverManagementReducer);
  const getBranchID = useSelector((state) => state.branchAssignmentReducer);
  // get user details
  const { user } = useSelector((s) => s.userReducer);
  const historyInformation = location.state;

  useEffect(() => {
    setProducts(props.products);
  }, [props.products]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setProducts(props.products);
  }, [newOrders]);

  useEffect(() => {
    setPageName(props.type);
    setProducts(props.products);
  }, [props.type]);

  useEffect(() => {
    setProducts(props.products);
  }, [ungeocoded]);

  useEffect((stateChange) => {}, [customer.customerUpdated]);

  useEffect(() => {
    if (historyInformation?.dates) {
      setDeliveryDate(historyInformation.dates);
      return;
    }

    let todayRange = [new Date(), new Date()];
    setDeliveryDate(todayRange);
  }, []);

  useEffect(() => {
    // if(orderUpdated) window.location.reload();
    // if(orderUpdated) setProducts(props.products);
  }, [orderUpdated]);

  useEffect(() => {
    setProducts(products);
  }, [deletedVehicle]);

  useEffect(() => {
    if (vehicleStatus) setProducts(props.products);
  }, [vehicleStatus]);

  const handleUploadFile = (doc_name) => {
    if (vehiclesFiles.vehicle_doc && vehiclesFiles.vehicle_doc[0].id) {
      product[`${doc_name}`] = vehiclesFiles.vehicle_doc[0].id;
    }
  };

  const convertDateToAnotherTimezone = (hr) => {
    var d = new Date(hr);
    d.setHours(d.getHours() + d.getTimezoneOffset() / -60);
    let hh = d.toLocaleTimeString("default", {
      hour: "numeric",
      minute: "numeric",
    });
    let dd = d.toLocaleDateString();
    return [dd, hh];
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const dateConvert = (value) => {
    let date = new Date(value.application_due_date);

    return (
      <React.Fragment>
        <div>{date.toLocaleDateString()}</div>
      </React.Fragment>
    );
  };

  const birthdayConvert = (value) => {
    let date = value.user && new Date(value.user.birthday);

    return (
      <React.Fragment>
        <div>{(date && date.toLocaleDateString()) || "-"}</div>
      </React.Fragment>
    );
  };

  const created_atConvert = (value) => {
    let date = new Date(value.created_at);

    return (
      <React.Fragment>
        <div>{date.toLocaleDateString()}</div>
      </React.Fragment>
    );
  };

  // const liveTrackingOrderPopup = (product) => {
  //   setProduct({ ...product });
  //   setProductDialog(true);
  //   setModalType("livetracking");
  // };

  const liveTrackingOrder = async (orderCode) => {
    history.push({
      pathname: "/distribution/detail/sub-detail",
      search: `?siparisKodu=${orderCode}`,
      state: {
        dates: deliveryDate,
        url: location.pathname,
        orderCode: orderCode,
        dt: dt.current.state,
      },
    });
  };

  const orderCodePopup = (value) => {
    return (
      <React.Fragment>
        {/* onClick={(e) => handleLiveTrackingHistory(value)}> */}
        <Button
          onClick={async (e) => await liveTrackingOrder(value.order_code)}
        >
          {value.order_code}
        </Button>
      </React.Fragment>
    );
  };

  const deliveryStatus = (value, props) => {
    let header = props.header;
    let split = header.split("(");

    return (
      <React.Fragment>
        {(() => {
          //(value.delivery_status == "DELIVERED" || value.delivery_status == "PARTIALLY_DELIVERED" || value.delivery_status == "NOT_DELIVERED" || value.delivery_status == "DELIVERY_POSTPONED")
          //split[0] -> header
          if (split[0] == "Teslim" && value.delivery_status == "DELIVERED") {
            return <div className="greenDot"></div>;
          } else if (
            split[0] == "Teslim" &&
            (value.delivery_status == "PARTIALLY_DELIVERED" ||
              value.delivery_status == "NOT_DELIVERED" ||
              value.delivery_status == "DELIVERY_CANCELLED" ||
              value.delivery_status == "WILL_BE_RETURN")
          ) {
            return <div className="redDot"></div>;
          } else if (
            split[0] == "Teslim" &&
            value.delivery_status == "DELIVERY_POSTPONED"
          ) {
            // postponed visit
            return <div className="blueDot"></div>;
          } else if (
            split[0] != "Teslim" &&
            (value.delivery_status == "DELIVERED" ||
              value.delivery_status == "PARTIALLY_DELIVERED" ||
              value.delivery_status == "NOT_DELIVERED" ||
              value.delivery_status == "DELIVERY_POSTPONED" ||
              value.delivery_status == "DELIVERY_CANCELLED" ||
              value.delivery_status == "WILL_BE_RETURN")
          ) {
            return <div className="grayDot"></div>;
          } else if (
            split[0] == "Yolda" &&
            (value.delivery_status == "CUSTOMER_INFORMED_1" ||
              value.delivery_status == "CUSTOMER_INFORMED_2" ||
              value.delivery_status == "CUSTOMER_INFORMED_3" ||
              value.delivery_status == "ON_THE_WAY_TO_CUSTOMER" ||
              value.delivery_status == "COURIER_DEBIT")
          ) {
            return <div className="yellowDot"></div>;
          } else if (
            split[0] != "Yolda" &&
            (value.delivery_status == "CUSTOMER_INFORMED_1" ||
              value.delivery_status == "CUSTOMER_INFORMED_2" ||
              value.delivery_status == "CUSTOMER_INFORMED_3" ||
              value.delivery_status == "ON_THE_WAY_TO_CUSTOMER" ||
              value.delivery_status == "COURIER_DEBIT")
          ) {
            return <div className="grayDot"></div>;
          } else if (
            split[0] == "Depoda" &&
            (value.delivery_status == "IN_WAREHOUSE" ||
              value.delivery_status == "DELIVERY_PLANNED" ||
              value.delivery_status == "ON_BOARD" ||
              value.delivery_status == "BRANCH_ACCEPTED")
          ) {
            return <div className="yellowDot"></div>;
          } else if (
            split[0] != "Depoda" &&
            (value.delivery_status == "IN_WAREHOUSE" ||
              value.delivery_status == "DELIVERY_PLANNED" ||
              value.delivery_status == "ON_BOARD" ||
              value.delivery_status == "BRANCH_ACCEPTED")
          ) {
            return <div className="grayDot"></div>;
          }
        })()}
      </React.Fragment>
    );
  };

  const deliveryStatusText = (value) => {
    return (
      <React.Fragment>
        <div>
          {(() => {
            switch (value.delivery_status) {
              case "IN_WAREHOUSE":
              case "DELIVERY_PLANNED":
              case "ON_BOARD":
              case "BRANCH_ACCEPTED":
                return <div>Depoda</div>;
                break;
              case "ON_THE_WAY_TO_CUSTOMER":
              case "CUSTOMER_INFORMED_1":
              case "CUSTOMER_INFORMED_2":
              case "CUSTOMER_READINESS_RECEIVED":
              case "COURIER_DEBIT":
              case "CUSTOMER_INFORMED_3":
                return <div>Dağıtımda</div>;
                break;
              // case "CUSTOMER_INFORMED_3":
              //   return <div>Dağıtımda </div>;
              //   break;
              case "DELIVERED":
                return <div>Teslim edildi</div>;
                break;
              case "PARTIALLY_DELIVERED":
                return <div>Kısmen teslim edildi</div>;
                break;
              case "NOT_DELIVERED":
                return <div>Teslim edilmedi</div>;
                break;
              case "DELIVERY_POSTPONED":
                return <div>Teslim ertelendi</div>;
                break;
              case "DELIVERY_CANCELLED":
                return <div>Sipariş müşteriden alınamadı</div>;
                break;
              case "WILL_BE_RETURN":
                return <div>İade edilecek</div>;
                break;
              // case "BRANCH_ACCEPTED":
              //   //return <div>Şube Kabul Yapıldı</div>;
              //   return <div>Depoda</div>;
              //   break;
              case "BRANCH_RETURN_ACCEPTED":
                return <div>Şubeye İade Kabul Yapıldı</div>;
                break;
              // case "COURIER_DEBIT":
              //   //return <div>Kuryeye zimmetlendi</div>;
              //   return <div>Depoda</div>;
              //   break;
              default:
                break;
            }
          })()}
        </div>
      </React.Fragment>
    );
  };

  //????????????????????????????????
  const adminOrderManagementAllDeliveryStatusText = (value) => {
    return (
      <React.Fragment>
        <div>
          {(() => {
            switch (value.delivery_status) {
              case "ORDER_RECEIVED":
                return <div> Paket kabul bekliyor </div>;
              case "IN_WAREHOUSE":
              case "DELIVERY_PLANNED":
              case "ON_BOARD":
                return <div>Depoda</div>;
                break;
              case "ON_THE_WAY_TO_CUSTOMER":
              case "CUSTOMER_INFORMED_1":
              case "CUSTOMER_INFORMED_2":
              case "CUSTOMER_READINESS_RECEIVED":
              case "CUSTOMER_INFORMED_3":
                return <div>Dağıtımda</div>;
                break;
              case "DELIVERED":
                return <div>Teslim edildi</div>;
                break;
              case "PARTIALLY_DELIVERED":
                return <div>Kısmen teslim edildi</div>;
                break;
              case "NOT_DELIVERED":
                return <div>Teslim edilmedi</div>;
                break;
              case "DELIVERY_POSTPONED":
                return <div>Teslim ertelendi</div>;
                break;
              case "DELIVERY_CANCELLED":
                return <div>Sipariş müşteriden alınamadı</div>;
                break;
              case "WILL_BE_RETURN":
                return <div>İade edilecek</div>;
                break;
              case "BRANCH_ACCEPTED":
                return <div>Şube Kabul Yapıldı</div>;
                break;
              case "BRANCH_RETURN_ACCEPTED":
                return <div>Şubeye İade Kabul Yapıldı</div>;
                break;
              case "COURIER_DEBIT":
                return <div>Kuryeye zimmetlendi</div>;
                break;
              case "ORDER_ACCEPTED":
                return <div>Paket kabul yapıldı</div>;
                break;
              // case "-":
              //   return <div>Paket kabul bekliyor</div>;
              //   break;
              default:
                break;
            }
          })()}
        </div>
      </React.Fragment>
    );
  };

  const convertSecondsToHourFormat = (seconds, showSecond) => {
    // seconds = seconds || 0;
    // var mins = Math.floor(seconds / 60);
    // var s = Math.round(seconds - mins * 60);
    // var minutes = Math.floor(mins) % 60;
    // if (minutes < 10) minutes = "0" + minutes;
    // var hours = Math.floor(mins / 60);
    // if (hours < 10) hours = "0" + hours;
    // if (hours == 0) {
    //   return ""
    // }
    // return (hours + 3) + ":" + minutes + (showSecond ? ":" + s : "");

    // live tracking table -> manually change the delivery status
    if (seconds && typeof seconds != "number") {
      return seconds;
    } else {
      // arrival-time
      seconds = seconds || 0;
      var mins = Math.floor(seconds / 60);
      var s = Math.round(seconds - mins * 60);
      var minutes = Math.floor(mins) % 60;
      if (minutes < 10) minutes = "0" + minutes;
      var hours = Math.floor(mins / 60);
      //if (hours < 10) hours = "0" + hours;
      if (hours == 0) {
        return "";
      }
      var h = hours + 3;
      return (
        (h < 10 ? "0" + h : h) + ":" + minutes + (showSecond ? ":" + s : "")
      );
    }
  };

  const arrivalTime = (value) => {
    let time = new Date(value.arrival_time);

    return (
      <React.Fragment>
        <div>{convertSecondsToHourFormat(value.arrival_time, false)}</div>
      </React.Fragment>
    );
  };

  const liveTrackingDateFormat = (value, props) => {
    let time;
    if (props.header == "Sipariş Tarihi") {
      if (value.order_date) {
        time = new Date(value.order_date).toLocaleDateString();
      } else {
        time = "";
      }
    } else {
      time = new Date(value.delivery_date).toLocaleDateString();
    }

    return (
      <React.Fragment>
        <div>{time}</div>
      </React.Fragment>
    );
  };

  const arrayDriverLength = (value) => {
    return (
      <React.Fragment>
        <div>{value.drivers.length}</div>
      </React.Fragment>
    );
  };
  const arrayVehiclesLength = (value) => {
    return (
      <React.Fragment>
        <div>{value.vehicles.length}</div>
      </React.Fragment>
    );
  };

  const workingPeriodDates = (value) => {
    let startDate = new Date(value.start_date);
    let endDate = new Date(value.end_date);

    return (
      <React.Fragment>
        <div>
          {startDate.toLocaleDateString() +
            " - " +
            endDate.toLocaleDateString()}
        </div>
      </React.Fragment>
    );
  };

  const popupDetailProject = (RowData) => {
    setProductDialog(true);
    setModalType(pageName + "-detail");
    dispatch(getProjectApplicationReview(RowData));
  };

  const detailProject = (rowData) => {
    return (
      <React.Fragment>
        <Link to={`/project-application/${rowData.id}`}>Başvurular</Link>
      </React.Fragment>
    );
  };

  const editOrderDialogShow = (order) => {
    setProduct({ ...order });
    setProductDialog(true);
    setModalType("order-management-update-order");
    dispatch(branchList());
  };

  const orderDialog = (value) => {
    return (
      <React.Fragment>
        <Link
          to={`/distribution/detail/sub-detail?siparisKodu=${value.order_code}`}
          // onClick={() => {
          //   // editOrderDialogShow(value)
          // }}
        >
          {value.order_code}
        </Link>
      </React.Fragment>
    );
  };

  const applicationEvaluation = (RowData, whichEvaluation) => {
    switch (whichEvaluation) {
      case "Approve":
        dispatch(
          applicationEvaluationUpdate(RowData, 0, (res) => {
            toastCallback("success");
          })
        );
        setProductDialog(false);
        break;
      case "Reject":
        dispatch(
          applicationEvaluationUpdate(RowData, 1, (res) => {
            toastCallback("success");
          })
        );
        setProductDialog(false);
        break;
      case "Reserve":
        dispatch(
          applicationEvaluationUpdate(RowData, 2, (res) => {
            toastCallback("success");
          })
        );
        setProductDialog(false);
        break;
      default:
        break;
    }
  };

  const projectApplicationButtonSwitch = (RowData) => {
    switch (RowData.evaluation_result) {
      case "APPROVED":
        return (
          <Button
            label="Onaylandı"
            className="p-mr-2"
            style={{ width: "100%" }}
          />
        );
        break;
      case "REJECTED":
        return (
          <Button
            label="Reddedildi"
            className="p-button-danger"
            style={{ width: "100%" }}
          />
        );
        break;
      case "RESERVED":
        return (
          <Button
            label="Yedek"
            className="p-button-warning"
            style={{ width: "100%" }}
          />
        );
        break;

      default:
        return (
          <React.Fragment>
            <Button
              label="Onayla"
              className="p-mr-2"
              onClick={() => applicationEvaluation(RowData, "Approve")}
            />
            <Button
              label="Yedek"
              className="p-button-warning p-mr-2"
              onClick={() => applicationEvaluation(RowData, "Reserve")}
            />
            <Button
              label="Red"
              className="p-button-danger"
              onClick={() => applicationEvaluation(RowData, "Reject")}
            />
          </React.Fragment>
        );
        break;
    }
  };

  const projectDetailTemplate = (RowData) => {
    return projectApplicationButtonSwitch(RowData);
  };

  const projectDetailPopupTemplate = (RowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-search"
          className="p-button-rounded p-button-success p-button-outlined"
          onClick={() => popupDetailProject(RowData)}
        />
      </React.Fragment>
    );
  };

  const deleteUnplanned = (RowData) => {
    dispatch(deleteOrderUnplanned(RowData, toastCallback));
  };

  const deleteUnplannedAction = (RowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          // onClick={() => deleteUnplanned(RowData)}
          onClick={() => confirmDeleteProduct(RowData, true)}
        />
      </React.Fragment>
    );
  };

  const customerFeedbackDateFormat = (value, props) => {
    if (value.customer_feedback_date == null) return;
    // let hour = new Date(value.customer_feedback_date).setHours(3)
    // var hour2 = new Date(hour);
    // var timeFormatFinal = hour2.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
    let timeFormatFinal = value.customer_feedback_date.split("T");
    return (
      <React.Fragment>
        <div>
          {" "}
          {timeFormatFinal[0]} / {timeFormatFinal[1]}{" "}
        </div>
      </React.Fragment>
    );
  };

  const barcodePrinted = (id) => {
    dispatch(print_barcode(id));
  };

  //show barcode status
  const barcodePrintedAction = (rowData) => {
    return rowData.barcode_printed == true ? (
      <a
        onClick={(e) => barcodePrinted(rowData.id)}
        href={`${BASE_URL}/qrcode/?id=${rowData.id}`}
        target="_blank"
      >
        Basıldı
      </a>
    ) : (
      <a
        onClick={(e) => barcodePrinted(rowData.id)}
        href={`${BASE_URL}/qrcode/?id=${rowData.id}`}
        target="_blank"
      >
        Basılmadı
      </a>
    );
    //return rowData.barcode_printed == true ? <a onClick={(e) => barcodePrinted(rowData.id)} target="_blank" style={{ "color": "green" }}>Basıldı</a> : <a onClick={(e) => barcodePrinted(rowData.id)} target="_blank" style={{ "color": "red" }}>Basılmadı</a>
  };

  const barcodePrintedBulk = (id) => {
    let barcodeID = new Date().getTime();
    dispatch(
      print_barcode_bulk(
        selectedProducts.map((d) => d.id),
        barcodeID
      )
    );
  };

  const coordinateReplaceOrderManagement = (coor, props) => {
    return (
      <React.Fragment>
        {
          props.header == "Enlem"
            ? typeof coor.address == "object"
              ? coor.address.latitude.toString().substring(0, 8)
              : coor.latitude.toString().substring(0, 8)
            : typeof coor.address == "object"
            ? coor.address.longitude.toString().substring(0, 8)
            : coor.longitude.toString().substring(0, 8)
          //(props.header == "Enlem") ? coor.address.geocode_score < 100 ? 0 : (typeof coor.address == "object" ? coor.address.latitude.toString().substring(0, 8) : coor.latitude.toString().substring(0, 8)) : coor.address.geocode_score < 100 ? 0 : (typeof coor.address == "object" ? coor.address.longitude.toString().substring(0, 8) : coor.longitude.toString().substring(0, 8))
        }
      </React.Fragment>
    );
  };

  const coordinateReplace = (coor, props) => {
    return (
      <React.Fragment>
        {props.header == "Enlem"
          ? typeof coor.address == "object"
            ? coor.address.latitude.toString().substring(0, 8)
            : coor.latitude.toString().substring(0, 8)
          : typeof coor.address == "object"
          ? coor.address.longitude.toString().substring(0, 8)
          : coor.longitude.toString().substring(0, 8)}
      </React.Fragment>
    );
  };

  // GeocodeEditDialog comp. clearing state
  useEffect(() => {
    dialogEdit == false && setOrderManagementAddressUpdate(null);
  }, [dialogEdit]);

  const orderAddressUpdateGeocodeEditVisible = (e, Rowdata) => {
    setDialogEdit(true);
    setOrderManagementAddressUpdate(true);
    // setSelectedDialogEdit(Rowdata)
    // setSelectedDialogEditTarget(e)
  };

  const geocodeEditVisible = (e, Rowdata) => {
    setDialogEdit(true);
    setSelectedDialogEdit(Rowdata);
    setSelectedDialogEditTarget(e);
  };

  const addPopupVisible = (Rowdata) => {
    setDialogAddPopup(true);
    if (!Rowdata) setProduct([]);
    Rowdata && setProduct(Rowdata);
    setAddPopupWhichComponent("Warehouse");
  };

  const addBranchVisible = (Rowdata) => {
    setDialogAddPopup(true);
    if (!Rowdata) setProduct([]);
    Rowdata && setProduct(Rowdata);
    setAddPopupWhichComponent("Branch");
  };

  const addPopupDelete = (Rowdata) => {
    // setDialogAddPopup(true);
    // Rowdata && setProduct(Rowdata)
    dispatch(deleteCustomerWarehouse(Rowdata.id));
  };

  const branchDelete = (Rowdata) => {
    dispatch(deleteBranch(Rowdata.id));
  };

  const branchDrawingDialogVisible = (Rowdata) => {
    setDialogBranchDrawing(true);
    setProduct(Rowdata);
  };

  const vehicleManagementDialogVisible = () => {
    setDialogVehicleManagement(true);
    setProduct(emptyProduct);
  };

  const driversManagementDialogVisible = () => {
    setDialogDriversManagement(true);
    setProduct(emptyProduct);
  };

  const customerUserAddDialogVisible = (Rowdata) => {
    setDialogCustomerUserAdd(true);
    setProduct(Rowdata);
  };

  const postponeVisitDialogVisible = () => {
    setDialogPostponeVisit(true);
  };

  const debitMultipleSelectiontDialogVisible = () => {
    setDialogDebitMultipleSelection(true);
  };

  const customerWarehouseDialogVisible = (RowData) => {
    setDialogCustomerWarehouse(true);
    dispatch(getCustomerWarehouse(RowData));
    dispatch({
      type: TYPES.SET_CUSTOMER_FIRM_ID,
      payload: RowData.id,
    });
  };

  const branchDialogVisible = (RowData) => {
    //console.log("bRowData ->", RowData);
    setDialogBranchAssignment(true);
    dispatch(warehouseListToBeAssigned());
    setProduct(RowData);

    // dispatch({
    //   type: TYPES.SELECTED_BRANCH,
    //   payload: RowData.id
    // });

    // setTimeout(() => {
    //   dispatch(
    //     branchAssignmnetAppointed(getBranchID.selectedBranch)
    //   )
    // }, 3000);

    // dispatch( // firm_id
    //   branchAssignmnetAppointed(RowData.id)
    // )
  };

  const debitDialogVisible = () => {
    setDialogDebit(true);
  };

  const vehicleManagementDialogEdit = (product) => {
    // drivers object format convert

    product.insurance_ext = product.insurance && product.insurance.id;
    product.inception_ext = product.inception && product.inception.id;
    product.k_certificate_ext =
      product.k_certificate && product.k_certificate.id;

    let copyProd = { ...product };

    let drivers = copyProd.drivers[0];
    delete copyProd.drivers;
    copyProd.drivers = drivers;

    let refrigerate =
      copyProd.refrigerate == true
        ? { name: "Var", code: "true" }
        : { name: "Yok", code: "false" };
    copyProd.refrigerate = refrigerate;

    setProduct({ ...copyProd });
    setDialogVehicleManagement(true);

    //       dispatch(
    //         vehicleDriversList(product.company && product.company.id)
    //      )
    //   };

    //   const driverManagementDialogEdit = product => {
    //     let get_row_driver = {...product};
    //     setProduct(get_row_driver);
    //     setDialogDriversManagement(true);
    //   };

    dispatch(vehicleDriversList(product.company && product.company.id));
  };

  const driversManagementDialogEdit = (product) => {
    product.src_ext = product.src_doc && product.src_doc.id;
    product.licence_ext = product.licence_doc && product.licence_doc.id;
    product.psycho_ext = product.psycho_doc && product.psycho_doc.id;

    setProduct({ ...product });
    setDialogDriversManagement(true);
    dispatch(driversVehicleList(product.company && product.company.id));
  };

  const geocodeEditAction = (RowData) => {
    return (
      <React.Fragment>
        <Button
          aa={RowData.address.address}
          icon="pi pi-map-marker"
          className="p-button-rounded p-button-warning"
          onClick={(e) => geocodeEditVisible(e, RowData)}
        />
      </React.Fragment>
    );
  };

  const openNew = (button) => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
    setModalType(button ? pageName + "-" + button : pageName + "-new");

    // api calls
    switch (button) {
      case "unplanned-order":
        dispatch(getUnplannedOrds());
        break;
      case "unplanned-order-send":
        dispatch(getUnplannedOrds());
        break;
      case "unplanned-geocoding":
        dispatch(getUnplannedOrdsUngeocoded());
        dispatch({
          type: TYPES.EMPTY_UNGEOCODED,
          payload: [],
        });
        break;
      case "unplanned-order-send-to-plan":
        dispatch(getUnplannedSendToPlan());
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    productDialog ? disableBodyScroll(document) : enableBodyScroll(document);
  }, [productDialog]);

  const hideDialog = () => {
    setProduct(emptyProduct);
    setSelectedProducts(null);
    setSubmitted(false);
    setProductDialog(false);
    setDialogVehicleManagement(false);
    setDialogDriversManagement(false);

    // dispatch({
    //   type: TYPES.CHECK_UNPLANNED,
    //   payload: {},
    // });
    dispatch({
      type: TYPES.EMPTY_CHECK_UNPLANNED,
      payload: {},
    });
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const geocoderSaveFooter = () => {
    //console.log("geocode: ", ungeocoded);
    var objects = [];
    for (var x = 0; x < ungeocoded.data.length; x++) {
      if (ungeocoded.changeCoordinate.length >= 1) {
        for (var j = 0; j < ungeocoded.changeCoordinate.length; j++) {
          if (
            ungeocoded.changeCoordinate[j].address_id ==
            ungeocoded.data[x].address.id
          ) {
            objects[x] = {
              address_id: ungeocoded.changeCoordinate[j].address_id,
              latitude: ungeocoded.changeCoordinate[j].latitude,
              longitude: ungeocoded.changeCoordinate[j].longitude,
            };
          } else {
            // geocode score 100
            // if (ungeocoded.data[x].address.geocode_score == 100) {
            //   objects[x] = { "address_id": ungeocoded.data[x].address.id, "latitude": ungeocoded.data[x].address.latitude, "longitude": ungeocoded.data[x].address.longitude };
            // }
          }
        }
      } else {
        // geocode score 100
        // if (ungeocoded.data[x].address.geocode_score == 100) {
        //   objects[x] = { "address_id": ungeocoded.data[x].address.id, "latitude": ungeocoded.data[x].address.latitude, "longitude": ungeocoded.data[x].address.longitude };
        // }
      }
    }

    if (objects.length == 0) {
      setdialogVs(false);
      toastCallback("warn", "Herhangi bir veri koordinatlanmadı!");
      window.location.reload();
      return;
    }

    dispatch(
      geocodeCoordinateSave(
        //ungeocoded.changeCoordinate,
        //objects,
        objects.filter((x) => x),
        setdialogVs,
        toastCallback,
        type
      )
    );
  };

  const hideGeocodeDialog = () => {
    //console.log("geocode: ", ungeocoded);

    // save when the popup is closed.(score == 100) Only 100 points.
    var objects = [];
    for (var x = 0; x < ungeocoded.data.length; x++) {
      // geocode score 100
      if (ungeocoded.data[x].address.geocode_score == 100) {
        objects[x] = {
          address_id: ungeocoded.data[x].address.id,
          latitude: ungeocoded.data[x].address.latitude,
          longitude: ungeocoded.data[x].address.longitude,
        };
      }
    }

    if (objects.length == 0) {
      setdialogVs(false);
      toastCallback("warn", "Herhangi bir veri koordinatlanmadı!");
      window.location.reload();
      return;
    }

    dispatch(
      geocodeCoordinateSave(
        //ungeocoded.changeCoordinate,
        //objects,
        objects.filter((x) => x),
        setdialogVs,
        toastCallback
      )
    );
    setdialogVs(false);
  };

  const toastCallback = (callback, message) => {
    if (callback === "success") {
      toast.current.show({
        severity: "success",
        //summary: t('successfull'),
        summary: "Başarılı",
        //detail: message ? message : "Updated",
        detail: message ? message : "Güncellendi",
        life: 3000,
      });
    }
    if (callback === "error") {
      toast.current.show({
        severity: "error",
        summary: t("error"),
        detail: message ? message : "error",
        life: 3000,
      });
    }
    if (callback === "warn") {
      toast.current.show({
        severity: "warn",
        summary: "Uyarı",
        detail: message ? message : "warn",
        life: 3000,
      });
    }
  };

  const validateMail = (mail) => {
    return /^\S+@\S{2,}\.\S{2,}$/g.test(mail);
  };

  var validateID = (id) => {
    const charArray = [...id];
    if (id.length !== 11 || charArray.some((val) => isNaN(Number(val))))
      return false;

    const reducer = (prev, current, i) =>
      i < 10 ? (Number(prev) + Number(current)).toString() : prev;
    const totalFirstTenDigits = ~~charArray.reduce(reducer);
    return totalFirstTenDigits % 10 === Number(id[id.length - 1]);
  };

  // const vehicleManangementNew = (par) => {
  //   console.log(par.test);
  // };

  const unplannedCheckRD = useSelector((state) => state.unplannedCheckReducer);

  const saveProduct = (saveType, event) => {
    //setSubmitted(true);
    switch (saveType) {
      case "Customer-new":
        setSubmitted(false);

        if (
          product.address == undefined ||
          product.customer == null ||
          //product.code == undefined ||
          product.compony == undefined ||
          product.county == undefined ||
          product.name == undefined ||
          product.phone_1 == undefined ||
          Object.keys(product).length === 0
        ) {
          alert("boş değerler var");
          return;
        }
        dispatch(
          customerAdd(
            product,
            (success) => {
              if (success) toastCallback("success");
            },
            (error) => {
              if (error) toastCallback("error", error);
            }
          )
        );
        setProductDialog(false);
        break;
      case "Customer-update":
        setSubmitted(false);

        if (
          product.address == "" ||
          product.code == "" ||
          product.compony == "" ||
          product.county == "" ||
          product.name == "" ||
          product.phone_1 == ""
        ) {
          alert("boş değerler var");
          return;
        }

        if (
          product.customer ? product.customer == null : product.contact == null
        ) {
          toastCallback("error", "Select contact, okay ?");
          return;
        }

        let customer_address = product.address;
        var address;

        if (typeof customer_address === "object" && customer_address != null) {
          address = customer_address.address;
        } else address = customer_address;

        let formatData = {
          id: product.id,
          code: product.code,
          name: product.name,
          barcode: product.barcode || "",
          contact_user_oid: product.customer
            ? product.customer.id
            : product.contact.id,
          is_follow: product.firm_is_follow == null ? false : true,
          address: {
            address: address,
            address_code_ext: "",
            address_line: "",
            city: product.city,
            code: "",
            country: "",
            county:
              product.county === ""
                ? ""
                : product.county || product.address.county,
            district: "",
            email: "",
            first_name:
              product.compony === ""
                ? ""
                : product.compony || product.address.first_name,
            gsm_number:
              product.phone_1 === ""
                ? ""
                : product.phone_1 || product.address.gsm_number,
            last_name: "",
            latitude: 0,
            longitude: 0,
            post_code: "",
            state: "",
            street: "",
          },
        };

        server
          .updateCustomer(formatData)
          .then((result) => {
            let res = result.entity.data[0];
            // console.log(res);
            let updatedCustomerIndex = products.findIndex(
              (products) => products.id == res.id
            );
            products[updatedCustomerIndex] = res;

            setProducts(products);
            setProduct(emptyProduct);
            toastCallback("success");
          })
          .catch((error) => toastCallback("error", error));

        setProductDialog(false);
        break;
      case "Project-new":
        setSubmitted(false);

        if (
          product.application_due_date == null ||
          product.end_date == undefined ||
          product.start_date == undefined ||
          product.customer == null ||
          Object.keys(product).length === 0 ||
          product.loading_points == undefined ||
          product.quota == undefined ||
          product.requirements == undefined
        ) {
          toastCallback("warn", "boş değerler var");
          return;
        } else if (product.end_date < product.start_date) {
          toastCallback("warn", "başlangıç tarihi bitişten küçük olamaz");
          return;
        }

        dispatch(
          projectAdd(
            product,
            (success) => {
              if (success) toastCallback("success");
            },
            (error) => {
              if (error) toastCallback("error", error.message);
            }
          )
        );
        setProductDialog(false);
        break;
      case "Project-update":
        setSubmitted(false);
        if (
          product.end_date == "" ||
          product.application_due_date == "" ||
          product.loading_points == "" ||
          product.quota == "" ||
          product.requirements == "" ||
          product.start_date == ""
        ) {
          toastCallback("error", "boş değerler var");
          return;
        }

        dispatch(
          projectUpdate(
            //obj,
            product,
            (res) => {
              let updatedCustomerIndex = products.findIndex(
                (products) => products.id == res.id
              );

              products[updatedCustomerIndex] = res;
              setProducts(products);
              setProduct(emptyProduct);
              toastCallback("success");
            },
            (error) => toastCallback("error", error.message)
          )
        );
        setProductDialog(false);
        break;
      case "order-management-manual-order":
        setSubmitted(false);
        dispatch(
          manuelOrderAdd(
            product,
            (success) => {
              if (success) toastCallback("success", "New Order Added");
            },
            (error) => {
              if (error) toastCallback("error", error.message);
            }
          )
        );
        setProductDialog(false);
        break;
      case "order-management-new":
      case "admin-order-management-new":
        if (!product.file) {
          toastCallback("warn", "Excel yüklemediniz!");
          return;
        }
        uploadExcelHandler(product);
        setProductDialog(false);
        // setSubmitted(false);
        break;

      case "order-management-unplanned-order":
        //Check if input is selected
        if (
          unplannedCheckRD.data == undefined ||
          unplannedCheckRD.data.length == 0
        ) {
          toastCallback("warn", "Sipariş seçmediniz!");
          return;
        }
        dispatch(
          getUnplannedByDate(
            unplannedCheckRD.data,
            toastCallback,
            setProductDialog
          )
        );
        // dispatch({
        //   type: TYPES.CHECK_UNPLANNED,
        //   payload: {}
        // });
        dispatch({
          type: TYPES.EMPTY_CHECK_UNPLANNED,
          payload: {},
        });

        break;

      case "order-management-unplanned-geocoding":
        //Check if input is selected
        if (
          unplannedCheckRD.data == undefined ||
          unplannedCheckRD.data.length == 0
        ) {
          toastCallback("warn", "Sipariş seçmediniz!");
          return;
        }
        //clear previous geocodeo data
        dispatch({
          type: TYPES.CLEAR_UNGEOCODED,
          payload: [],
        });
        dispatch(getUnplannedByDateGeocode(unplannedCheckRD.data));
        setdialogVs(true);
        setProductDialog(false);
        // GEOCODİNG ???
        // dispatch({
        //   type: TYPES.CHECK_UNPLANNED,
        //   payload: {}
        // });
        dispatch({
          type: TYPES.EMPTY_CHECK_UNPLANNED,
          payload: {},
        });
        break;

      case "order-management-unplanned-order-send":
        alert("dsfdf");
        break;
      case "order-management-unplanned-order-send-to-plan":
        //Check if input is selected
        if (
          unplannedCheckRD.data == undefined ||
          unplannedCheckRD.data.length == 0
        ) {
          toastCallback("warn", "Sipariş seçmediniz!");
          return;
        }
        dispatch(
          submittedPlansAction(
            unplannedCheck.data,
            toastCallback,
            setProductDialog
          )
        );
        // dispatch({
        //   type: TYPES.CHECK_UNPLANNED,
        //   payload: {}
        // });
        dispatch({
          type: TYPES.EMPTY_CHECK_UNPLANNED,
          payload: {},
        });
        break;
      case "order-management-update-order":
        //console.log(product);
        dispatch(
          updateOrder(
            product,
            (res, success) => {
              // if(res) {
              // let updatedOrderIndex = products.findIndex(
              //   (orders) => orders.id == res.id
              // );

              // for(var i=0; i<products.length; i++){
              //   if(products[i].id == res.id) products[i] = res;
              // }
              var search = (arr, item) => {
                return arr.indexOf(item);
              };
              // products[updatedOrderIndex] = res;
              var order_index = search(products, res.customer.id);

              if (order_index == -1) {
                products.push(res);
              } else products[order_index] = res;

              setProducts(products);
              setProduct(emptyProduct);

              setOrderUpdated(success);
              toastCallback("success");
              //  }
            },
            (error) => {
              if (error) toastCallback("error", error.message);
            }
          )
        );
        setProductDialog(false);
        break;
      case "vehicle-mannagement-dialog":
        if (product.id) {
          dispatch(
            vehicleUpdate(
              product,
              (res) => {
                if (res.success) {
                  toastCallback("success", "Eklendi");
                  setDialogVehicleManagement(false);
                }
              },
              (error) => {
                toastCallback("error", error.message);
              }
            )
          );
        } else {
          dispatch(
            vehicleAdd(
              product,
              (res) => {
                if (res.success) {
                  toastCallback("success", "Eklendi");
                  setDialogVehicleManagement(false);
                }
              },
              (error) => {
                toastCallback("error", error.message);
              }
            )
          );
        }

        break;
      case "drivers-mannagement-dialog":
        if (product.id) {
          dispatch(
            driverUpdate(
              product,
              (res) => {
                if (res.success) {
                  toastCallback("success", "Güncellendi");
                  setDialogDriversManagement(false);
                }
              },
              (error) => {
                toastCallback("error", error.message);
              }
            )
          );
        } else {
          let emailCheck = validateMail(product.email);
          if (emailCheck == false) {
            toastCallback("warn", "E-mail formatı uygun değil");
            return;
          }

          // if(product.identity_number){
          //   toastCallback('warn', 'TC kimlik no boş bırakılamaz');
          //   return
          // }

          //If no ID number, continue
          if (product.identity_number) {
            let identityNumberCheck = validateID(product.identity_number);
            if (identityNumberCheck == false) {
              toastCallback("warn", "TC kimlik no formatı uygun değil");
              return;
            }
          }

          if (!product.phone) {
            toastCallback("warn", "Telefon alanı boş bırakılamaz");
            return;
          }

          let phoneCheck = product.phone.substring(0, 2);

          if (phoneCheck[0] != "5" && phoneCheck[1] != "5") {
            toastCallback("warn", "Telefon numarası formatı uygun değil");
            return;
          } else {
            product.phone =
              phoneCheck[0] == "0"
                ? product.phone.substring(1, 11)
                : product.phone.substring(0, 11);
          }

          dispatch(
            driversAdd(
              product,
              toastCallback,
              setDialogDriversManagement
              // res => {
              //   if (res.success) {
              //     toastCallback('success', 'Eklendi');
              //     setDialogDriversManagement(false);
              //   }
              //   // else if (res == "E0001") {
              //   //   toastCallback('warn', 'Telefon veya Email daha önce girilmiş'); // !!!!!! Error.
              //   // }
              // },
              // error => {
              //   toastCallback('error', error.message);
              // }
            )
          );
        }

        break;
      case "ProjectDetail-update":
        dispatch(
          JobUpdate(
            product,
            (res) => {
              if (res.success) {
                toastCallback("success", "Guncelendi");
              }
            },
            (error) => alert(error)
          )
        );

        //alert('not allowed');
        break;
      default:
        break;
    }
  };

  const uploadExcelHandler = (product) => {
    const file = product.file;
    //const warehouseOid = product.warehouse;
    //if there is only one warehouse
    //var warehouseOid = allWarehouseList.data.length > 1 ? product.warehouse : allWarehouseList.data[0];
    var warehouseOid = product.warehouse
      ? product.warehouse
      : activeWarehouse.data[0];
    var date;
    if (!product.date) {
      date = new Date().toISOString().split("T")[0];
    } else {
      var newDt = new Date(product.date).setHours(3);
      date = new Date(newDt).toISOString().split("T")[0];
    }

    //admin
    if (!warehouseOid) {
      warehouseOid = product.adminWarehouse.id;
    }

    const fileReader = new FileReader();

    // fileReader.onload = e => {
    // server.uploadExcel(e.target.result, warehouseOid);
    dispatch(
      uploadExcelAction(
        file,
        warehouseOid,
        date,
        (success) => {
          if (success) {
            toastCallback("success", "New Orders Uploaded");
            setNewOrders(success);
            //setdialogVs(true);

            //excel file is loaded
            //geocode screen opened

            //clear previous geocodeo data
            dispatch({
              type: TYPES.CLEAR_UNGEOCODED,
              payload: [],
            });

            dispatch(
              getUnplannedByDateGeocode({
                value: new Date(
                  new Date(date).setDate(new Date(date).getDate() - 1)
                ),
              })
            );
            setdialogVs(true);
            setProductDialog(false);
          }
        },
        (error) => {
          if (error) {
            switch (error.code) {
              case "E0000":
                toastCallback("error", "bilinmedik hata");
                break;

              default:
                toastCallback("error", error.message);
                break;
            }
          }
        },
        setdialogVs,
        toastCallback
      )
    );
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
    setModalType(pageName + "-update");
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
    //setDeleteSingleRecord(opts)
  };

  const deleteProduct = () => {
    if (type === "Project") {
      server
        .delProject(product.id)
        .then((res) => {
          let _products = products.filter((val) => val.id != product.id);
          setProducts(_products);
          setDeleteProductDialog(false);
          setSelectedProducts(null);
          setProduct(emptyProduct);

          toastCallback("success", "Deleted");
        })
        .catch((error) => toastCallback("error", error.message));
    }

    if (type === "Customer") {
      dispatch(
        customerDelete(
          product,
          (success) => {
            if (success) toastCallback("success", "Deleted");
          },
          (error) => {
            if (error) toastCallback("error", error);
          }
        )
      );
      setDeleteProductDialog(false);
      // server
      //    .delCustomer(product.id)
      //    .then(res => {
      //       let _products = products.filter(val => val.id != product.id);
      //       setProducts(_products);
      //       setDeleteProductDialog(false);
      //       setSelectedProducts(null);
      //       setProduct(emptyProduct);

      //       deleteCustomerCallback(res.entity.success);
      //       toastCallback("success", "Deleted");
      //   })
      //   .catch((error) => toastCallback("error", error.message));
    }

    if (type === "order-management" || type === "admin-order-management") {
      /**
       * Multiple delete from table
       * SelectedProduct, check and delete
       */

      // if(selectedProducts && Object.keys(selectedProducts).length > 1) {
      //   //console.log(selectedProducts);
      //   let oIds = selectedProducts.map(e => e.id);
      //   dispatch(
      //     orderMultipleDelete(oIds, toastCallback, setSelectedProducts)
      //   )
      // }else{
      //   dispatch(
      //     deleteOrderUnplanned(product, toastCallback)
      //   )
      // }

      /**
       * Multiple delete from table
       * Check product and product length, and then delete an product element
       */

      //if(product && deleteSingleRecord ) {

      //delete product.projects;
      //if (product && product.projects == undefined && Object.keys(product).length > 0) {

      if (selectedProducts == null) {
        //single order deletion
        //console.log(selectedProducts);
        dispatch(deleteOrderUnplanned(product, toastCallback));
        setProduct(emptyProduct);
        //setDeleteSingleRecord(false)
      } else {
        let oIds = selectedProducts.map((e) => e.id);
        dispatch(orderMultipleDelete(oIds, toastCallback, setSelectedProducts));
      }

      setDeleteProductDialog(false);
    }

    if (type == "vehicle-management") {
      dispatch(
        deleteVehicleAction(
          product.id,
          (res) => {
            if (res.success) {
              toastCallback("success", "Silindi");
              setDeleteProductDialog(false);
              setDeletedVehicle(true);
            }
          },
          (error) => {
            if (error) {
              toastCallback("error", error.message);
            }
          }
        )
      );
    }

    if (type == "driver-management") {
      dispatch(
        deleteDriverAction(
          product.id,
          (res) => {
            if (res.success) {
              toastCallback("success", "Silindi");
              setDeleteProductDialog(false);
              //setDeletedVehicle(true);
            }
          },
          (error) => {
            if (error) {
              toastCallback("error", error.message);
            }
          }
        )
      );
    }

    if (type == "LiveTracking") {
      dispatch(
        willBeReturn(
          product.order_oid,
          (res) => {
            if (res === 200) {
              toastCallback("success");
              setDeleteProductDialog(false);
              hideDialog();
            }
          },
          (error) => {
            if (error) {
              toastCallback("error", error.message);
            }
          }
        )
      );
    }

    if (type == "dialog-table-customer-warehouse") {
      dispatch(
        deleteCustomerWarehouse(
          product.id,
          (res) => {
            if (res == 200) {
              toastCallback("success", "Silindi");
              setDeleteProductDialog(false);
              //setDeletedVehicle(true);
            }
          },
          (error) => {
            if (error) {
              toastCallback("error", error.message);
            }
          }
        )
      );
    }

    if (type == "admin-branch") {
      dispatch(
        deleteBranch(
          product.id,
          (res) => {
            if (res == 200) {
              toastCallback("success", "Silindi");
              setDeleteProductDialog(false);
              //setDeletedVehicle(true);
            }
          },
          (error) => {
            if (error) {
              toastCallback("error", error.message);
            }
          }
        )
      );
    }

    // let _products = products.filter(val => val.id != product.id);
    // setProducts(_products);
    // setDeleteProductDialog(false);
    // setSelectedProducts(null);
    // setProduct(emptyProduct);
    // toastCallback("success", "Deleted");
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  const exportCSV = () => {
    // exportCSV(options: { selectionOnly: boolean })
    if (type == "LiveTracking") {
      // let columnsFilter= dt.current.props.columns.filter(x => x.key != "delivery_status");
      // dt.current.props = columnsFilter;
      //dt.current.exportCSV({ selectionOnly: false });
      dt.current.props.columns.findIndex(function (item, i) {
        //if (item && item.key == "delivery_status") {
        if (item && item.key == "delivery") {
          dt.current.props.columns.splice(6, 3); // hide excel columns
        }
      });
      //dt.current.props.value.map(d => d.arrival_time = convertSecondsToHourFormat(d.arrival_time));
      dt.current.props.value.map(function (val, index) {
        val.order_code = "\xa0" + val.order_code;
        val.arrival_time = convertSecondsToHourFormat(val.arrival_time);
        val.delivery_date = new Date(val.delivery_date).toLocaleDateString(
          "tr-TR"
        );
        val.order_date = new Date(val.order_date).toLocaleDateString("tr-TR");
        //val.delivery_status = excelDeliveryStatus(val);
        val.filter = excelDeliveryStatus(val);
      });
      dt.current.exportCSV({ selectionOnly: false });
    } else if (type == "vehicle-usage-report") {
      // excel update in vehicle reports
      dt.current.props.value.map(function (val, index) {
        val.status = val.status == "ACTIVE" ? "Aktif" : "Pasif";
        val.refrigerate = val.refrigerate == true ? "Var" : "Yok";
        val.driver =
          (val.first_name && val.first_name + " " + val.last_name) || "";
        val.uStatus = val.delivered > 0 ? "Evet" : "Hayır";
        val.brandModal = val.vehicle_brand_name + " " + val.vehicle_model_name;
        val.planned_distance = (val.planned_distance / 1000)
          .toFixed(1)
          .replace(".", ",");
        val.completed_distance = (val.completed_distance / 1000)
          .toFixed(1)
          .replace(".", ",");
        val.maintanence_km = (val.maintanence_km / 1000)
          .toFixed(1)
          .replace(".", ",");
      });
      dt.current.exportCSV({ selectionOnly: false });
    } else if (type == "driver-management") {
      dt.current.props.value.map(function (val, index) {
        val.status = val.status == "ACTIVE" ? "Aktif" : "Pasif";
        val.birthday = (val.birthday && val.birthday.split("T")[0]) || "";
        val.vehicles = val.vehicles[0].name;
        val.licence_doc =
          (val.licence_doc && val.licence_doc.doc_expiration.split("T")[0]) ||
          "";
        val.criminal_record =
          val.criminal_record &&
          ((val.criminal_record.doc_expiration &&
            val.criminal_record.doc_expiration.split("T")[0]) ||
            val.criminal_record.created_at.split("T")[0]);
        val.src_doc =
          (val.src_doc && val.src_doc.doc_expiration.split("T")[0]) || "";
        val.psycho_doc =
          (val.psycho_doc && val.psycho_doc.doc_expiration.split("T")[0]) || "";
      });
      dt.current.exportCSV({ selectionOnly: false });
    } else if (type == "vehicle-management") {
      dt.current.props.value.map(function (val, index) {
        val.status = val.status == "ACTIVE" ? "Aktif" : "Pasif";
        val.refrigerate = val.refrigerate == true ? "Var" : "Yok";
        val.projects = vehicleProjectData.data.data
          .map((d) => val.projects.includes(d.project_id) == true && d.name)
          .filter((d) => d != false);
        val.zones = vehicleZoneData.data.data.map((d) =>
          d.zones
            .map((z) => val.zones.includes(z.zone_id) == true && z.name)
            .filter((d) => d != false)
        );
        val.drivers = val.drivers[0].name;
        val.k_certificate =
          (val.k_certificate &&
            val.k_certificate.doc_expiration.split("T")[0]) ||
          "";
        val.insurance =
          (val.insurance && val.insurance.doc_expiration.split("T")[0]) || "";
        val.inception =
          (val.inception && val.inception.doc_expiration.split("T")[0]) || "";
        val.maintanence_date =
          (val.maintanence_date && val.maintanence_date.split("T")[0]) || "";
        val.vehicle_licence =
          (val.vehicle_licence &&
            val.vehicle_licence.doc_expiration.split("T")[0]) ||
          "";
      });
      dt.current.exportCSV({ selectionOnly: false });
    } else if (type == "ProjectDetail") {
      dt.current.props.value.map(function (val, index) {
        val.user.birthday =
          (val.user.birthday && val.user.birthday.split("T")[0]) || "";
        val.created_at = (val.created_at && val.created_at.split("T")[0]) || "";
      });
      dt.current.exportCSV({ selectionOnly: false });
    } else if (type == "order-management" || type == "admin-order-management") {
      dt.current.props.value.map(function (val, index) {
        var debitTypeExcel;
        switch (val.debit_type) {
          case "ORDER_ACCEPTED":
            debitTypeExcel = "Paket kabul yapıldı";
            break;
          case "BRANCH_ACCEPTED":
            debitTypeExcel = "Şube kabul yapıldı";
            break;
          case "BRANCH_RETURN_ACCEPTED":
            debitTypeExcel = "Şubeye iade kabul yapıldı";
            break;
          case "COURIER_DEBIT":
            debitTypeExcel = "Kuryeye zimmetlendi";
            break;
          case "-":
            debitTypeExcel = "Paket kabul bekliyor";
            break;
          default:
            debitTypeExcel = "-";
            break;
        }
        val.debit_type = debitTypeExcel;
        val.barcode_printed =
          val.barcode_printed == true ? "Basıldı" : "Basılmadı";
        val.customer.first_name =
          val.customer.first_name &&
          val.customer.first_name +
            " " +
            ((val.customer.last_name && val.customer.last_name) || "");
        val.order_code = "\xa0" + val.order_code; // "\xa0" character -> prevent excel from rounding order number
      });
      dt.current.exportCSV({ selectionOnly: false });
    } else {
      dt.current.exportCSV({ selectionOnly: false });
    }
  };

  const exportPdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const export_columns_current = dt.current.props.columns;
    const data = dt.current.props.value;
    const pdf_table_customer = [
      {
        code: "",
        name: "",
        address: "",
        county: "",
        first_name: "",
        gsm_number: "",
        contact: "",
      },
    ];
    const pdf_table_orders = [
      {
        code: "",
        order_code: "",
        first_name: "",
        address: "",
        city: "",
        latitude: "",
        longitude: "",
        gsm_number: "",
        deci: "",
        quantity: "",
        sms_notification: "",
        taken_by: "",
        barcode: "",
        email: "",
        delivery_note: "",
      },
    ];

    const pdf_table_live = [
      {
        order_code: "",
        order_date: "",
        name: "",
        gsm_number: "",
        delivery_status: "",
        delivery_status: "",
        delivery_status: "",
        delivery_date: "",
        arrival_time: "",
        driver_name: "",
        driver_phone: "",
      },
    ];

    const pdf_table_vehicle_usage_report = [
      {
        date: "",
        vehicle_id: "",
        company_name: "",
        status: "",
        reason: "",
        vehicle_brand_model: "",
        refrigerate: "",
        driver: "",
        maintanence_expiration: "",
        maintanence_km: "",
        zone: "",
        project_names: "",
        total: "",
        delivered: "",
        not_delivered: "",
        planned_distance: "",
        completed_distance: "",
      },
    ];

    const pdf_table_job = [
      {
        company_code: "",
        created_at: "",
        company_name: "",
        first_name: "",
        last_name: "",
        identity_number: "",
        birthday: "",
        loading_points: "",
        drivers: "",
        vehicles: "",
      },
    ];

    var columns;

    data.forEach((row) => {
      switch (type) {
        case "order-management":
          pdf_table_orders.push({
            code: row.customer.code,
            order_code: row.order_code,
            first_name: row.customer.first_name,
            address: row.address.address.removeTurkishCharacters(),
            city: row.address.city,
            latitude: row.address.latitude,
            longitude: row.address.longitude,
            gsm_number: row.customer.gsm_number,
            deci: row.deci,
            quantity: row.products[0].quantity,
            sms_notification: row.sms_notification && row.sms_notification,
            taken_by: row.taken_by,
            barcode: row.barcode,
            email: row.customer.email,
            delivery_note: row.customer.delivery_note,
          });

          columns = [
            {
              field: "code",
              header: "Muşteri No".removeTurkishCharacters(),
            },
            {
              field: "order_code",
              header: "Sipariş No".removeTurkishCharacters(),
            },
            {
              field: "first_name",
              header: "Alıcı Adı".removeTurkishCharacters(),
            },
            {
              field: "address",
              header: "Alıcı Adresi".removeTurkishCharacters(),
            },
            {
              field: "city",
              header: "İl/İlçe".removeTurkishCharacters(),
            },
            {
              field: "latitude",
              header: "Enlem",
            },
            {
              field: "longitude",
              header: "Boylam",
            },
            {
              field: "gsm_number",
              header: "Cep Telefonu",
            },
            {
              field: "deci",
              header: "Desi",
            },
            {
              field: "quantity",
              header: "Paket Sayısı".removeTurkishCharacters(),
            },
            {
              field: "sms_notification",
              header: "SMS Bilgilendirme",
            },
            {
              field: "taken_by",
              header: "Gonderen",
            },
            {
              field: "barcode",
              header: "Barkod",
            },
            {
              field: "email",
              header: "Email",
            },
            {
              field: "delivery_note",
              header: "Not",
            },
          ];

          // const export_orders = columns.map((col) => ({
          //   title: col.header,
          //   dataKey: col.field,
          // }));

          // doc.autoTable(export_orders, pdf_table_orders);

          break;
        case "Customer":
          pdf_table_customer.push({
            code: row.code,
            name: row.name.removeTurkishCharacters(),
            address: row.address
              ? typeof row.address.address === "string"
                ? row.address.address.removeTurkishCharacters()
                : null
              : null,
            county: row.address
              ? typeof row.address.county === "string"
                ? row.address.county.removeTurkishCharacters()
                : null
              : null,
            first_name: row.address
              ? typeof row.address.first_name === "string"
                ? row.address.first_name.removeTurkishCharacters()
                : null
              : null,
            gsm_number: row.address ? row.address.gsm_number : null,
            contact: row.contact ? row.contact.username : null,
          });

          columns = [
            {
              field: "code",
              header: "Müşteri Kodu".removeTurkishCharacters(),
              sortable: true,
            },
            {
              field: "name",
              header: "Müşteri Adı".removeTurkishCharacters(),
              sortable: true,
            },
            { field: "address", header: "Adres", sortable: true },
            {
              field: "county",
              header: "İlçe/il".removeTurkishCharacters(),
              sortable: true,
            },
            {
              field: "first_name",
              header: "Şirket Yetkilisi".removeTurkishCharacters(),
              sortable: true,
            },
            { field: "gsm_number", header: "Telefon-1", sortable: true },
            { field: "contact", header: "Bringo Sorumlusu", sortable: true },
          ];

          // const export_customers = columns.map((col) => ({
          //   title: col.header,
          //   dataKey: col.field,
          // }));

          // doc.autoTable(export_customers, pdf_table_data);

          break;
        case "LiveTracking":
          pdf_table_live.push({
            order_code: row.order_code,
            order_date: row.order_date,
            name: row.name,
            gsm_number: row.gsm_number,
            // delivery_status: row.delivery_status === "DELIVERED",
            // delivery_status: row.delivery_status,
            // delivery_status:row.delivery_status,
            delivery_status: [
              "IN_WAREHOUSE",
              "DELIVERY_PLANNED",
              "ON_BOARD",
            ].includes(row.delivery_status)
              ? "Depoda"
              : [
                  "ON_THE_WAY_TO_CUSTOMER",
                  "CUSTOMER_INFORMED_1",
                  "CUSTOMER_INFORMED_2",
                  "CUSTOMER_READINESS_RECEIVED",
                ].includes(row.delivery_status)
              ? "Dağıtımda".removeTurkishCharacters()
              : row.delivery_status == "CUSTOMER_INFORMED_3"
              ? "Son 30 dakika"
              : row.delivery_status === "DELIVERED"
              ? "Teslim edildi"
              : row.delivery_status === "PARTIALLY_DELIVERED"
              ? "Kısmen teslim edildi".removeTurkishCharacters()
              : row.delivery_status === "NOT_DELIVERED"
              ? "Teslim edilmedi".removeTurkishCharacters()
              : row.delivery_status === "DELIVERY_POSTPONED"
              ? "Teslim ertelendi"
              : "",
            delivery_date: row.delivery_date,
            arrival_time: row.arrival_time,
            driver_name: row.driver_name,
            driver_phone: row.driver_phone,
          });

          columns = [
            {
              field: "order_code",
              header: "Sipariş kodu".removeTurkishCharacters(),
            },
            {
              field: "order_date",
              header: "Sipariş Tarihi".removeTurkishCharacters(),
            },
            {
              field: "name",
              header: "Sipariş veren".removeTurkishCharacters(),
            },
            {
              field: "gsm_number",
              header: "Telefon",
            },
            {
              field: "delivery_status",
              header: "Teslim Durumu",
            },
            {
              field: "delivery_date",
              header: "Teslimat Tarihi",
            },
            {
              field: "arrival_time",
              header: "Teslim Saati",
            },
            {
              field: "driver_name",
              header: "Kurye Adı Soyadı".removeTurkishCharacters(),
            },
            {
              field: "driver_phone",
              header: "Kurye Telefon",
            },
          ];
          break;
        case "ProjectDetail":
          pdf_table_job.push({
            company_code: row.advertisement
              ? row.adversisement.company_code
              : null,
            created_at: row.created_at,
            company_name: row.company_name,
            first_name: row.user.first_name,
            last_name: row.user.last_name,
            identity_number: row.user.identity_number,
            birthday: row.user.birthday,
            loading_points: row.advertisement
              ? row.advertisement.loading_points
              : null,
            drivers: row.drivers.length,
            vehicles: row.vehicles.length,
          });

          columns = [
            {
              field: "company_code",
              header: "Başvuru No".removeTurkishCharacters(),
            },
            {
              field: "created_at",
              header: "Başvuru Tarihi".removeTurkishCharacters(),
            },
            {
              field: "company_name",
              header: "Şirket Adı".removeTurkishCharacters(),
            },
            {
              field: "first_name",
              header: "Adı".removeTurkishCharacters(),
            },
            {
              field: "last_name",
              header: "Soyadı".removeTurkishCharacters(),
            },
            {
              field: "identity_number",
              header: "TCKN",
            },
            {
              field: "birthday",
              header: "Doğum Tarihi".removeTurkishCharacters(),
            },
            {
              field: "loading_points",
              header: "il/ilçe",
            },
            {
              field: "drivers",
              header: "Araç Sayısı".removeTurkishCharacters(),
            },
            {
              field: "vehicles",
              header: "Sürücü Sayısı".removeTurkishCharacters(),
            },
          ];
          break;
        case "vehicle-usage-report":
          pdf_table_vehicle_usage_report.push({
            date: row.date,
            vehicle_id: row.vehicle_id,
            company_name: row.company_name,
            status: row.status,
            reason: row.reason,
            vehicle_brand_model: "",
            refrigerate: row.refrigerate,
            driver: "",
            maintanence_expiration: row.maintanence_expiration,
            maintanence_km: row.maintanence_km,
            zone: row.zone,
            project_names: row.project_names,
            total: row.total,
            delivered: row.delivered,
            not_delivered: row.not_delivered,
            planned_distance: row.planned_distance,
            completed_distance: row.completed_distance,
          });

          columns = [
            {
              field: "date",
              header: "Tarih".removeTurkishCharacters(),
            },
            {
              field: "vehicle_id",
              header: "Plaka".removeTurkishCharacters(),
            },
            {
              field: "company_name",
              header: "Firma".removeTurkishCharacters(),
            },
            {
              field: "status",
              header: "Aktif/Pasif".removeTurkishCharacters(),
            },
            {
              field: "reason",
              header: "Pasifse Nedeni".removeTurkishCharacters(),
            },
            {
              field: "vehicle_brand_model",
              header: "Marka/Model".removeTurkishCharacters(),
            },
            {
              field: "refrigerate",
              header: "Frigo / izolasyon - var?".removeTurkishCharacters(),
            },
            {
              field: "driver",
              header: "Sürücü".removeTurkishCharacters(),
            },
            {
              field: "maintanence_expiration",
              header: "Son Bakım Tarihi".removeTurkishCharacters(),
            },
            {
              field: "maintanence_km",
              header: "Son Bakım KM".removeTurkishCharacters(),
            },
            {
              field: "zone",
              header: "Bölge".removeTurkishCharacters(),
            },
            {
              field: "project_names",
              header: "Çalıştığı Projeler".removeTurkishCharacters(),
            },
            {
              field: "total",
              header: "Günlük Teslimat Sayısı".removeTurkishCharacters(),
            },
            {
              field: "delivered",
              header: "Başarılı Teslimat Sayısı".removeTurkishCharacters(),
            },
            {
              field: "not_delivered",
              header: "Başarısız Teslimat Sayısı".removeTurkishCharacters(),
            },
            {
              field: "planned_distance",
              header: "Günlük Planlanan Km".removeTurkishCharacters(),
            },
            {
              field: "completed_distance",
              header: "Gerçekleşen Km".removeTurkishCharacters(),
            },
          ];
        default:
          break;
      }
    });

    const exportColumns = columns.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    if (type == "order-management")
      doc.autoTable(exportColumns, pdf_table_orders);
    if (type == "Customer") doc.autoTable(exportColumns, pdf_table_customer);
    if (type == "LiveTracking") doc.autoTable(exportColumns, pdf_table_live);
    if (type == "ProjectDetail") doc.autoTable(exportColumns, pdf_table_job);
    if (type == "vehicle-usage-report")
      doc.autoTable(exportColumns, pdf_table_vehicle_usage_report);

    doc.save(`${type}.pdf`);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toastCallback("success", "Products deleted");
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };
    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name, contactType) => {
    var val = null;
    var file = null;

    if (e.target && e.target.files) file = e.target && e.target.files[0];
    if (e.target && e.target.value) val = e.target && e.target.value;
    if (e.value) val = e.value;
    //if (e.checked) val = e.checked;

    let _product = { ...product };

    if (file) _product[`${name}`] = file;
    //else name == "address" ? _product[`${name}`].address = val : _product[`${name}`] = val;
    else if (modalType == "Customer-update" && name == "address") {
      _product[`${name}`].address = val;
    } else _product[`${name}`] = val;

    // server.updateCustomer(_product);
    //delete _product.customer;

    if (contactType == "warehouse") product.warehouse = e.value;
    if (contactType == "customer") product.customer = e.value;

    setProduct(_product);
  };

  const zoneAddProduct = (obj, name) => {
    let _product2 = { ...product };
    _product2[`${name}`] = obj;
    setProduct(_product2);
    //console.log("PPPPPPPPP ykp: ", _product2);
  };

  const onInputChangeCalendar = (obj, name) => {
    let _product2 = { ...product };

    //let aa = { "id": obj.target.id, "doc_expiration": obj.target.value }

    _product2[`${name}`].doc_expiration = obj.target.value;

    setProduct(_product2);
  };

  const onFileLoad = (e, name) => {
    setFileEvent(e.target.files);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const submittedPlansClicked = () => {
    dispatch(submittedPlansAction(toastCallback));
  };

  //debit
  // when select box changes, get data
  // start ============================>

  useEffect(() => {
    product.customer_id && dispatch(getCustomerCountyUser(product.customer_id));
    if (
      product.customer_id &&
      debitList.data != null &&
      Object.keys(debitList.data).length > 0
    ) {
      setProducts(debitList.data);
    } else if (product.customer_id) {
      setProducts(null);
    }
  }, [product]);

  useEffect(() => {
    if (location.pathname === "/driver-management" && debitList.data) {
      dispatch(driverList(true));
      if (
        product.customer_id &&
        debitList.data != null &&
        Object.keys(debitList.data).length > 0
      ) {
        setProducts(debitList.data);
      } else if (product.customer_id) {
        setProducts(null);
      }
    }
  }, [debitList]);

  // end ============================>

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        {props.leftToolbarHide != true ? (
          <>
            {props.type != "vehicle-management" &&
              props.type != "driver-management" &&
              props.type != "vehicle-usage-report" &&
              props.type != "debit" &&
              props.type != "admin-order-geocode" &&
              props.type != "dialog-table-customer-warehouse" &&
              props.type != "admin-branch" &&
              (props.isDashboard ? (
                <>
                  <ButtonMui
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => openNew("")}
                  >
                    {type === "order-management"
                      ? t("add-new-order")
                      : t("new")}
                  </ButtonMui>
                  {/* Dispatcher follow button control (hide-show) */}
                  {user.is_follow == true && (
                    <ButtonMui
                      size="large"
                      variant="contained"
                      color="secondary"
                      sx={{ ml: 2 }}
                      onClick={() =>
                        history.push("/bringo-customer/dispatcherlive")
                      }
                    >
                      Takip
                    </ButtonMui>
                  )}
                </>
              ) : (
                <Button
                  label={
                    type === "order-management" ? t("add-new-order") : t("new")
                  }
                  icon="pi pi-plus"
                  className="p-button-success p-mr-2"
                  onClick={() => openNew("")}
                />
              ))}

            {props.type == "dialog-table-customer-warehouse" && (
              <Button
                label={"Depo Ekle"}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => addPopupVisible()}
              />
            )}

            {props.type == "admin-branch" && (
              <Button
                label={"Şube Ekle"}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => addBranchVisible()}
              />
            )}

            {props.type == "vehicle-management" && (
              <Button
                label={"Araç Ekle"}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => vehicleManagementDialogVisible()}
              />
            )}

            {props.type == "admin-order-geocode" && (
              <b>Kayıt Sayısı : {props.products && props.products.length}</b>
            )}

            {props.type == "driver-management" && (
              <Button
                label={"Kullanıcı Ekle"}
                icon="pi pi-plus"
                className="p-button-success p-mr-2"
                onClick={() => driversManagementDialogVisible()}
              />
            )}
            {/* 
            {props.type == "debit" && (<Button
              label={"Müşteri Toplama Zimmet Kullanıcıs Ekle "}
              icon="pi pi-plus"
              className="p-button-success p-mr-2"
              onClick={() => debitDialogVisible()}
            />)} */}

            {props.type == "debit" && (
              <Dropdown
                style={{
                  height: "33px",
                  lineHeight: "14px",
                  marginRight: "10px",
                  width: "180px",
                }}
                value={product.customer_id}
                options={customer.data}
                optionValue="id"
                optionLabel="name"
                onChange={(e) => {
                  onInputChange(e, "customer_id");
                }}
                placeholder="Müşteri"
              />
            )}
          </>
        ) : (
          ""
        )}

        {/* dashboard screen hide */}
        {!props.isDashboard && props.unplannedOrderButtonShow === true ? (
          <>
            <Button
              label={t("unplanned-order")}
              icon=""
              className="p-button-danger p-mr-2"
              onClick={() => openNew("unplanned-order")}
            />
            <Button
              label={"Planlamaya Gönderilen Siparişler"}
              icon=""
              className="p-button-success p-mr-2"
              //onClick={() => submittedPlansClicked()}
              onClick={() => openNew("unplanned-order-send-to-plan")}
            />
          </>
        ) : (
          ""
        )}

        {/* <Button
          label="Delete"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedProducts || !selectedProducts.length}
        /> */}
      </React.Fragment>
    );
  };

  //debit, selected object
  //combobox single selection
  const dbtUserSelectionComboBox = (cboxEvent, rowData) => {
    let dbtSelection = [
      {
        customer_oid: product.customer_id,
        city: rowData.city,
        county: rowData.county,
        user_oid: cboxEvent.value,
      },
    ];
    dispatch(postCustomerCountyUser(dbtSelection, toastCallback));
  };

  const dbtUserSelection = (rowData) => {
    return (
      <Dropdown
        style={{
          height: "33px",
          lineHeight: "14px",
          marginRight: "10px",
          width: "180px",
        }}
        value={rowData.assigned_user}
        options={driversList.data.data}
        optionValue="id"
        optionLabel="full_name"
        onChange={(e) => {
          dbtUserSelectionComboBox(e, rowData);
        }}
        placeholder="Müşteri"
      />
    );
  };

  //live tracking page
  //on first load, clear the filter
  // useEffect(() => {
  //   dispatch({
  //     type: TYPES.LIVE_CUSTOM_FILTER_EMPTY,
  //     payload: {},
  //   });
  // }, []);

  // search all
  useEffect(() => {
    if (liveCustomFilter && liveCustomFilter.length > 3) {
      dispatch(liveFilter(liveCustomFilter));
    } else if (liveCustomFilter == "" && liveCustomFilter.length == 0) {
      dispatch({
        type: TYPES.LIVE_CUSTOM_FILTER_EMPTY,
        payload: {},
      });
    }
  }, [liveCustomFilter]);

  const leftToolbarTemplateLiveTracking = () => {
    return (
      <React.Fragment>
        <>
          <InputText
            type="search"
            onInput={(e) => setLiveCustomFilter(e.target.value)}
            placeholder="Arama..."
          />
        </>
      </React.Fragment>
    );
  };

  const onDeliveryStatusChange = (e) => {
    setDeliveryStatusDropdowm(e.value);
  };

  const liveTableFilterButton = () => {
    dispatch(getDeliveryReportFilter(deliveryDate, deliveryStatusDropdowm));
  };

  //live tracking
  //when date range is selected, list all data
  useEffect(() => {
    if (deliveryDate === null || deliveryDate[1] === null) return;
    if (type == "LiveTracking") {
      dispatch(getDeliveryReportFilter(deliveryDate, ""));
    }
  }, [deliveryDate]);

  useEffect(() => {
    if (adminOrderFilterDate === null || adminOrderFilterDate[1] === null)
      return;
    dispatch(getAdminOrderDate(adminOrderFilterDate));
  }, [adminOrderFilterDate]);

  const onDowloadChange = (e) => {
    //e.value
    //alert(e.value)
    if (e.value == "excel") {
      exportCSV();
    } else {
      exportPdf();
    }
  };

  const downloadOptions = [
    { label: "excel", value: "excel" },
    { label: "pdf", value: "pdf" },
  ];

  const confirmBackupRejectedOptions = [
    { label: "Tümü", value: "All" },
    { label: "Onaylanan", value: "APPROVED" },
    { label: "Yedekler", value: "RESERVED" },
    { label: "Reddedilen", value: "REJECTED" },
    { label: "Bekleyenler", value: ["WAIT_FOR_EVALUATE", "NOT_EVALUATED"] },
  ];

  const rightToolbarTemplate = () => {
    const deliveryDropdowmData = [
      { label: "Tümü", value: "" },
      {
        label: "Teslim edilen",
        value: "DELIVERED,PARTIALLY_DELIVERED,NOT_DELIVERED,DELIVERY_POSTPONED",
      },
      { label: "Depoda", value: "IN_WAREHOUSE,DELIVERY_PLANNED,ON_BOARD" },
      {
        label: "Dağıtımda",
        value:
          "ON_THE_WAY_TO_CUSTOMER,CUSTOMER_INFORMED_1,CUSTOMER_INFORMED_2,CUSTOMER_READINESS_RECEIVED,CUSTOMER_INFORMED_3",
      },
    ];

    return (
      <React.Fragment>
        {/* <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          label="Import"
          chooseLabel="Import"
          className="p-mr-2 p-d-inline-block"
        /> */}

        {type === "LiveTracking" ? (
          <>
            <Calendar
              id="range"
              placeholder="Tarih Aralığı"
              style={{ marginRight: "25px", width: "185px" }}
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.value)}
              selectionMode="range"
              dateFormat="dd.mm.yy"
              readOnlyInput
            />
            {/* <Dropdown style={{ height: "30px", marginRight: "10px", width: "250px" }} options={citySelectItems} placeholder="Select a City" /> */}
            <Dropdown
              style={{
                height: "33px",
                lineHeight: "14px",
                marginRight: "10px",
                width: "195px",
              }}
              value={deliveryStatusDropdowm}
              options={deliveryDropdowmData}
              onChange={onDeliveryStatusChange}
              placeholder="Teslim Durumu Seçiniz"
            />
            <Button
              style={{ marginRight: "10px" }}
              color="teal"
              onClick={liveTableFilterButton}
            >
              Filtrele
            </Button>
            {/* <Button
                label={t('export')}
                icon="pi pi-upload"
                className="p-button-help"
                onClick={exportCSV}
              /> */}
          </>
        ) : (
          // <Button
          //   label={t('export')}
          //   icon="pi pi-upload"
          //   className="p-button-help"
          //   onClick={exportCSV}
          // />
          ""
        )}

        {/* admin - order management toolbar */}
        {props.type === "admin-order-management" && (
          <>
            <Button
              icon="pi pi-print"
              style={{
                "background-color": "transparent",
                color: "black",
                marginRight: "5px",
              }}
              className=""
              onClick={() => barcodePrintedBulk()}
            />
            <Calendar
              id="range"
              placeholder="Tarih Aralığı"
              style={{ marginRight: "25px", width: "185px" }}
              value={adminOrderFilterDate}
              onChange={(e) => setAdminOrderFilterDate(e.value)}
              selectionMode="range"
              dateFormat="dd.mm.yy"
              readOnlyInput
            />
          </>
        )}

        {props.type === "order-management" && (
          <>
            <Button
              icon="pi pi-print"
              style={{
                "background-color": "transparent",
                color: "black",
                marginRight: "5px",
              }}
              className=""
              onClick={() => barcodePrintedBulk()}
            />
            <Button
              label={t("Geocoding")}
              icon=""
              className="p-button-warning"
              onClick={() => openNew("unplanned-geocoding")}
            />
          </>
        )}

        <hr />

        {type === "order-management" ? (
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Arama..."
          />
        ) : (
          <>
            {type != "debit" &&
              type != "dialog-table-customer-warehouse" &&
              type != "admin-branch" &&
              type != "LiveTracking" &&
              type != "admin-order-management" && (
                <Dropdown
                  style={{
                    height: "33px",
                    lineHeight: "14px",
                    marginRight: "10px",
                    width: "80px",
                  }}
                  options={downloadOptions}
                  onChange={onDowloadChange}
                  placeholder="İndir"
                />
              )}
            {/* New excel button */}
            {type == "LiveTracking" && (
              <DownloadExcel
                name={"Canlı Takip"}
                //tableHeadData={tableHead[0].head}
                tableHeadData={tableHead("LiveTracking")[0].head}
                tableData={tableManipulation(products)}
              />
            )}
            {type == "admin-order-management" && (
              <DownloadExcel
                name={"Sipariş Yönetimi"}
                tableHeadData={tableHead("admin-order-management")[0].head}
                tableData={tableManipulationAdminOrderManagement(products)}
              />
            )}

            {/* <Dropdown button selectOnBlur item text={t('export')}>
              <Dropdown.Menu>
                <Dropdown.Item icon onClick={exportCSV} >
                  <i class="file excel icon"></i>
                  {t('excel')}

                </Dropdown.Item>
                <Dropdown.Item onClick={exportPdf}>
                  <i class="file pdf icon"></i>
                  {t('pdf')}

                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </>
        )}
        {/* <Dropdown button selectOnBlur item text={t('export')}>
          <Dropdown.Menu>
              <Dropdown.Item icon onClick={exportCSV} >
                <i class="file excel icon"></i>
                {t('excel')}
       
              </Dropdown.Item>
              <Dropdown.Item onClick={exportPdf}>
                <i class="file pdf icon"></i>
                {t('pdf')}
           
              </Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown> */}

        {type === "ProjectDetail" ? (
          <Dropdown
            style={{
              height: "33px",
              lineHeight: "14px",
              marginRight: "10px",
              width: "180px",
            }}
            value={aa}
            options={confirmBackupRejectedOptions}
            onChange={(e) => {
              dispatch({ type: TYPES.FILTER_EVALUATION, payload: e.value });
              setAA(e.value);
            }}
            placeholder="Seçiniz"
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`showcase/demo/images/product/${rowData.image}`}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };

  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };

  const activePassiveDialog = (row) => {
    setDialogActiveStatus(true);
    setDialogActivePassiveID(row);
  };

  const vehicleManagementBodyActive = (row) => {
    return (
      <Button
        //label={row.status && row.status.toLowerCase()}
        label={
          row.status == "ACTIVE" || row.status == "PENDING" ? "Aktif" : "Pasif"
        }
        className={
          row.status == "ACTIVE" || row.status == "PENDING"
            ? "p-button-success"
            : "p-button-secondary"
        }
        onClick={() => activePassiveDialog(row)}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <span
        className={`product-badge status-${
          rowData.inventoryStatus && rowData.inventoryStatus.toLowerCase()
        }`}
      >
        {rowData.inventoryStatus}
      </span>
    );
  };

  const projectUnpublish = (rowData, evaluation_status) => {
    dispatch(
      publishUpdate(rowData, evaluation_status, (res) => {
        toastCallback("success");
      })
    );
  };

  const projectActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.evaluation_status === "EVALUATED_PUBLISHED" ? (
          <Button
            label={t("cancel-advertisement")}
            className="p-button-success"
            onClick={() => projectUnpublish(rowData, "NOT_EVALUATED")}
          />
        ) : (
          <Button
            label={t("advertise")}
            className="p-button-secondary"
            onClick={() => projectUnpublish(rowData, "EVALUATED_PUBLISHED")}
          />
        )}
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {props.type == "vehicle-management" ? (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => vehicleManagementDialogEdit(rowData)}
          />
        ) : props.type == "driver-management" ? (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-success p-mr-2"
            onClick={() => driversManagementDialogEdit(rowData)}
          />
        ) : (
          <>
            {props.type == "Customer" && (
              <>
                <Button
                  icon="pi pi-envelope"
                  className="p-button-rounded p-button-danger p-mr-2"
                  tooltip="Mesaj Yönetimi"
                  //onClick={}
                  //onClick={()=>{window.location.href=`messages-management?customerID=${rowData.id}`}}
                  onClick={() => {
                    history.push(
                      `messages-management?customerID=${rowData.id}`
                    );
                  }} // redirect to message management page
                />
                <Button
                  icon="pi pi-user-plus"
                  className="p-button-rounded p-button-secondary p-mr-2"
                  tooltip="Kullanıcı Ekle"
                  onClick={() => customerUserAddDialogVisible(rowData)}
                />
              </>
            )}
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-success p-mr-2"
              onClick={() => editProduct(rowData)}
            />
            {props.type !== "ProjectDetail" && (
              <Button
                icon="pi pi-home"
                className="p-button-rounded p-button-primary p-mr-2"
                onClick={() => customerWarehouseDialogVisible(rowData)}
              />
            )}
          </>
        )}
        {props.type !== "ProjectDetail" && (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteProduct(rowData)}
          />
        )}
      </React.Fragment>
    );
  };

  const customerWarehouseActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => addPopupVisible(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning  p-mr-2"
          //onClick={() => addPopupDelete(rowData)}
          onClick={() => confirmDeleteProduct(rowData)}
        />
        {/* <Button
          icon="pi pi-sitemap"
          className="p-button-rounded p-button-secondary"
          onClick={() => branchDialogVisible(rowData)}
        /> */}
      </React.Fragment>
    );
  };

  const branchActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => addBranchVisible(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning  p-mr-2"
          //onClick={() => branchDelete(rowData)}
          onClick={() => confirmDeleteProduct(rowData)}
        />
        <Button
          icon="pi pi-sitemap"
          className="p-button-rounded p-button-secondary p-mr-2"
          onClick={() => branchDialogVisible(rowData)}
        />
        <Button
          icon="pi pi-map"
          className="p-button-rounded p-button-secondary"
          onClick={() => branchDrawingDialogVisible(rowData)}
        />
      </React.Fragment>
    );
  };

  const willBeReturnClick = (rowData) => {
    confirmDeleteProduct(rowData);
  };

  const liveTrackingManuallyUpdateOrderSave = () => {
    //console.log("güncelle :", product);

    let orderUpdateObj = {
      order_id: product.order_oid,
      address: product.address,
      city: product.city,
      county: product.county,
      gsm_number: product.gsm_number,
    };

    dispatch(
      manuallyUpdateOrder(orderUpdateObj, setProductDialog, toastCallback)
    );
  };

  const handleSearch = (event) => {
    switch (type) {
      case "Project":
        setDates2(event.value);
        break;
      case "vehicle-usage-report":
        setDates2(event.value);
        break;
      case "driver-management":
        setBoderline(event.value);
        break;
      case "vehicle-management":
        setBoderline(event.value);
      default:
        break;
    }
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">{/*t(`manage-${type}`)*/}</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        {type === "Project" ||
        type === "driver-management" ||
        type === "vehicle-management" ||
        type === "vehicle-usage-report" ? (
          <Calendar
            id="range"
            placeholder="Tarih seçiniz"
            style={{ marginRight: "25px" }}
            value={
              type === "Project" || type === "vehicle-usage-report"
                ? dates2
                : boderline
            }
            onChange={(event) => handleSearch(event)}
            selectionMode={
              ((type === "Project" || type === "vehicle-usage-report") &&
                "range") ||
              "single"
            }
            readOnlyInput
          />
        ) : (
          ""
        )}

        {type === "order-management" ? (
          // <Dropdown button selectOnBlur item text={t('export')}>
          //   <Dropdown.Menu>
          //     <Dropdown.Item icon onClick={exportCSV} >
          //       <i class="file excel icon"></i>
          //       {t('excel')}

          //     </Dropdown.Item>
          //     <Dropdown.Item onClick={exportPdf}>
          //       <i class="file pdf icon"></i>
          //       {t('pdf')}

          //     </Dropdown.Item>
          //   </Dropdown.Menu>
          // </Dropdown>
          <Dropdown
            style={{
              height: "33px",
              lineHeight: "14px",
              marginRight: "10px",
              width: "80px",
            }}
            options={downloadOptions}
            onChange={onDowloadChange}
            placeholder="İndir"
          />
        ) : (
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Arama..."
          />
        )}
      </span>
    </div>
  );
  const productDialogFooter = (saveType) => {
    return (
      <React.Fragment>
        <Button
          label="İptal"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label={
            saveType === "order-management-unplanned-order" ||
            saveType === "order-management-unplanned-order-send-to-plan"
              ? "Listele"
              : "Kaydet"
          }
          icon="pi pi-check"
          className="p-button-text"
          onClick={(e) => saveProduct(saveType, e)}
        />
      </React.Fragment>
    );
  };
  const projectApplicationDetailReview = useSelector(
    (state) => state.projectApplicationDetailReducer
  );

  const projectDetailDialogFooter = (RowData) => {
    return (
      <React.Fragment>
        <Button
          label="Onayla"
          className="p-mr-2"
          onClick={() =>
            applicationEvaluation(
              projectApplicationDetailReview.review,
              "Approve"
            )
          }
        />
        <Button
          label="Yedek"
          className="p-button-warning p-mr-2"
          onClick={() =>
            applicationEvaluation(
              projectApplicationDetailReview.review,
              "Reserve"
            )
          }
        />
        <Button
          label="Red"
          className="p-button-danger"
          onClick={() =>
            applicationEvaluation(
              projectApplicationDetailReview.review,
              "Reject"
            )
          }
        />
      </React.Fragment>
    );
  };
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="Hayır"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Evet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const liveDialogFooter = (
    <React.Fragment>
      <Button
        label="İptal"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          setProduct(emptyProduct);
          setProductDialog(false);
        }}
      />
      <Button
        label="Kaydet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => liveTrackingManuallyUpdateOrderSave()}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="Hayır"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Evet"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const geocoderDialogFooter = (
    <React.Fragment>
      <Button
        label="Kaydet"
        className="p-button-warning"
        onClick={geocoderSaveFooter}
      />
    </React.Fragment>
  );

  const sendToPlan = () => {
    if (!selectedProducts) {
      toastCallback("warn", "Sipariş Seçmediniz!");
      return;
    }
    let RowID = selectedProducts.map((e) => e.id);
    dispatch(sendToPlanAction(RowID, toastCallback));

    setTimeout(() => {
      setSelectedProducts(null);
    }, 2000);
  };

  const revertToPlan = () => {
    if (!selectedProducts) {
      toastCallback("warn", "Sipariş Seçmediniz!");
      return;
    }
    let RowID = selectedProducts.map((e) => e.id);
    dispatch(revertToPlanAction(RowID, toastCallback));

    setTimeout(() => {
      setSelectedProducts(null);
    }, 2000);
  };

  const deleteMultiple = () => {
    if (!selectedProducts) {
      toastCallback("warn", "Silmek için seçilmiş kayıt yok!");
      return;
    }
    setDeleteProductDialog(true);
    //console.log(orderCode)
  };

  const tableFooter = (
    <React.Fragment>
      <Button
        icon="pi pi-plus"
        style={{ "background-color": "transparent", color: "black" }}
        className=""
        onClick={() => openNew("manual-order")}
      />

      <Button
        icon="pi pi-trash"
        style={{
          "background-color": "transparent",
          color: "black",
          marginLeft: "5px",
        }}
        className=""
        onClick={() => deleteMultiple()}
      />

      {/* <Button
        icon="pi pi-print"
        style={{ "background-color": "transparent", color: "black", marginLeft: "5px" }}
        className=""
        onClick={() => barcodePrintedBulk()}
      /> */}

      {/* <a className="pi pi-print"
      onClick={() =>{
         var idx= new Date().getTime()
         barcodePrintedBulk(idx)
        }} 
      style={{ cursor: "pointer", padding: "8px", color: "black", border: "1px solid #2196F3", marginLeft: "5px", borderRadius: "3px"}} 
      //href={`asdsad${idx}`} 
      target="_blank"
      ></a> */}

      {getOrderList.data &&
      getOrderList.data.length > 1 &&
      getOrderList.data[0].sent_to_plan === true ? (
        <Button
          label="Planlamadan Çıkar"
          style={{ position: "relative", marginLeft: "1150px", height: 40 }}
          className="p-button-danger p-button-raised p-button-rounded"
          onClick={() => revertToPlan()}
        />
      ) : (
        <Button
          label="Planlamaya Gönder"
          style={{ position: "relative", marginLeft: "1150px", height: 40 }}
          className="p-button-secondary p-button-raised p-button-rounded"
          onClick={() => sendToPlan()}
        />
      )}
    </React.Fragment>
  );

  const tableFooterAdminOrderManagement =
    type !== "admin-order-management" ? (
      <React.Fragment>
        <Button
          icon="pi pi-trash"
          style={{
            "background-color": "transparent",
            color: "black",
            marginLeft: "5px",
          }}
          className=""
          onClick={() => deleteMultiple()}
        />
        <Button
          label="Planlamaya Gönder"
          style={{ position: "relative", "margin-left": "1150px", height: 40 }}
          className="p-button-secondary p-button-raised p-button-rounded"
          onClick={() => sendToPlan()}
        />
      </React.Fragment>
    ) : null;

  const tableFooterLiveTracking = (
    <React.Fragment>
      <Button
        label="Ziyaret Ertele"
        style={{ position: "relative", height: 40 }}
        className="p-button-danger p-button-raised p-button-rounded"
        onClick={() => postponeVisitDialogVisible()}
      />
    </React.Fragment>
  );

  const tableFooterDebit = (
    <React.Fragment>
      <Button
        label="Toplu Atama"
        style={{ position: "relative", height: 40 }}
        className="p-button-danger p-button-raised p-button-rounded"
        onClick={() => debitMultipleSelectiontDialogVisible()}
      />
    </React.Fragment>
  );

  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />

      <div className="card">
        {type === "LiveTracking" ? (
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplateLiveTracking}
            right={rightToolbarTemplate}
          ></Toolbar>
        ) : props.isDashboard ? (
          leftToolbarTemplate()
        ) : (
          // dashboard screen hide
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
        )}
        {!props.isDashboard && (
          <TableBody
            type={props.type}
            dt={dt}
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            globalFilter={globalFilter}
            header={type != "debit" ? header : ""}
            imageBodyTemplate={imageBodyTemplate}
            priceBodyTemplate={priceBodyTemplate}
            ratingBodyTemplate={ratingBodyTemplate}
            vehicleManagementBodyActive={vehicleManagementBodyActive}
            statusBodyTemplate={statusBodyTemplate}
            actionBodyTemplate={actionBodyTemplate}
            projectActionBodyTemplate={projectActionBodyTemplate}
            dateConvert={dateConvert}
            workingPeriodDates={workingPeriodDates}
            detailProject={detailProject}
            projectDetailTemplate={projectDetailTemplate}
            projectDetailPopupTemplate={projectDetailPopupTemplate}
            birthdayConvert={birthdayConvert}
            arrayDriverLength={arrayDriverLength}
            arrayVehiclesLength={arrayVehiclesLength}
            created_atConvert={created_atConvert}
            deliveryStatus={deliveryStatus}
            orderCodePopup={orderCodePopup}
            deliveryStatusText={deliveryStatusText}
            arrivalTime={arrivalTime}
            tableFooter={tableFooter}
            deleteUnplannedAction={deleteUnplannedAction}
            liveTrackingDateFormat={liveTrackingDateFormat}
            geocodeEditAction={geocodeEditAction}
            editOrder={orderDialog}
            coordinateReplace={coordinateReplace}
            coordinateReplaceOrderManagement={coordinateReplaceOrderManagement}
            customerFeedbackDateFormat={customerFeedbackDateFormat}
            barcodePrintedAction={barcodePrintedAction}
            convertDateToAnotherTimezone={convertDateToAnotherTimezone}
            tableFooterLiveTracking={tableFooterLiveTracking}
            dbtUserSelection={dbtUserSelection}
            tableFooterDebit={tableFooterDebit}
            customerWarehouseActionBodyTemplate={
              customerWarehouseActionBodyTemplate
            }
            branchActionBodyTemplate={branchActionBodyTemplate}
            tableFooterAdminOrderManagement={tableFooterAdminOrderManagement}
            adminOrderManagementAllDeliveryStatusText={
              adminOrderManagementAllDeliveryStatusText
            }
            differentPage={differentPage}
          />
        )}
      </div>
      <DialogModal
        productDialog={productDialog}
        productDialogFooter={() => productDialogFooter(modalType)}
        hideDialog={hideDialog}
        product={product}
        onInputChange={onInputChange}
        submitted={submitted}
        onCategoryChange={onCategoryChange}
        onInputNumberChange={onInputNumberChange}
        deleteProductDialog={deleteProductDialog}
        deleteProductDialogFooter={deleteProductDialogFooter}
        hideDeleteProductDialog={hideDeleteProductDialog}
        deleteProductsDialog={deleteProductsDialog}
        deleteProductsDialogFooter={deleteProductsDialogFooter}
        hideDeleteProductsDialog={hideDeleteProductsDialog}
        modalType={modalType}
        projectDetailDialogFooter={projectDetailDialogFooter}
        uploadExcelHandler={uploadExcelHandler}
        onFileLoad={onFileLoad}
        arrivalTime={arrivalTime}
        toastCallback={toastCallback}
        convertDateToAnotherTimezone={convertDateToAnotherTimezone}
        differentPage={differentPage}
        liveDialogFooter={liveDialogFooter}
        willBeReturnClick={willBeReturnClick}
        setProductDialog={setProductDialog}
        orderAddressUpdateGeocodeEditVisible={
          orderAddressUpdateGeocodeEditVisible
        }
      />

      <GeocodeDialog
        type={type}
        dialogVs={dialogVs}
        setdialogVs={setdialogVs}
        geocoderDialogFooter={geocoderDialogFooter}
        hideGeocodeDialog={hideGeocodeDialog}
        geocoderSaveFooter={geocoderSaveFooter}
      />
      <GeocodeEditDialog
        toastCallback={toastCallback}
        pp={dialogEdit}
        setDialogEdit={setDialogEdit}
        selectedDialogEdit={selectedDialogEdit}
        selectedDialogEditTarget={selectedDialogEditTarget}
        comp={"orderManagement"}
        orderManagementAddressUpdate={orderManagementAddressUpdate}
        product={product}
        //onInputChangeProduct={onInputChange}
      />

      {/* warehouse add dialog popup */}
      {location.pathname === "/branch" && (
        <AddPopup
          toastCallback={toastCallback}
          dialogAddPopup={dialogAddPopup}
          setDialogAddPopup={setDialogAddPopup}
          product={product}
          //setProduct={setProduct}
          onInputChange={onInputChange}
          addPopupWhichComponent={addPopupWhichComponent}
        />
      )}

      {location.pathname === "/vehicle-management" && (
        <VehicleManagement
          product={product}
          onInputChange={onInputChange}
          dialogVehicleManagement={dialogVehicleManagement}
          setDialogVehicleManagement={setDialogVehicleManagement}
          productDialogFooter={productDialogFooter}
          zoneAddProduct={zoneAddProduct}
          toastCallback={toastCallback}
          onDocUploaded={(doc_name) => handleUploadFile(doc_name)}
          setProduct={setProduct}
          onInputChangeCalendar={onInputChangeCalendar}
        />
      )}

      <DialogActive
        dialogActiveStatus={dialogActiveStatus}
        setDialogActiveStatus={setDialogActiveStatus}
        dialogActivePassiveID={dialogActivePassiveID}
        activePassive={(act_pass) => vehicleManagementBodyActive(act_pass)}
      />

      {location.pathname === "/driver-management" && (
        <DriverManagement
          type={type}
          product={product}
          dialogDriversManagement={dialogDriversManagement}
          setDialogDriversManagement={setDialogDriversManagement}
          toastCallback={toastCallback}
          onDocUploaded={(doc_name) => handleUploadFile(doc_name)}
          onInputChange={onInputChange}
          productDialogFooter={productDialogFooter}
          setProduct={setProduct}
          onInputChangeCalendar={onInputChangeCalendar}
        />
      )}

      <CustomerUserAdd
        dialogCustomerUser={dialogCustomerUserAdd}
        setDialogCustomerUser={setDialogCustomerUserAdd}
        product={product}
        toastCallback={toastCallback}
      />

      <PostponeVisit
        dialogPostponeVisit={dialogPostponeVisit}
        setDialogPostponeVisit={setDialogPostponeVisit}
        toastCallback={toastCallback}
        selectedProducts={selectedProducts}
      />

      {/* <Debit
        dialogDebit={dialogDebit}
        setDialogDebit={setDialogDebit}
        toastCallback={toastCallback}
        zoneAddProduct={zoneAddProduct}
        onInputChange={onInputChange}
        product={product}
      /> */}

      {/*Zimmet dialog popup  */}
      <DebitMultipleSelection
        dialogDebitMultipleSelection={dialogDebitMultipleSelection}
        setDialogDebitMultipleSelection={setDialogDebitMultipleSelection}
        selectedProducts={selectedProducts}
        onInputChange={onInputChange}
        product={product}
        toastCallback={toastCallback}
      />

      {/*Customer warehouse dialog popup */}
      <CustomerWarehouse
        dialogCustomerWarehouse={dialogCustomerWarehouse}
        setDialogCustomerWarehouse={setDialogCustomerWarehouse}
      />

      {/*Branch assignment dialog popup */}
      <BranchAssignment
        dialogBranchAssignment={dialogBranchAssignment}
        setDialogBranchAssignment={setDialogBranchAssignment}
        product={product}
        setProduct={setProduct}
      />

      {/*add branch user dialog popup */}
      <addBranchUser />

      {/*Branch drawing dialog popup */}
      <BranchDrawing
        dialogBranchDrawing={dialogBranchDrawing}
        setDialogBranchDrawing={setDialogBranchDrawing}
        product={product}
      />
    </div>
  );
};

index.whyDidYouRender = false;

export default index;
/* eslint-disable */
