import React from "react";
import MUIDataTable from "mui-datatables";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Services } from "api/Services";
import { useHistory } from "react-router";
import qs from "query-string";
import moment from "moment";
import { CalendarMonth, Close } from "@mui/icons-material";
import Map from "./Map";
import tableTextLabels from "utils/tableTextLabels";
import randomColors from "utils/randomColors";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Link } from "react-router-dom";
import tr from "date-fns/locale/tr";
import { TextFirstLetter } from "utils";

const server = new Services();

const columns = [
  {
    name: "Kurye Bilgisi",
    options: { filterType: "textField" },
  },
  { name: "Sipariş S.", options: { filter: false } },
  { name: "Paket S.", options: { filter: false } },
  { name: "Planlanan Tarih", options: { filter: false } },
  { name: "Rota Saati", options: { filter: false } },
  { name: "Pln. KM", options: { filter: false } },
  { name: "Grçk. KM", options: { filter: false } },
  { name: "", options: { filter: false, display: false, viewColumns: false } },
];

const renderRoute = (routes) => {
  const colors = randomColors(routes.length);
  return routes.map((item, i) => {
    const routeColor = colors[i];
    return [
      `${TextFirstLetter(
        item.driver.first_name + " " + item.driver.last_name
      )}\n${item.driver.username}`,
      item.orders.length,
      item.package_count,
      moment(item.date).format("DD.MM.YYYY HH:mm"),
      moment(item.start_time).format("DD.MM.YYYY HH:mm"),
      (item.distance / 1000).toFixed(2) || 0,
      (item.completed_distance / 1000).toFixed(2) || 0,
      {
        ...item,
        routeColor,
        orders: item.orders.map((o) => ({ ...o, routeColor })),
      },
    ];
  });
};

const DataGrid = styled(MUIDataTable)(({ theme }) => ({
  "& thead th": {
    backgroundColor: "#F4F6F8!important",
    "& > span": {
      whiteSpace: "nowrap",
      "& > button": {
        fontSize: 12,
      },
    },
  },
  "& tbody tr > td": {
    fontSize: 12,
  },
}));

export default function App() {
  const history = useHistory();
  const queryParam = qs.parse(history.location.search);
  const [date, setDate] = React.useState(moment().format("yyyy-MM-DD"));
  const [loading, setLoading] = React.useState(true);
  const [routes, setRoutes] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [polylineArrLoading, setPolylineArrLoading] = React.useState(false);
  const [polylineArr, setPolylineArr] = React.useState([]);

  const getRoutes = async () => {
    if (!loading) {
      setLoading(true);
    }

    try {
      if (queryParam.routeId) {
        const res = await server.liveTrackingGetOrders({
          routeId: queryParam.routeId,
        });
        setRoutes(renderRoute(res));
      } else {
        const res = await server.liveTrackingGetDetails({
          date: moment(date).format("yyyy-MM-DD"),
        });
        setRoutes(renderRoute(res));
      }
      setSelectedRows([]);
      setPolylineArr([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getRoutes();
  }, [queryParam.routeId, date]);

  const orders = routes
    .map((route, index) => {
      if (selectedRows.map((i) => i.dataIndex).includes(index)) {
        return route[route.length - 1].orders;
      }
    })
    .flatMap((i) => i)
    .filter(Boolean);

  async function onSelectedRows(allRowsSelected) {
    setSelectedRows(allRowsSelected);
    setPolylineArrLoading(true);

    const newArr = [];

    for (const route of allRowsSelected) {
      const getRoute =
        routes[route.dataIndex][routes[route.dataIndex].length - 1];

      try {
        const res = await server.getPlanlananRotalar({
          routeId: getRoute.route_id,
        });

        const res2 = await server.getGerceklesenRotalar({
          routeId: getRoute.route_id,
        });
        newArr.push({
          route: getRoute,
          dataIndex: route.dataIndex,
          planlananRotalar: res[0]?.routeCoordinates
            ? JSON.parse(res[0]?.routeCoordinates)
            : [],
          gerceklesenRotalar: res2,
        });
      } catch (error) {}
    }

    setPolylineArr(newArr);
    setPolylineArrLoading(false);
  }

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: "sticky",
          top: 0,
          px: 2,
          zIndex: 2,
          height: 60,
          bgcolor: "#fff",
        }}
      >
        <Box flex={1}>
          <Link
            to="/"
            style={{
              width: 140,
              height: 60,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src="/hey-bringo-logo.png"
              sx={{
                display: "block",
                width: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Link>
        </Box>
        <IconButton color="inherit" onClick={history.goBack}>
          <Close />
        </IconButton>
      </Stack>
      {loading ? (
        <Stack height={1} justifyContent="center">
          <CircularProgress sx={{ display: "block", my: 10, mx: "auto" }} />
        </Stack>
      ) : (
        <Box height="calc(100vh - 60px)" p={2}>
          <Stack direction="row" height={1} spacing={2}>
            <Box width="43%" height={1}>
              {!queryParam.routeId && (
                <LocalizationProvider locale={tr} dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    mask="__.__.____"
                    toolbarPlaceholder="Tarih"
                    cancelText="Vazgeç"
                    okText="Filtrele"
                    inputFormat="dd.MM.yyyy"
                    value={date}
                    onChange={() => {}}
                    onAccept={setDate}
                    renderInput={(params) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ mb: "12px" }}
                      >
                        <Typography variant="subtitle2">
                          Tarih Seçimi
                        </Typography>
                        <TextField
                          {...params}
                          size="small"
                          InputProps={{
                            endAdornment: <CalendarMonth color="disabled" />,
                          }}
                        />
                      </Stack>
                    )}
                  />
                </LocalizationProvider>
              )}
              <DataGrid
                title={"Canlı Takip"}
                data={routes}
                columns={columns}
                options={{
                  setTableProps: () => ({ size: "small" }),
                  isRowSelectable: () => !polylineArrLoading,
                  onRowsDelete: () => {
                    onSelectedRows([]);
                  },
                  search: true,
                  download: true,
                  print: true,
                  viewColumns: true,
                  filterType: "dropdown",
                  tableBodyHeight: `calc(100vh - ${
                    queryParam.routeId ? "212" : "264"
                  }px)`,
                  responsive: "vertical",
                  rowsPerPage: 25,
                  rowsPerPageOptions: [25, 50, 100],
                  selectableRowsHeader: !polylineArrLoading,
                  selectableRows: "multiple",
                  textLabels: polylineArrLoading
                    ? {
                        ...tableTextLabels,
                        selectedRows: {
                          ...tableTextLabels.selectedRows,
                          text:
                            tableTextLabels.selectedRows.text +
                            `. ${
                              polylineArrLoading ? "Rotalar Çekiliyor" : ""
                            }`,
                        },
                      }
                    : tableTextLabels,
                  onRowSelectionChange: (
                    currentRowsSelected,
                    allRowsSelected,
                    rowsSelected
                  ) => {
                    onSelectedRows(allRowsSelected);
                  },
                }}
              />
            </Box>
            <Map
              polylineArr={polylineArr}
              orders={orders.map((i) => ({ ...i, isClick: false }))}
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
}
