import React from "react";
import PropTypes from "prop-types";
import Item from "./item";
import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Iconify from "components/Iconify";
import TableNoData from "components/CustomTable/TableNoData";

CityList.propTypes = {
  data: PropTypes.array,
};

export default function CityList({ data }) {
  const [tableData, setTableData] = React.useState(data);
  const [sortType, setSortType] = React.useState("desc");
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    setTableData(
      data.map((d) => ({
        city: d.city,
        count: d.count,
        density: d.density,
      }))
    );
  }, [data]);

  // Table Head Data
  const tableHeadData = [
    { key: "city", title: "İl" },
    { key: "count", title: "Sipariş Sayısı" },
    { key: "density", title: "Yoğunluk" },
  ];

  const sortedData = (key) => {
    const sortedData = [...tableData];
    if (sortType === "desc") {
      sortedData.sort((a, b) => {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
      setSortType("asc");
    } else {
      sortedData.sort((a, b) => {
        if (a[key] > b[key]) {
          return -1;
        }

        if (a[key] < b[key]) {
          return 1;
        }
        return 0;
      });
      setSortType("desc");
    }
    setTableData(sortedData);
  };

  const dataToShow = showAll ? tableData : tableData.slice(0, 5);

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeadData.map((t) => (
                <TableCell
                  sx={{ cursor: "pointer" }}
                  onClick={() => sortedData(t.key)}
                  key={t.key}
                >
                  {t.title} <Iconify icon="bx:bx-sort" />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToShow.length > 0 ? (
              dataToShow.map((row, index) => <Item key={index} item={row} />)
            ) : (
              <TableNoData isNotFound colSpan={3} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      {data.length > 5 && (
        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="inherit"
            endIcon={
              showAll ? (
                <Iconify icon="bx:bx-chevron-down" />
              ) : (
                <Iconify icon="bx:bx-chevron-right" />
              )
            }
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Daha az göster" : "Tümünü göster"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
