import _ from "lodash";

const initialState = {
  branches: [],
  cities: [],
  counties: [],
  customers: [],
};

export const analyticsFilterRecuder = (state = initialState, action) => {
  switch (action.type) {
    case "ANALYICS_FILTER_SET":
      return {
        ...state,
        ...action.payload,
      };
    case "ANALYICS_FILTER_RESET":
      return initialState;
    default:
      return state;
  }
};
