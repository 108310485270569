import React from "react";
import PropTypes from "prop-types";
import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  LoadingButton,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/lab";
import { DeleteSweep } from "@mui/icons-material";
import MultipleSelect from "components/MultipleSelect";

Filters.propTypes = {
  data: PropTypes.object,
  filter: PropTypes.object,
  handleChangeFilter: PropTypes.func,
  handleFilterReset: PropTypes.func,
  filterLoading: PropTypes.bool,
};

export default function Filters({
  data,
  filter,
  handleChangeFilter,
  filterLoading,
  handleFilterReset,
}) {
  const {
    branches,
    vehicle_types,
    vehicles,
    drivers,
    cities,
    counties,
    customers,
  } = data;

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 2,
        columnGap: 0.5,
        gridTemplateColumns: {
          xs: "repeat(2, 1fr)",
          md: "repeat(5, 1fr)",
          lg: "repeat(10, 1fr)",
        },
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel>Projeler</InputLabel>
        <MultipleSelect
          label="Projeler"
          defaultValue={filter.customers}
          options={customers}
          handleChange={(val) => handleChangeFilter("customers", val)}
        />
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel>Şubeler</InputLabel>
        <MultipleSelect
          label="Şubeler"
          defaultValue={filter.branches}
          options={branches}
          handleChange={(val) => handleChangeFilter("branches", val)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel>Araç Tipi</InputLabel>
        <MultipleSelect
          label="Araç Tipi"
          defaultValue={filter.vehicle_types}
          options={vehicle_types}
          handleChange={(val) => handleChangeFilter("vehicle_types", val)}
        />
      </FormControl>

      <TextField
        size="small"
        fullWidth
        label="Sipariş No"
        value={filter.shipment_number}
        onChange={(val) => handleChangeFilter("shipment_number", val)}
      />

      <FormControl fullWidth size="small">
        <InputLabel>Plaka</InputLabel>
        <MultipleSelect
          label="Plaka"
          defaultValue={filter.vehicles}
          options={vehicles}
          handleChange={(val) => handleChangeFilter("vehicles", val)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel id="sofor">Şoför</InputLabel>
        <MultipleSelect
          label="Şoför"
          defaultValue={filter.drivers}
          options={drivers}
          handleChange={(val) => handleChangeFilter("drivers", val)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel>İl</InputLabel>
        <MultipleSelect
          label="İl"
          defaultValue={filter.cities}
          options={cities}
          handleChange={(val) => handleChangeFilter("cities", val)}
        />
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel>İlçe</InputLabel>
        <MultipleSelect
          label="İlçe"
          defaultValue={filter.counties}
          options={counties}
          handleChange={(val) => handleChangeFilter("counties", val)}
        />
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          mask="__.__.____"
          toolbarPlaceholder="Tarih"
          cancelText="Vazgeç"
          okText="Filtrele"
          inputFormat="dd.MM.yyyy"
          value={filter.start_date}
          onChange={() => {}}
          onAccept={(val) => {
            handleChangeFilter("start_date", val);
          }}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
        />
      </LocalizationProvider>
      <LoadingButton
        color="error"
        onClick={handleFilterReset}
        loading={filterLoading}
        startIcon={<DeleteSweep />}
      >
        Temizle
      </LoadingButton>
    </Box>
  );
}
