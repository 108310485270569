import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField } from "@mui/material";

InputField.propTypes = {
  label: PropTypes.string,
};

const ALPHA_LETTER_DASH_REGEX = /^[A-Za-zÇĞİÖŞÜçğıöşü ]+$/;
const ONLY_NUMBER_REGEX =
  /^([0-9]|Backspace|Tab|Delete|ArrowLeft|ArrowRight)+$/;

export default function InputField({ label, onlyLetter, ...props }) {
  const [field, meta] = useField(props);

  return (
    <TextField
      label={label}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      onKeyDown={(event) => {
        if (onlyLetter && !ALPHA_LETTER_DASH_REGEX.test(event.key)) {
          event.preventDefault();
        }
        if (props.type === "number" && !ONLY_NUMBER_REGEX.test(event.key)) {
          event.preventDefault();
        }
      }}
    />
  );
}
