import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import Layout from "components/Layout";
import { Services } from "api/Services";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import qs from "query-string";
import { getStatus, statusFilter } from "helper";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import { useDispatch } from "react-redux";
import Iconify from "components/Iconify";
import TableSelectedActions from "components/CustomTable/TableSelectedActions";
import { useSnackbar } from "notistack";
import { BASE_URL } from "api/Env";
import Map from "components/CustomMap";
import ListRow from "./ListRow";
import { Save } from "@mui/icons-material";

// Dialog Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const services = new Services();
// Filter Options Data
const filterOptions = ["Tümü", "Basıldı", "Basılmadı"];

const List = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const queryParam = qs.parse(props.location.search);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [filterOption, filterOptionsOnChange] = React.useState(
    filterOptions[0]
  );
  const [deleteItem, setDeleteItem] = React.useState(null);
  const [updateOrder, setUpdateOrder] = React.useState(null);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  // get querys
  const { start, end, routeKey } = queryParam;

  const getData = async () => {
    setLoading(true);
    try {
      const res = await services.getDistributionDetailListDashboard({
        start,
        end,
        routeKey,
      });
      const newData = res.entity.data.map((item) => {
        return {
          id: item.id,
          addressID: item.address.id,
          musteriNo: item.customer.code || "-",
          siparisNo: item.order_code || "-",
          aliciAdi: `${item.customer.first_name || "-"}  ${
            item.customer.last_name || ""
          }`,
          aliciSoyadi: item.customer.last_name !== null,
          aliciAdresi: item.address.address || "-",
          //ilIlce: item.address.city || "-",
          ilIlce:
            item.address.city +
              "/" +
              item.address.county?.charAt(0).toUpperCase() +
              item.address.county?.slice(1).toLowerCase() || "-",
          skor: item.address.geocode_score || "-",
          siparisDurumu: statusFilter(item),
          enlem: item.address.latitude || "-",
          boylam: item.address.longitude || "-",
          siparisinBizeGelmeSaati: item.created_at || "-",
          teslimTarihi: item.delivery_date || "-",
          cepTelefon: item.customer.gsm_number || "-",
          deci: item.deci || "-",
          paketSayisi: item.quantity || "-",
          dagitimSubesi: item?.assigned_branch?.name || "",
          barkod: item.barcode || "-",
          durum: item.barcode_printed ? "Basıldı" : "Basılmadı",
          warehouseCode: item.warehouse.code,
        };
      });

      setData(res.entity.data);
      setTableData(newData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Get pagination props
  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    order,
    orderBy,
    onSort,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
  } = useTable();

  // Table Head Data
  const tableHeadData = [
    { key: "musteriNo", title: "Müşteri No", onSort },
    { key: "siparisNo", title: "Sipariş No", onSort },
    { key: "aliciAdi", title: "Alıcı Adı", onSort },
    {
      key: "aliciAdresi",
      title: "Alıcı Adresi",
      onSort,
      sx: { minWidth: 120 },
    },
    { key: "ilIlce", title: "İl/İlçe", onSort },
    { key: "skor", title: "Skor", onSort },
    { key: "siparisDurumu", title: "Sipariş Durumu", onSort },
    { key: "enlem", title: "Enlem", onSort },
    { key: "boylam", title: "Boylam", onSort },
    {
      key: "siparisinBizeGelmeSaati",
      title: "S.B.G Saati",
      onSort,
    },
    { key: "cepTelefon", title: "Cep Telefonu", onSort },
    { key: "teslimTarihi", title: "Teslim Tarihi", onSort },
    { key: "desi", title: "Desi", onSort },
    { key: "paketSayisi", title: "Paket Sayısı", onSort },
    { key: "dagitimSubesi", title: "Dağıtım Şubesi", onSort },
    { key: "barkod", title: "Barkod", onSort },
    { key: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterOption,
    filterSearch,
    filterSearchKeys: [...tableHeadData.map((i) => i.key)],
    comparator: getComparator(order, orderBy),
    uniqBy: "id",
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => queryParam && getData(), []);

  const goDetail = (siparisKodu) => {
    props.history.push({
      pathname: `/distribution/detail/sub-detail`,
      search: qs.stringify({ siparisKodu }),
    });
  };

  const updateBarcodeKey = (id) => {
    setTableData((t) => {
      // update item
      const newArray = [...t];
      const getIndex = t.findIndex((e) => e.id === id);
      if (getIndex > -1) {
        newArray[getIndex].durum = "Basıldı";
      }
      return newArray;
    });
  };

  const handleBarcodeRows = async () => {
    const ids = tableData
      .filter((row) => selected.includes(row.id))
      .map((i) => i.id);
    //const requests = ids.map((i) => services.post_print_barcode(i));

    //bulk services
    let barcodeID = new Date().getTime();
    services.post_print_barcode_bulk(ids, barcodeID);

    try {
      //await Promise.all(requests);
      // update table
      ids.forEach((id) => {
        updateBarcodeKey(id);
      });
      setTimeout(() => {
        //window.open(`${BASE_URL}/qrcode/?id=${id}&notPrint=true`, id);
        window.open(`${BASE_URL}qrcode/?bulkId=${barcodeID}&notPrint=true`);
      }, 500);

      setSelected([]);
      enqueueSnackbar("Barkodlar başarıyla basıldı.", { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  const handleBarcode = async (id) => {
    try {
      await services.post_print_barcode(id);
      updateBarcodeKey(id);
      window.open(`${BASE_URL}qrcode/?id=${id}&notPrint=true`);
      enqueueSnackbar("Barkodlar başarıyla basıldı.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  const handleDeleteRows = async () => {
    const ids = tableData
      .filter((row) => selected.includes(row.id))
      .map((i) => ({ warehouseCode: i.warehouseCode, siparisNo: i.siparisNo }));
    const requests = ids.map((i) =>
      services.deleteOrderUnplanned(i.warehouseCode, i.siparisNo)
    );

    try {
      await Promise.all(requests);
      // update table
      const deleteRows = tableData.filter((row) => !selected.includes(row.id));
      setSelected([]);
      setTableData(deleteRows);
      setDeleteItem(null);
      enqueueSnackbar("Siparişler başarıyla silindi.", { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Bir sorun oluştu.", { variant: "error" });
    }
  };

  const handleDeleteRow = async (order) => {
    try {
      await services.deleteOrderUnplanned(order.warehouseCode, order.siparisNo);
      setTableData((t) => {
        // delete item
        return [...t].filter((i) => i.id !== order.id);
      });
      setSelected([]);
      setDeleteItem(null);
      enqueueSnackbar("Başarıyla silindi.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Silinemedi.", { variant: "error" });
    }
  };

  const coordinateEditing = (lat, lon) => {
    // Error reason : hypen charecter "-"
    //coords={[updateOrder.enlem.toString().includes("-") === true ? updateOrder.enlem.replace("-", 0) : updateOrder.enlem, updateOrder.boylam.toString().includes("-") === true ? updateOrder.boylam.replace("-", 0) : updateOrder.boylam]}
    let coords = [];
    //lat
    if (lat.toString().includes("-") === true) {
      coords.push(lat.replace("-", 0));
    } else {
      coords.push(lat);
    }
    //lon
    if (lon.toString().includes("-") === true) {
      coords.push(lon.replace("-", 0), ...coords);
    } else {
      coords.push(lon, ...coords);
    }
    return coords;
  };

  const updateOrderAddress = (newAddress) => {
    if (!newAddress) {
      return;
    }
    const { lat, lng } = newAddress.center;
    setUpdateOrder((s) => ({ ...s, enlem: lat, boylam: lng }));
  };

  const updateOrderAddressRequest = async () => {
    try {
      await services.geocodeCoordinate([
        {
          address_id: updateOrder.addressID,
          latitude: updateOrder.enlem,
          longitude: updateOrder.boylam,
        },
      ]);
      setTableData((t) => {
        const newData = [...t];
        const index = newData.findIndex((i) => i.id === updateOrder.id);
        if (index > -1) {
          newData[index].enlem = updateOrder.enlem;
          newData[index].boylam = updateOrder.boylam;
        }
        return newData;
      });
      setUpdateOrder(null);
      enqueueSnackbar("Adres başarıyla güncellendi.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Adres güncellenemedi.", { variant: "error" });
    }
  };

  const heading = getStatus(queryParam.routeKey) || "Toplam Sipariş";

  return (
    <Layout
      {...props}
      heading={heading}
      links={[{ name: "Anasayfa", href: "/" }, { name: heading }]}
      action={
        <DownloadExcel
          name={heading}
          tableHeadData={tableHeadData}
          tableData={tableData}
        />
      }
    >
      <CustomTable
        {...{
          loading,
          // Filter
          filterOption,
          filterOptions,
          filterOptionsOnChange,
          filterOptionsPlaceholder: "Barkod Durumu",
          filterSearch,
          filterSearchOnChange,
          filterSearchPlaceholder:
            "Lütfen aramak istediğiniz sipariş bilgisini giriniz",
          // Table
          // selected
          rowCount: tableData.length,
          numSelected: selected.length,
          onSelectAllRows: (checked) => {
            onSelectAllRows(
              checked,
              tableData.map((row) => row.id)
            );
          },
          //
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          containerChildren: selected.length > 0 && (
            <TableSelectedActions
              dense
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              actions={
                <Stack spacing={1} direction="row">
                  <Tooltip title="Barkod Basma">
                    <IconButton color="info" onClick={handleBarcodeRows}>
                      <Iconify icon={"bx:barcode-reader"} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Silme">
                    {/* <IconButton color="primary" onClick={handleDeleteRows}> */}
                    <IconButton
                      color="primary"
                      onClick={() => setDeleteItem(true)}
                    >
                      <Iconify icon={"eva:trash-2-outline"} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />
          ),
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <ListRow
              key={index}
              item={item}
              selected={selected.includes(item.id)}
              goDetail={goDetail}
              onSelectRow={onSelectRow}
              handleBarcode={handleBarcode}
              setUpdateOrder={setUpdateOrder}
              setDeleteItem={setDeleteItem}
            />
          )),
        }}
      />

      {deleteItem && (
        <Dialog
          open
          keepMounted
          TransitionComponent={Transition}
          onClose={() => setDeleteItem(null)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            Uyarı, bir silme işlemi gerçekleştiriyorsunuz.
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{ pt: 2 }}
            >
              <b>{deleteItem.siparisNo}</b> numaralı siparişi silmek
              istediğinize emin misiniz ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="info" onClick={() => setDeleteItem(null)}>
              Vazgeç
            </Button>
            {/* <Button onClick={() => handleDeleteRow(deleteItem)}>Sil</Button> */}
            <Button onClick={() => handleDeleteRows(deleteItem)}>Sil</Button>
          </DialogActions>
        </Dialog>
      )}

      {updateOrder && (
        <Dialog
          open
          fullWidth
          keepMounted
          maxWidth="lg"
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-slide-map"
          onClose={() => setUpdateOrder(null)}
        >
          <DialogTitle sx={{ textAlign: "center", pb: 3 }}>
            Adres Güncelleme
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ py: 3 }}>
              <Grid item xs={12} md={4}>
                <Stack spacing={3}>
                  <TextField
                    disabled
                    fullWidth
                    label="Müşteri Adı"
                    value={updateOrder.aliciAdi}
                  />
                  <TextField
                    disabled
                    fullWidth
                    label="Müşteri Adresi"
                    value={updateOrder.aliciAdresi}
                  />
                  <TextField
                    disabled
                    fullWidth
                    label="İl/İlçe"
                    value={updateOrder.ilIlce}
                  />
                  <TextField label="Enlem" value={updateOrder.enlem} />
                  <TextField label="Boylam" value={updateOrder.boylam} />
                </Stack>
              </Grid>
              <Grid item xs={12} md={8}>
                <Card sx={{ height: "100%", minHeight: 400 }}>
                  <Map
                    coords={coordinateEditing(
                      updateOrder.enlem,
                      updateOrder.boylam
                    )}
                    onChange={updateOrderAddress}
                  />
                </Card>
              </Grid>
            </Grid>

            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              endIcon={<Save />}
              onClick={updateOrderAddressRequest}
            >
              Adresi güncelle
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Layout>
  );
};

export default List;
