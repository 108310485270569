import React, { useRef, useState, useEffect } from "react";
import { Auth } from "../../api/Auth";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/actions/loginAction";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import { Typography } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { loadingActive } from "helper";
import { useSnackbar } from "notistack";

function Copyright(props) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      Bringo {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignInSide(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = new useState();
  const [password, setPassword] = new useState();
  const [loading, setLoading] = new useState(false);
  const [error, setError] = new useState();
  const [captchaToken, setRecaptchaToken] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  var auth = new Auth();
  const ref = new useRef(0);
  const recaptchaInstance = ref.current;
  // const recaptchaProps = recaptchaInstance.props;

  const _authenticated = auth.isAuthenticated();

  const { state = {} } = props.location;
  const { user } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const urlHistory = useHistory();
  const handleLogin = () => {
    if (!username) {
      enqueueSnackbar("Lütfen kullanıcı adını boş bırakmayınız.", {
        variant: "error",
      });
      return;
    }

    if (!password) {
      enqueueSnackbar("Lütfen parola alanını boş bırakmayınız.", {
        variant: "error",
      });
      return;
    }

    setLoading(true);
    dispatch(
      login(
        username,
        password,
        captchaToken,
        (data) => {
          if (data.user_type == "ADMIN") {
            urlHistory.push("/customer-management");
          } else {
            // page redirect on customer panel
            urlHistory.push("/bringo-customer/dashboard");
          }
        },
        (error) => {
          setLoading(false);
          if (error.message === "W0007") {
            enqueueSnackbar(error.code, { variant: "error" });
          }
          setError(error.message);
          // recaptchaProps.grecaptcha.reset();
        }
      )
    );
    // dispatch(userInfo());
  };

  // disable loading screen
  useEffect(() => {
    loadingActive(dispatch, false);
  }, []);

  const verifyCaptcha = (key) => {
    setRecaptchaToken(key);
  };

  const handleChange = (event) => {
    if (event.target.value == "") setError("Input required");
    if (event.target.name == "username") setUsername(event.target.value);
    if (event.target.name == "password") setPassword(event.target.value);
  };
  useEffect(() => {
    if (_authenticated) {
      if (user) {
        const isAdmin = user.user_type === "ADMIN";
        props.history.replace(
          isAdmin ? "/customer-management" : "/bringo-customer/dashboard"
        );
      }
    }
  }, [_authenticated]);

  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(/login-splash.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "200px", mb: 2 }}>
              <img src="/hey-bringo-logo.png" />
            </Box>
            <Typography component="h1" variant="h5">
              Giriş yap
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Kullanıcı adı"
                name="username"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Parola"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Beni hatırla"
              />
              <LoadingButton
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={loading}
                onClick={handleLogin}
              >
                {t("login")}
              </LoadingButton>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
